const checkboxStyles = {
  inputCheckbox: {
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
    padding: "8px",
    border: "2px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  checkboxLabel: {
    marginLeft: "8px",
    fontSize: "14px",
    color: "#333",
  },
  CheckboxChecked: {
    backgroundColor: "#007bff",
    border: "#007bff",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E\")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "70% 70%",
  },
};
export default checkboxStyles;
