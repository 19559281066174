// utils/dateUtils.js
const formatDateString = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return { year, month, day };
};

const formatKoreanDate = (dateString) => {
  const { year, month, day } = formatDateString(dateString);
  return `${year}년 ${parseInt(month, 10)}월 ${parseInt(day, 10)}일`;
};

export { formatKoreanDate };
