import React, { useState } from "react";
import CustomInput from "../../components/common/CustomInput";
import SelectBankModal from "../../components/modal/SelectBankModal";
import styles from "../../css/Claim/claimMembersInfo.module.css";
import SecureInputForm from "../common/SecureInputForm";
import RelationData from "../../api/RelationData.json";
import DownArrow from "../../assets/commonDownArrow.svg";

function AccountInfoSection({
  error,
  setError,
  accountNumber,
  setAccountNumber,
  accountHolderName,
  setAccountHolderName,
  handleAccountNumberChange,
  isValidKoreanName,
  selectedBank,
  setSelectedBank,
  setSelectedBankCd,
  handleEncryptedAccountNumber,
  EncryptedAccountSecretNum,
  setRelationCd,
  isButtonDisabled,
}) {
  const [isSelectBankModalOpen, setIsSelectBankModalOpen] = useState(false);
  const [residentNumber, setResidentNumber] = useState("");
  const [toggleActive, setToggleActive] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState("");
  const [inputTouched, setInputTouched] = useState({
    accountNumber: false,
    accountHolderName: false,
    residentNumber: false,
  });

  const openBankModal = () => {
    setIsSelectBankModalOpen(true);
  };

  const closeBankModal = () => {
    setIsSelectBankModalOpen(false);
  };

  const handleBankSelect = (bankName, bankCd) => {
    setSelectedBank(bankName);
    setSelectedBankCd(bankCd);
  };

  const toggleAction = () => {
    setToggleActive(!toggleActive);
  };

  const handleRelationSelect = (relation, key) => {
    setSelectedRelation(relation);
    setRelationCd(key);
    setToggleActive(false);
  };
  console.log(selectedRelation);

  const handleInputChange = (field, value, validationFunction) => {
    setInputTouched({ ...inputTouched, [field]: true });
    if (field === "accountNumber") {
      setAccountNumber(value);
      handleAccountNumberChange(value);
    } else if (field === "accountHolderName") {
      setAccountHolderName(value);
    } else if (field === "residentNumber") {
      setResidentNumber(value);
    }

    if (value.trim() === "") {
      setError({ ...error, [field]: "" });
    } else if (validationFunction) {
      const errorMessage = validationFunction(value);
      setError({ ...error, [field]: errorMessage });
    }
  };

  const validateAccountHolderName = (value) => {
    return !isValidKoreanName(value) ? "유효하지 않은 이름입니다." : "";
  };

  const shouldShowError = (field) => {
    const value =
      field === "accountNumber"
        ? accountNumber
        : field === "accountHolderName"
        ? accountHolderName
        : field === "residentNumber"
        ? residentNumber
        : "";
    return (
      inputTouched[field] &&
      error[field] &&
      error[field] !== "" &&
      value.trim() !== "" &&
      isButtonDisabled
    );
  };

  return (
    <div className={styles.section_3}>
      <div className={styles.sectionContentsWrap_Title}>
        <span>3</span>
        <p>계좌 정보 입력</p>
      </div>
      <div className={styles.escrowContentsWrap}>
        <div className={styles.escrowContents}>
          <div className={styles.escrowContents_span}>보험금 입금계좌</div>
          <div
            className={`${styles.accountList} ${
              !isButtonDisabled ? styles.disabled : ""
            }`}
          >
            <div className={styles.selectBankInput} onClick={openBankModal}>
              <CustomInput
                placeholder="금융기관을 선택해주세요."
                readOnly
                value={selectedBank}
              />
            </div>
            {isSelectBankModalOpen && (
              <SelectBankModal
                isOpen={isSelectBankModalOpen}
                onClose={closeBankModal}
                onSelectBank={handleBankSelect}
              />
            )}
            <SecureInputForm
              initialValue={accountNumber}
              placeholder="계좌번호를 입력해주세요."
              maxLength={13}
              error={shouldShowError("accountNumber")}
              onChange={(value) => handleInputChange("accountNumber", value)}
              onClick={(encryptedData) => {
                handleEncryptedAccountNumber(encryptedData);
              }}
              maskValue="account"
              userSecretNum={accountNumber}
            />
            {shouldShowError("accountNumber") && (
              <p style={{ color: "#E86565" }}>{error.accountNumber}</p>
            )}

            <CustomInput
              placeholder="예금주 이름을 입력해주세요."
              value={accountHolderName}
              onChange={(value) =>
                handleInputChange(
                  "accountHolderName",
                  value,
                  validateAccountHolderName
                )
              }
              onClear={() => {
                setAccountHolderName("");
                setError({ ...error, accountHolderName: "" });
                setInputTouched({ ...inputTouched, accountHolderName: false });
              }}
              error={shouldShowError("accountHolderName")}
            />

            {shouldShowError("accountHolderName") && (
              <p style={{ color: "#E86565" }}>{error.accountHolderName}</p>
            )}
            <div className={styles.relationshipContents}>
              <div className={styles.selectRelationShip}>
                <div
                  className={`${styles.selectRelationTitle} ${
                    selectedRelation
                      ? styles.selectedRelation
                      : styles.defaultRelation
                  }`}
                  onClick={toggleAction}
                >
                  {selectedRelation || "관계 선택"}
                  <img src={DownArrow} alt="DownArrow" />
                </div>
                <div
                  className={`${styles.relationOptions} ${
                    toggleActive ? styles.active : ""
                  }`}
                >
                  <div className={styles.boundary}></div>
                  {Object.entries(RelationData).map(([key, value]) => (
                    <div
                      key={key}
                      className={styles.relationOptionItem}
                      onClick={() => handleRelationSelect(value, key)}
                    >
                      <span>{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {selectedRelation !== "본인" && selectedRelation !== "" && (
              <>
                <SecureInputForm
                  placeholder="예금주 주민등록번호 13자리를 입력해주세요."
                  maxLength={13}
                  value={residentNumber}
                  onChange={(value) =>
                    handleInputChange("residentNumber", value)
                  }
                  onClick={(encryptedData) => {
                    EncryptedAccountSecretNum(encryptedData);
                  }}
                  maskValue="accountSecret"
                  userSecretNum={residentNumber}
                />
                {shouldShowError("residentNumber") && (
                  <p style={{ color: "#E86565" }}>{error.residentNumber}</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInfoSection;
