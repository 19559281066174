import React, { useState, useRef, useEffect } from "react";
import styles from "../../css/Claim/claimUploadModal.module.css";
import commonX from "../../assets/commonX.svg";
import claimModalFileUpload from "../../assets/claimModalFileUpload.svg";
import claimDocFileUpload from "../../assets/claimDocFileUpload.svg";
import claimDeleteBtn from "../../assets/claimDeleteBtn.svg";
import Button from "../common/Button";
import Loading from "./Loading";

function UploadFileModal({
  closeModal,
  previews,
  setPreviews,
  collectionData,
  setImageName,
}) {
  const [pendingPreviews, setPendingPreviews] = useState(previews);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    setPendingPreviews(previews);
  }, [previews]);

  useEffect(() => {
    if (filesToUpload.length > 0) {
      setUploading(true);
      setIsLoading(true);
      const promises = filesToUpload.map(({ file, accidentCauseCd }) =>
        sendFileToServer(file, accidentCauseCd)
      );

      Promise.all(promises)
        .then((results) => {
          results.forEach((result) => {
            if (result) {
              setImageName((prevImageName) => [
                ...prevImageName,
                result.Filename,
              ]);
            }
          });
        })
        .catch((error) => console.error("파일 업로드 오류:", error))
        .finally(() => {
          setFilesToUpload([]);
          setUploading(false);
          setIsLoading(false);
        });
    }
  }, [filesToUpload]);

  const sendFileToServer = (file, accidentCauseCd) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("accident_cause_cd", accidentCauseCd);
      formData.append("file", file, file.name);

      fetch(`${process.env.REACT_APP_BASE_URL}/trip-api/upload`, {
        method: "POST",
        body: formData,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  };

  const handleFileChange = (index, e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    const updatedPendingPreviews = [...pendingPreviews];
    updatedPendingPreviews[index] = [...pendingPreviews[index], ...newPreviews];
    setPendingPreviews(updatedPendingPreviews);

    const newFilesToUpload = files.map((file) => ({
      file,
      accidentCauseCd: collectionData.accidentDateKey,
    }));

    setFilesToUpload((prevFilesToUpload) => [
      ...prevFilesToUpload,
      ...newFilesToUpload,
    ]);
  };

  const openFileUpload = (index) => {
    fileInputRefs.current[index].current.click();
  };

  const handleFileDelete = (index, fileIndex) => {
    const updatedPendingPreviews = [...pendingPreviews];
    updatedPendingPreviews[index] = pendingPreviews[index].filter(
      (_, idx) => idx !== fileIndex
    );
    setPendingPreviews(updatedPendingPreviews);
    setPreviews(updatedPendingPreviews);

    setImageName((prevImageName) =>
      prevImageName.filter((_, idx) => idx !== fileIndex)
    );
  };

  const handleConfirm = () => {
    if (!uploading) {
      setPreviews(pendingPreviews);
      closeModal();
    } else {
      console.log("파일 업로드가 완료될 때까지 기다려주세요.");
    }
  };

  return (
    <div className={styles.modalOverlay}>
      {isLoading && <Loading />}
      <div className={styles.modalContent}>
        <div className={styles.modalContentTitle}>
          <p>필요서류 첨부</p>
          <button className={styles.closeButton} onClick={closeModal}>
            <img src={commonX} alt="close" />
          </button>
        </div>
        <h3>항목별 서류 파일을 첨부해주세요.</h3>
        <div className={styles.uploadListWrap}>
          {["진료비 계산 영수증", "진료비 세부내역서", "진단서"].map(
            (title, idx) => (
              <div className={styles.uploadList} key={idx}>
                <p>{title}</p>
                <div className={styles.overflowContents}>
                  <div className={styles.ImagesWrap}>
                    {!pendingPreviews[idx].length ? (
                      <img
                        src={claimModalFileUpload}
                        alt="upload icon"
                        className={styles.uploadIcon}
                        onClick={() => openFileUpload(idx)}
                      />
                    ) : (
                      <div className={styles.imagesContents}>
                        <div className={styles.imagesFlex}>
                          <img
                            src={claimDocFileUpload}
                            alt="upload icon"
                            onClick={() => openFileUpload(idx)}
                          />
                          <div className={styles.imagesWrap}>
                            {pendingPreviews[idx].map((preview, fileIdx) => (
                              <div
                                key={fileIdx}
                                className={styles.previewContainer}
                              >
                                <img src={preview} alt="preview" />
                                <div
                                  className={styles.deleteButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFileDelete(idx, fileIdx);
                                  }}
                                >
                                  <img src={claimDeleteBtn} alt="delete" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(idx, e)}
                  accept=".jpg,.jpeg,.png"
                  ref={fileInputRefs.current[idx]}
                  className={styles.input}
                  multiple
                  key={`file-input-${idx}`}
                />
              </div>
            )
          )}
        </div>
        <Button buttonText="확인" onClick={handleConfirm} />
      </div>
    </div>
  );
}

export default UploadFileModal;
