import React from "react";
import styles from "../../css/Claim/claimIntro.module.css";
import claimintroPlan from "../../assets/claimintroPlan.svg";
import claimintroLogin from "../../assets/claimintroLogin.svg";
import ClaimFooter from "./ClaimFooter";
import GoNice from "../../components/niceComponents/GoNice";
import ClaimIntroSlide from "../../components/claimComponents/ClaimIntroSlide";
import ClipLogin from "../../components/claimComponents/ClipLogin";
import { useNavigate } from "react-router-dom";

function ClaimIntro() {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-[100%] bg-white">
        <ClaimIntroSlide />
        <div className={styles.eventBannerWrap}>
          <div className={styles.eventBannerWraper}>
            <div className={styles.Banners}>
              <div className={styles.Banner_Content}>
                <div className={styles.Banner_Text}>
                  <h3>해외 여행자 보험 가입</h3>
                  <p>
                    알뜰 플랜부터 추천플랜까지!
                    <br /> 간편하게 보험가입 해보세요
                  </p>
                </div>
                <img src={claimintroLogin} alt="claimintroLogin" />
              </div>
              <button
                className={styles.Banner_Button}
                onClick={() => navigate("/trip")}
              >
                여행자 보험 가입하기
              </button>
            </div>
            <div className={styles.Banner}>
              <div className={styles.Banner_Content}>
                <div className={styles.Banner_Text}>
                  <h3>
                    가입 내역 조회하고
                    <br />
                    보험료 청구하기
                  </h3>
                  <p>보험료 청구도 빠르게 간편하게</p>
                </div>
                <img src={claimintroPlan} alt="claimintroPlan" />
              </div>
              <ClipLogin />
              <GoNice />
            </div>
          </div>
        </div>
        <ClaimFooter />
      </div>
    </>
  );
}

export default ClaimIntro;
