import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { selectStartDate } from "../../redux/store";

function StartCalendar({ onClose, faRetrustData }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const minDate = dayjs().startOf("month");
  const maxDate = dayjs().add(3, "month").startOf("month").subtract(1, "day");

  useEffect(() => {
    if (faRetrustData && faRetrustData.startDate) {
      const startDate = dayjs(faRetrustData.startDate);
      setSelectedDate(startDate);
      dispatch(selectStartDate(startDate.format("YYYYMMDD")));
    }
  }, [faRetrustData, dispatch]);

  const shouldDisableDate = (day) => {
    const currentDate = dayjs().format("YYYYMMDD");
    const formattedSelectedDate = dayjs(day).format("YYYYMMDD");
    return dayjs(formattedSelectedDate).isBefore(currentDate);
  };

  const handleApply = () => {
    if (selectedDate) {
      const formattedDate = selectedDate.format("YYYYMMDD");
      dispatch(selectStartDate(formattedDate));
      onClose(selectedDate);
    } else {
      alert("선택된 날짜가 없습니다!");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <div className="calendar-container">
        <DateCalendar
          value={selectedDate}
          onChange={setSelectedDate}
          shouldDisableDate={shouldDisableDate}
          showDaysOutsideCurrentMonth={false}
          minDate={minDate}
          maxDate={maxDate}
          views={["month", "day"]}
          className="custom-calendar"
        />
      </div>

      <div className="mt-5 border-t-2 "></div>
      <div className="flex w-[90%] mx-auto justify-between mt-2 items-center mb-4">
        <span className="font-extrabold text-xs ">출발일</span>
        <span className="text-[#386937]  text-[13px] font-semibold">
          {selectedDate
            ? selectedDate.format("YYYY년 MM월 DD일")
            : "날짜를 선택해주세요"}
        </span>
      </div>
      <div className="py-2">
        <button
          className="w-[100%] mx-auto text-center py-4 rounded-2xl bg-[#386937] text-white font-semibold text-sm"
          onClick={handleApply}
        >
          적용
        </button>
      </div>
    </LocalizationProvider>
  );
}

export default StartCalendar;
