import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/Claim/claimMembersInfo.module.css";
import commonDownArrow from "../../assets/commonDownArrow.svg";
import commonRadioBtn from "../../assets/commonRadioBtn.svg";
import commonRadinActive from "../../assets/commonRadinActive.svg";
import { useSelector } from "react-redux";
import SecureInputForm from "../common/SecureInputForm";
import { formatKoreanDate } from "../../utils/dateUtils";

function ClaimInfoSection({
  isActive,
  handleOptionClick,
  setEncryptedRRN,
  setSelectedClaimDate,
  setSelectedName,
  isButtonDisabled,
}) {
  const cladjOpapiRctDivCd = {
    1: "신규",
    2: "추가",
  };

  const token = useSelector((state) => state.cookie.cookie);
  const userBirth = token?.birth || "";
  const userName = token?.name || "";
  const [rrnValue, setRRNValue] = useState("");
  const [error, setError] = useState("");
  const [claimableData, setClaimableData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({
    name: userName,
    birth: userBirth,
  });

  useEffect(() => {
    setSelectedName(selectedPerson.name);
  }, [selectedPerson]);

  const dropdownRef = useRef(null);

  const selectedBirth = selectedPerson.birth;

  useEffect(() => {
    const myHeaders = new Headers();

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/trip-api/insurance/claimable`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setClaimableData(result);
        setSelectedClaimDate(result);
      })
      .catch((error) => console.error("Error fetching claimable data:", error));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEncryptedRRN = (encryptedData) => {
    setEncryptedRRN(encryptedData);
  };

  const childrenData = claimableData?.data?.claimableChilds || [];

  const handleDropdownToggle = () => {
    if (childrenData.length > 0) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handlePersonSelect = (person) => {
    setSelectedPerson(person);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.section_1}>
      <h1>청구정보를 입력해주세요.</h1>
      <div className={`${styles.sectionContentsWrap}`}>
        <div className={styles.sectionContentsWrap_Title}>
          <span>1</span>
          <p>청구 정보 입력</p>
        </div>
        <div className={`${styles.sectionContentsWrap_userSelect}`}>
          <p>피보험자 선택</p>
          <div
            className={`${styles.sectionContentsWrap_SelectOption} ${
              isDropdownOpen ? styles.active : styles.nonActive
            }`}
            ref={dropdownRef}
            onClick={handleDropdownToggle}
          >
            <div className={styles.selectedOption}>
              <p>{selectedPerson.name}</p>
              <div className={styles.flexRow}>
                <p>{formatKoreanDate(selectedPerson.birth)}</p>
                <img
                  src={commonDownArrow}
                  alt="downArrow"
                  className={isDropdownOpen ? styles.rotated : ""}
                />
              </div>
            </div>
            {isDropdownOpen && childrenData.length > 0 && (
              <ul className={styles.optionsList}>
                <li
                  onClick={() =>
                    handlePersonSelect({ name: userName, birth: userBirth })
                  }
                >
                  <p>{userName}</p>
                  <p>{formatKoreanDate(userBirth)}</p>
                </li>
                {childrenData.map((child, index) => (
                  <li key={index} onClick={() => handlePersonSelect(child)}>
                    <p>{child.name}</p>
                    <p>{formatKoreanDate(child.birth)}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {isButtonDisabled && (
            <div
              className={`${styles.hidden} ${
                isButtonDisabled ? styles.hidden : ""
              }`}
            >
              <SecureInputForm
                initialValue={rrnValue}
                placeholder="주민등록번호 뒷자리를 입력해주세요."
                error={error}
                selectedBirth={selectedBirth}
                onChange={(value) => {
                  setRRNValue(value);
                  setError("");
                }}
                maskValue="secret"
                onClick={handleEncryptedRRN}
              />
            </div>
          )}
          <div className={`${styles.sectionContentsWrap_claimSelect}`}>
            <div className={`${styles.sectionContentsWrap_claimSelectOption}`}>
              <p>청구 선택</p>
              <ul>
                {Object.entries(cladjOpapiRctDivCd).map(([key, value]) => (
                  <li
                    key={key}
                    onClick={() =>
                      isButtonDisabled && handleOptionClick(parseInt(key))
                    }
                    style={{
                      display: "flex",
                      gap: "8px",
                      cursor: isButtonDisabled ? "pointer" : "not-allowed",
                      alignItems: "center",
                      opacity: isButtonDisabled ? 1 : 0.7,
                    }}
                  >
                    <img
                      src={
                        isActive === parseInt(key)
                          ? commonRadinActive
                          : commonRadioBtn
                      }
                      alt="radio button"
                    />
                    <p
                      style={{
                        color:
                          isActive === parseInt(key) ? "#386937" : "#1b1e28",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        opacity: isButtonDisabled ? 1 : 0.9,
                        width: "100%",
                      }}
                    >
                      {value}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimInfoSection;
