import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../css/Claim/claimMainSlick.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MainSlick() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const handleSlideClick = (id) => {
    const selectedInsurance = data.find((item) => item.id === id);
    navigate(`/claimContractInfo/${id}`, {
      state: { selectedInsurance },
    });
  };

  const tokenData = useSelector((state) => state.insurance.insurances);
  const data = Array.isArray(tokenData.Insurances) ? tokenData.Insurances : [];
  const name = tokenData.name || "";
  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    arrows: false,
    centerMode: true,
    centerPadding: "8%",
  };

  // 현재 날짜를 yyyy-mm-dd 형식의 문자열로 가져옵니다.
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = formatDate(new Date());

  // 데이터를 필터링하여 만료된 보험을 제외합니다.
  const filteredData = data.filter((item) => {
    const endDate = formatDate(new Date(item.Contract?.insurance_end_date));
    return endDate >= currentDate;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    return (
      new Date(b.Contract?.insurance_start_date) -
      new Date(a.Contract?.insurance_start_date)
    );
  });

  // 슬라이더를 표시할지 여부를 결정합니다.
  const shouldShowSlider = sortedData.length > 0;

  return (
    <div className={styles.sliderContainer}>
      {shouldShowSlider ? (
        <>
          <div className="flex w-full items-center ml-[20px]">
            <p className={styles.memberInfo}>가입내역 |</p>
            <p
              className={styles.memberInfo2}
              onClick={() => {
                navigate("/claimContractInfo");
              }}
            >
              더 보기
            </p>
          </div>
          <Slider
            ref={sliderRef}
            {...settings}
            style={{
              margin: "50px 0", // Adjust margin between slides if needed
              bottom: "40px",
              right: "10px",
            }}
          >
            {sortedData.slice(0, 3).map((item) => (
              <div key={item.id} onClick={() => handleSlideClick(item.id)}>
                <div className={styles.slideContents}>
                  <div className={styles.slideContentsTitle}>
                    <div className={styles.titleText}>
                      <h3>해외여행 실손의료보험</h3>
                      <span>정상</span>
                    </div>
                    <a href={item.Card?.goKlip} onClick={handleLinkClick}>
                      <button className={styles.titleBtn}>NFT 보러가기</button>
                    </a>
                  </div>
                  <div className={styles.boundaryWrap}>
                    <div className={styles.boundary}></div>
                  </div>
                  <div className={styles.userInfoConents}>
                    <ul>
                      <li>
                        <span>계약자</span>
                        <p>{item.Contract?.User?.contractor_name}</p>
                      </li>
                      <li>
                        <span>피보험자</span>
                        <p>{name}</p>
                      </li>
                      <li>
                        <span>보험기간</span>
                        <p>
                          {item.Contract?.insurance_start_date} ~{" "}
                          {item.Contract?.insurance_end_date}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      ) : null}
    </div>
  );
}

export default MainSlick;
