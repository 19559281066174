import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Claim/ClaimRevacationSlide.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/currentDate";
import ErrorModal from "../modal/ErrorModal";

function ClaimRevocationSlide({ setEmpty }) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSlideClick = (id,deletable) => {
    if(!deletable){
      setShowErrorModal(true)
      return;
    };
    navigate(`/claimRevocation/${id}`);
  };

  const tokenData = useSelector((state) => state.insurance.insurances);
  const data = Array.isArray(tokenData.Insurances) ? tokenData.Insurances : [];

  const name = tokenData.name || "";

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    arrows: false,
    centerMode: true,
    centerPadding: "30px",
  };

  const currentDate = formatDate(new Date());

  // 데이터를 필터링하여 만료된 보험을 제외합니다.
  const filteredData = data.filter((item) => {
    const endDate = formatDate(new Date(item.Contract?.insurance_end_date));
    return endDate > currentDate;
  });

  const sortedData = filteredData.slice().sort((a, b) => {
    return (
      new Date(b.Contract?.insurance_start_date) -
      new Date(a.Contract?.insurance_start_date)
    );
  });

  return (
    <div className={styles.sliderContainer}>
      <Slider
        ref={sliderRef}
        {...settings}
        style={{
          marginBottom: "20px",
          bottom: "0px",
          right: "10px",
        }}
      >
        {sortedData.slice(0, 4).map((item) => (
          <div
            key={item.id}
            onClick={() => handleSlideClick(item?.Contract.id,item.deletable)}
          >
            <div className={styles.slideContents}>
              <h3>해외여행 실손의료보험</h3>
              <div className={styles.boundaryWrap}>
                <div className={styles.boundary}></div>
              </div>
              <div className={styles.userInfoConents}>
                <ul>
                  <li>
                    <span>견적번호</span>
                    <p>{item.Contract.group_no}</p>
                  </li>
                  <li>
                    <span>보험기간</span>
                    <p>
                      {item.Contract.insurance_start_date} ~{" "}
                      {item.Contract.insurance_end_date}
                    </p>
                  </li>
                  <li>
                    <span>계약자</span>
                    <p>{item.Contract.User?.contractor_name}</p>
                    </li>
                  <li>
                    <span>피보험자</span>
                    <p>{name}</p>
                  </li>
                  <li>
                        <span>환급보험료</span>
                        <p>{item.Contract.total_premium}</p>
                      </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {showErrorModal && (
        <ErrorModal
          onClose={() => {
            setShowErrorModal(false);
          }}
          message="동반가입자 취소 불가능"
          subMsg="단체 보험상품으로 동반가입자 보험이 일괄 취소되므로, 가입하신 계약자만 취소가 가능합니다."
        />
      )}
    </div>
  );
}

export default ClaimRevocationSlide;
