import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";

// PersonalInfo slice 정의 (엑셀 업로드 데이터)
const personalInfoSlice = createSlice({
  name: "personalInfo",
  initialState: {
    koreanName: "",
    englishName: "",
    email: "",
    phoneNumber: "",
  },
  reducers: {
    setKoreanName(state, action) {
      state.koreanName = action.payload;
    },
    setEnglishName(state, action) {
      state.englishName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
  },
});

// Plan slice 정의
const planSlice = createSlice({
  name: "plan",
  initialState: {
    selectedData: null,
    selectedPlanName: null,
  },
  reducers: {
    selectData(state, action) {
      state.selectedData = action.payload;
    },
    setSelectedPlanName(state, action) {
      state.selectedPlanName = action.payload;
    },
  },
});

// Calendar slice 정의
const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    selectedStartDate: null,
    selectedEndDate: null,
  },
  reducers: {
    selectStartDate(state, action) {
      state.selectedStartDate = action.payload;
    },
    selectEndDate(state, action) {
      state.selectedEndDate = action.payload;
    },
  },
});

// User slice 정의
const userSlice = createSlice({
  name: "user",
  initialState: {
    gender: "",
    dateOfBirth: "",
  },
  reducers: {
    setGender(state, action) {
      state.gender = action.payload;
    },
    setDateOfBirth(state, action) {
      state.dateOfBirth = action.payload;
    },
  },
});

// Companions slice 정의
const companionsSlice = createSlice({
  name: "companions",
  initialState: [],
  reducers: {
    setCompanions(state, action) {
      // 동반자 초기화 후 새로운 데이터 추가
      return action.payload;
    },
    addCompanion(state, action) {
      state.push(action.payload);
    },
    updateCompanionGender(state, action) {
      const { index, gender } = action.payload;
      state[index].gender = gender;
    },
    updateCompanionDateOfBirth(state, action) {
      const { index, dateOfBirth } = action.payload;
      state[index].dateOfBirth = dateOfBirth;
    },
    deleteCompanion(state, action) {
      state.splice(action.payload, 1);
    },
  },
});

// Country slice 정의
const countrySlice = createSlice({
  name: "country",
  initialState: {
    selectedCountryData: null,
    recommendedCountryData: null,
  },
  reducers: {
    setSelectedCountryData(state, action) {
      state.selectedCountryData = action.payload;
    },
    setRecommendedCountryData(state, action) {
      state.recommendedCountryData = action.payload;
    },
    clearCountryData(state) {
      state.selectedCountryData = null;
      state.recommendedCountryData = null;
    },
  },
});

// TotalPrice slice 정의
const totalPriceSlice = createSlice({
  name: "totalPrice",
  initialState: {
    totalPrice: null,
  },
  reducers: {
    setTotalPrice(state, action) {
      state.totalPrice = action.payload;
    },
  },
});

// PriceData slice 정의
const priceDataSlice = createSlice({
  name: "priceData",
  initialState: {
    priceData: null,
  },
  reducers: {
    setPriceData(state, action) {
      state.priceData = action.payload;
    },
  },
});

// Auth slice 정의
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
  },
  reducers: {
    login(state, action) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = null;
    },
  },
});

// Claim slice 정의
const claimSlice = createSlice({
  name: "claim",
  initialState: {
    birthSecondPart: "",
    status: null,
    message: null,
  },
  reducers: {
    setBirthSecondPart(state, action) {
      state.birthSecondPart = action.payload;
    },
  },
});

// MembersData slice 정의
const membersDataSlice = createSlice({
  name: "members",
  initialState: {
    members: "",
  },
  reducers: {
    setMembersData(state, action) {
      state.members = action.payload;
    },
  },
});

// Insurance slice 정의
const insuranceSlice = createSlice({
  name: "insurance",
  initialState: {
    insurances: [],
  },
  reducers: {
    setInsurance(state, action) {
      state.insurances = action.payload;
    },
  },
});

// Cookie slice 정의
const cookieSlice = createSlice({
  name: "cookie",
  initialState: {
    cookie: [],
  },
  reducers: {
    setCookie(state, action) {
      state.cookie = action.payload;
    },
  },
});

// Combine all reducers into rootReducer
const rootReducer = combineReducers({
  plan: planSlice.reducer,
  calendar: calendarSlice.reducer,
  user: userSlice.reducer,
  companions: companionsSlice.reducer,
  country: countrySlice.reducer,
  totalPrice: totalPriceSlice.reducer,
  priceData: priceDataSlice.reducer,
  auth: authSlice.reducer,
  claim: claimSlice.reducer,
  members: membersDataSlice.reducer,
  insurance: insuranceSlice.reducer,
  cookie: cookieSlice.reducer,
  personalInfo: personalInfoSlice.reducer,
});

// preloadedState 설정
const preloadedState = (() => {
  const savedState = JSON.parse(sessionStorage.getItem("reduxState") || "{}");
  return {
    ...savedState,
    insurance: savedState.insurance || { insurances: [] },
  };
})();

// Remove unexpected keys if any
const expectedKeys = [
  "plan",
  "calendar",
  "user",
  "companions",
  "country",
  "totalPrice",
  "priceData",
  "insurance",
  "members",
  "cookie",
  "personalInfo",
];

Object.keys(preloadedState).forEach((key) => {
  if (!expectedKeys.includes(key)) {
    delete preloadedState[key];
  }
});

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

// Save state to sessionStorage (excluding auth and claim slices)
store.subscribe(() => {
  const state = store.getState();
  const { auth, claim, ...stateToSave } = state;
  sessionStorage.setItem("reduxState", JSON.stringify(stateToSave));
});

// Export actions
export const {
  selectData,
  setSelectedPlanName,
  selectStartDate,
  selectEndDate,
  setGender,
  setDateOfBirth,
  setCompanions, // addCompanion 대신 setCompanions 사용하여 초기화 후 추가
  addCompanion,
  updateCompanionGender,
  updateCompanionDateOfBirth,
  deleteCompanion,
  setSelectedCountryData,
  setRecommendedCountryData,
  clearCountryData,
  setMembersData,
  login,
  logout,
  setInsurance,
  setClaimStatus,
  setCookie,
  setFaRetrustData,
} = {
  ...planSlice.actions,
  ...calendarSlice.actions,
  ...userSlice.actions,
  ...companionsSlice.actions,
  ...countrySlice.actions,
  ...authSlice.actions,
  ...membersDataSlice.actions,
  ...insuranceSlice.actions,
  ...claimSlice.actions,
  ...cookieSlice.actions,
};

// personalInfo 액션들 별도로 export
export const { setKoreanName, setEnglishName, setEmail, setPhoneNumber } =
  personalInfoSlice.actions;

export const { setPriceData } = priceDataSlice.actions;
export const { setTotalPrice } = totalPriceSlice.actions;
export const { setBirthSecondPart } = claimSlice.actions;

// Export the Redux store
export default store;
