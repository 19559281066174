import React from "react";

function Tooltip() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_64_5578)">
          <circle cx="8" cy="8" r="8" fill="#F3F4F6" fillOpacity="0.6" />
          <circle cx="8" cy="11.5" r="1" fill="#66686F" />
          <rect x="7" y="3.5" width="2" height="6" rx="1" fill="#66686F" />
        </g>
        <defs>
          <clipPath id="clip0_64_5578">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default Tooltip;
