import React from "react";
import styles from "../../css/Claim/claimFooter.module.css";
import claimintroLogo from "../../assets/claimintroLogo.svg";
import claimintroPhone from "../../assets/claimintroPhone.svg";
import { useNavigate } from "react-router-dom";

function ClaimFooter() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path);
  };

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerWrap}>
          <div className={styles.svgFlexRow}>
            <img src={claimintroLogo} alt="logo" />

            {/* 고객콜센터 icon */}
            <div className="flex items-center gap-[4px]">
              <img src={claimintroPhone} alt="phone" className={styles.phoneImage} />
              <p className={styles.callcenterText}>고객콜센터</p>
            </div>
          </div>
          <div className={styles.companyContents}>
            <div className={styles.companyContentsWrap}>
              <ul>
                <li className={styles.companyinfo}>대표</li>
                <li className={styles.infomationData}>장우석</li>
              </ul>
              <span>|</span>
              <ul>
                <li className={styles.companyinfo}>전화번호</li>
                <li className={styles.infomationData}>0507-1416-8803</li>
              </ul>
            </div>
            <ul>
              <li className={`${styles.companyinfo} ${styles.companyinfos}`}>
                주소
              </li>
              <li className={`${styles.infomationData}`}>
                부산광역시 남구 문현금융로 40 , 8층 <br />
                (문현동, 부산국제금융센터)
              </li>
            </ul>
            <ul>
              <li className={styles.companyinfo}>사업자등록번호</li>
              <li className={styles.infomationData}>370-88-02749</li>
            </ul>
            <ul>
              <li className={styles.companyinfo}>대리점등록번호</li>
              <li className={styles.infomationData}>제 2024040026 호</li>
            </ul>
            <ul>
              <li className={styles.companyinfo}>통신판매업신고번호</li>
              <li className={styles.infomationData}>
                제 2024-부산남구-0464 호
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <div className={styles.FooterTextFlex}>
              <span
                className="cursor-pointer"
                onClick={() => handleNavigation("/individualInfo")}
              >
                -개인정보처리방침
              </span>
              <span
                className="cursor-pointer"
                onClick={() => handleNavigation("/serviceInfo")}
              >
                -서비스이용약관
              </span>
            </div>
            <p className="pb-4">Copyright© Retrust. All rights reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimFooter;
