import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import InsertDate from "../../components/calculationProcessPart/InsertDate";
import Gender from "../../components/calculationProcessPart/Gender";
import ErrorModal from "../../components/modal/ErrorModal";
import styles from "../../css/calculationProcessCSS/Insert.module.css";


function Insert({ faRetrustData }) {
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const endDate = useSelector((state) => state.calendar.selectedEndDate);
  const gender = useSelector((state) => state.user.gender);
  const dateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const companions = useSelector((state) => state.companions);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { reOrderData } = location.state || {};

  const validateDateOfBirth = (dateString) => {
    const validFormat = /^\d{8}$/;
    if (!validFormat.test(dateString)) {
      return false;
    }
    const year = parseInt(dateString.substring(0, 4), 10);
    if (
      year < new Date().getFullYear() - 90 ||
      year > new Date().getFullYear() - 4
    ) {
      return false;
    }
    const month = parseInt(dateString.substring(4, 6), 10);
    if (month < 1 || month > 12) {
      return false;
    }
    const day = parseInt(dateString.substring(6, 8), 10);
    const maxDay = new Date(year, month, 0).getDate();
    if (day < 1 || day > maxDay) {
      return false;
    }
    return true;
  };

  const handleNext = () => {
    let newErrorMessage = "";

    if (!startDate || !endDate || startDate > endDate) {
      newErrorMessage = "올바른 여행날짜를 선택해주세요!";
    } else if (!gender) {
      newErrorMessage = "성별을 선택해주세요!";
    } else if (!dateOfBirth || !validateDateOfBirth(dateOfBirth)) {
      newErrorMessage = "유효하지 않은 생년월일입니다.";
    } else if (companions.some((companion) => !companion.gender)) {
      newErrorMessage = "동반자의 성별을 입력해주세요!";
    } else if (
      companions.some(
        (companion) => !validateDateOfBirth(companion.dateOfBirth)
      )
    ) {
      newErrorMessage = "유효하지 않은 동반자의 생년월일입니다.!";
    }
    if (newErrorMessage) {
      setErrorMessage(newErrorMessage);
    } else {
      setErrorMessage(""); // 유효성 검사 통과 시 에러 메시지 초기화
      navigate("/trip/trip"); // navigate 수행
    }
  };

  // 동반자 정보가 존재하고 빈 값이 있는지 확인
  const companionsInfoValid = companions.every(
    (companion) => companion.gender && companion.dateOfBirth
  );

  return (
    <>
      <div className={styles.titltBox}>
        <div className={styles.titltWrap}>
          <h2 className={styles.title}>
            보험료 계산에 필요한 정보를
            <br />
            입력해주세요
          </h2>
          <div className={styles.tripSelect}>
            <InsertDate faRetrustData={faRetrustData} />
            <Gender faRetrustData={faRetrustData} reOrderData={reOrderData} />
          </div>
        </div>
      </div>

      <Button
        buttonText="다음"
        onClick={handleNext}
        disabled={
          !startDate ||
          !endDate ||
          !gender ||
          !dateOfBirth ||
          !companionsInfoValid
        }
      />
      {errorMessage && (
        <ErrorModal
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
    </>
  );
}

export default Insert;
