import React, { useRef, useEffect, useState } from "react";
import styles from "../../css/Claim/claimUpLoadPagenation.module.css";
import commonX from "../../assets/commonX.svg";
import rigth from "../../assets/commonRightArrow.svg";

function UpLoadPagenation({
  onClose,
  fileType,
  imageSrcs,
  currentImageIndex,
  onDelete,
}) {
  const contentRef = useRef(null);
  const imageRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(currentImageIndex);
  const totalImages = imageSrcs.length;

  useEffect(() => {
    // 파일 타입에 따라 내용 설정
    switch (fileType) {
      case "진료비 계산 영수증":
        contentRef.current.innerText = "진료비 계산 영수증 내용";
        break;
      case "진료비 세부내역서":
        contentRef.current.innerText = "진료비 세부내역서 내용";
        break;
      case "진단서":
        contentRef.current.innerText = "진단서 내용";
        break;
      default:
        contentRef.current.innerText = "기타 내용";
        break;
    }

    // 이미지 src 설정
    if (imageSrcs && imageSrcs.length > 0) {
      imageRef.current.src = imageSrcs[currentIndex];
    }
  }, [fileType, imageSrcs, currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : totalImages - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < totalImages - 1 ? prevIndex + 1 : 0
    );
  };

  const handleDelete = () => {
    onDelete(currentIndex); // onDelete prop을 호출하여 파일 삭제
    onClose(); // 모달 닫기
  };

  return (
    <div className={styles.backdrop}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <h3>미리보기</h3>
          <img
            src={commonX}
            alt="닫기"
            className={styles.closeIcon}
            onClick={onClose}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <span ref={contentRef}></span>
            <img
              ref={imageRef}
              alt="미리보기"
              className={styles.previewImages}
            />
            <div className={styles.pagination}>
              <div onClick={handlePrev}>
                <img src={rigth} alt=">" className={styles.rightBtn} />
              </div>
              <div>
                <span>
                  {currentIndex + 1} / {totalImages}
                </span>
              </div>
              <div onClick={handleNext}>
                <img src={rigth} alt="<" className={styles.leftBtn} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <button onClick={handleDelete}>삭제</button>
          <button onClick={onClose}>확인</button>
        </div>
      </div>
    </div>
  );
}

export default UpLoadPagenation;
