import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import commonX from "../../assets/commonX.svg";
import commonCheck from "../../assets/commonCheck.svg";
import AcitveCommonCheck from "../../assets/commonActiveChk.svg";
import Button from "../common/Button";

function ClaimExtendModal({ filteredData, onClose }) {
  const [selected, setSelected] = useState(1);
  const navigate = useNavigate();

  const options = ["예, 사고가 있었어요.", "아니오, 사고가 없었어요."];

  const handleNavigation = () => {
    if (selected === 0) {
      if (
        window.confirm(
          "사고가 있을 경우 도착일 연장이 불가능합니다. 이전페이지로 돌아가시겠습니까?"
        )
      ) {
        navigate("/ClaimExtendDate");
      }
    } else if (selected === 1) {
      if (filteredData) {
        navigate("/claimExtendSelectDate", { state: filteredData });
      }
    }
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 flex justify-center items-center h-full bg-black bg-opacity-60 z-[200]">
        <div className="commonModal bg-white rounded-t-3xl w-full absolute bottom-0 p-4 z-[200]">
          <div className="flex items-center justify-between w-full pt-3">
            <h1 className="text-[#1B1E28] text-[22px]">
              보험사고 발생여부를 알려주세요.
            </h1>
            <img
              src={commonX}
              alt="X"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="py-6">
            <p className="text-[#66686F] text-[14px]">
              도착일 연장의 경우, 연장 신청일 기준으로 이전에 사고가 없을
              경우만가능합니다. 사고 사실을 통보하지 않고 연장한 경우 보험금
              지급이 어렵습니다.
            </p>
          </div>
          <div className="w-full">
            <ul className="w-full flex flex-col gap-3">
              {options.map((text, idx) => (
                <li
                  key={idx}
                  className={`flex items-center gap-2 cursor-pointer px-[12px] py-[16px] border rounded-xl ${
                    selected === idx
                      ? "border-[#386937] text-[#386937]"
                      : "border-[#E8E9EA]"
                  }`}
                  onClick={() => setSelected(idx)}
                >
                  <img
                    src={selected === idx ? AcitveCommonCheck : commonCheck}
                    alt="chk"
                  />
                  <p className="text-[#66686F] text-base">{text}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="z-[200]">
            <Button buttonText="확인" onClick={handleNavigation} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimExtendModal;
