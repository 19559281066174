import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CityInfoData from "../../api/CityinfoData.json";
import styles from "../../css/calculationProcessCSS/excelUpLoad.module.css";
import {
  selectStartDate,
  selectEndDate,
  setSelectedCountryData,
  setGender,
  setDateOfBirth,
  setCompanions,
  setKoreanName,
  setEnglishName,
  setEmail,
  setPhoneNumber,
} from "../../redux/store";
import EstimateButton from "../common/EstimateButton";

function ExcelUpLoad() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [uploadedData, setUploadedData] = useState(null);
  const [isFileProcessed, setIsFileProcessed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const endDate = useSelector((state) => state.calendar.selectedEndDate);
  const selectedCountry = useSelector(
    (state) => state.country.selectedCountryData
  );
  const userGender = useSelector((state) => state.user.gender);
  const userDateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const companions = useSelector((state) => state.companions);
  const personalInfo = useSelector((state) => state.personalInfo);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/[^0-9]/g, "");
    if (cleaned.length !== 11) return cleaned;
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7)}`;
  };

  const convertGender = useCallback((gender) => {
    return gender === "남" ? "1" : "2";
  }, []);

  const saveToRedux = (data, countryInfo) => {
    const contractor = data[0];

    dispatch(selectStartDate(contractor.출발일));
    dispatch(selectEndDate(contractor.도착일));

    dispatch(
      setSelectedCountryData({
        cityNatlCd: countryInfo.cityNatlCd,
        korNatlNm: contractor.국가,
      })
    );

    dispatch(setGender(convertGender(contractor.성별)));
    dispatch(setDateOfBirth(contractor.생년월일));
    dispatch(setKoreanName(contractor["이름(국문)"]));
    dispatch(setEnglishName(contractor["이름(영문)"]));
    dispatch(setEmail(contractor["이메일"]));
    dispatch(setPhoneNumber(contractor["휴대폰 번호"]));

    const companionsData = data.slice(1).map((companion) => ({
      gender: convertGender(companion.성별),
      dateOfBirth: companion.생년월일,
      koreanName: companion["이름(국문)"],
      englishName: companion["이름(영문)"],
      email: companion["이메일"],
      phoneNumber: companion["휴대폰 번호"],
    }));
    dispatch(setCompanions(companionsData));

    setIsFileProcessed(true);
  };

  const handleDownloadTemplate = () => {
    const templateContent = [
      "첫 번째 줄에는 반드시 계약자 정보를 입력해주세요. 미성년자 보험 적구는 계약자만 가능하오니 주의 해주세요.",
      "한번에 하나의 여행 보험 가입이 가능합니다. (여러 국가를 여행할 경우 첫 번째 여행국가를 입력하세요.)",
      "",
      "※ 휴대폰 번호 입력 시 앞에 ' 를 붙여주세요. (예: '01012345678)",
      "※ 생년월일은 YYYYMMDD 형식으로 8자리로 입력해주세요. (예: 19900101)",
      "※ 여행지는 국가명으로 입력해주세요. (예: 일본)",
      "※ 성별은 '남' 또는 '여'로 입력해주세요.",
      "",
      "국가,출발일,도착일,이름(국문),이름(영문),생년월일,성별,이메일,휴대폰 번호",
      "",
    ].join("\n");

    const BOM = "\uFEFF";
    const csvContent = BOM + templateContent;

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "간편견적_양식.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    setMessage("파일 다운로드가 완료되었습니다.");
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
        setMessage("CSV 파일만 업로드 가능합니다.");
        event.target.value = "";
        return;
      }

      setSelectedFile(file);
      setMessage("");
      setIsFileProcessed(false);

      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n");

        const data = [];
        let foundDataRow = false;

        for (const line of lines) {
          if (line.trim() === "") continue;

          if (line.match(/\d{8}/) && !foundDataRow) {
            foundDataRow = true;
            const values = line.split(",").map((value) => value.trim());

            if (values.length < 9) {
              setMessage("올바른 형식의 CSV 파일이 아닙니다.");
              setSelectedFile(null);
              event.target.value = "";
              return;
            }

            const rowData = {
              국가: values[0],
              출발일: values[1],
              도착일: values[2],
              "이름(국문)": values[3],
              "이름(영문)": values[4],
              생년월일: values[5],
              성별: values[6],
              이메일: values[7],
              "휴대폰 번호": formatPhoneNumber(
                values[8].replace(/[^0-9]/g, "")
              ),
            };

            data.push(rowData);
          } else if (foundDataRow && line.match(/\d{8}/)) {
            const values = line.split(",").map((value) => value.trim());
            if (values.length >= 9) {
              const rowData = {
                국가: values[0],
                출발일: values[1],
                도착일: values[2],
                "이름(국문)": values[3],
                "이름(영문)": values[4],
                생년월일: values[5],
                성별: values[6],
                이메일: values[7],
                "휴대폰 번호": formatPhoneNumber(
                  values[8].replace(/[^0-9]/g, "")
                ),
              };
              data.push(rowData);
            }
          }
        }

        if (data.length === 0) {
          setMessage("데이터를 찾을 수 없습니다. 파일을 확인해주세요.");
          setSelectedFile(null);
          event.target.value = "";
          return;
        }

        const firstPerson = data[0];
        const countryInfo = CityInfoData.find(
          (item) => item.korNatlNm === firstPerson.국가
        );

        if (!countryInfo) {
          setMessage(
            "등록되지 않은 국가입니다. 선택하신 국가를 다시 확인해주세요."
          );
          setSelectedFile(null);
          event.target.value = "";
          return;
        }

        setUploadedData(data);
        saveToRedux(data, countryInfo);
      };

      reader.readAsText(file, "UTF-8");
    }
  };

  const handleNextPage = () => {
    navigate("/trip/indemnity");
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>
          파일을 다운 받으신 후 작성해서 업로드 해주세요.
        </h2>

        <div className={styles.cardContent}>
          <div className={styles.uploadArea}>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileSelect}
              className={styles.fileInput}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <div className={styles.uploadIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
              </div>
              <p className={styles.uploadText}>
                CSV 파일을 드래그하거나 클릭하여 업로드하세요
              </p>
              {selectedFile && (
                <p className={styles.fileName}>
                  선택된 파일: {selectedFile.name}
                </p>
              )}
            </label>
          </div>

          <button
            onClick={handleDownloadTemplate}
            className={styles.templateButton}
          >
            <svg
              className={styles.buttonIcon}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            견적 양식 다운로드
          </button>

          {message && (
            <div
              className={
                message.includes("완료")
                  ? styles.successMessage
                  : styles.errorMessage
              }
            >
              {message}
            </div>
          )}

          <button
            onClick={handleNextPage}
            disabled={!uploadedData}
            className={styles.nextButton}
          >
            보험 가입하기
            <svg
              className={styles.buttonIcon}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          <EstimateButton
            startDate={startDate}
            endDate={endDate}
            selectedCountry={selectedCountry}
            userGender={userGender}
            userDateOfBirth={userDateOfBirth}
            companions={companions}
            koreanName={personalInfo.koreanName}
            englishName={personalInfo.englishName}
            email={personalInfo.email}
            phoneNumber={personalInfo.phoneNumber}
            isFileUploaded={isFileProcessed}
          />
        </div>
      </div>
    </div>
  );
}

export default ExcelUpLoad;
