import React, { useEffect, useState } from "react";
import ClaimHeader from "../../components/claimComponents/ClaimHeader";
import ClaimInfoSection from "../../components/claimComponents/ClaimInfoSection";
import AccidentInfoSection from "../../components/claimComponents/AccidentInfoSection";
import AccountInfoSection from "../../components/claimComponents/AccountInfoSection";
import SecretNumberSection from "../../components/claimComponents/SecretNumberSection";
import styles from "../../css/Claim/claimMembersInfo.module.css";
import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modal/ErrorModal";

function ClaimMembersInfo() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [text, setText] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [accidentDate, setAccidentDate] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankCd, setSelectedBankCd] = useState("");
  const [accidentDateKey, setAccidentDateKey] = useState("");
  const [claimTypeKey, setClaimTypeKey] = useState("");
  const [encryptedAccountNumber, setEncryptedAccountNumber] = useState(null);
  const [encryptedRRN, setEncryptedRRN] = useState("");
  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [encryptedResidentNumber, setEncryptedResidentNumber] = useState(null);
  const [relationCd, setRelationCd] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState({
    phoneNumber: "",
    accountHolderName: "",
    accountNumber: "",
  });
  const [selectedValues, setSelectedValues] = useState({
    clmTpCd: null,
    subClmTpCd: null,
  });
  const claim_types = selectedValues.clmTpCd;
  const accident_cause_cds = selectedValues.subClmTpCd;
  const selected_clmTpCd_Name = selectedValues.clmTpCd;

  const isSpecialClaimType =
    selected_clmTpCd_Name === "배상" || selected_clmTpCd_Name === "재물";

  useEffect(() => {
    if (selected_clmTpCd_Name === "재물" || selected_clmTpCd_Name === "배상") {
      setIsModal(true); // Show error modal
    } else {
      setIsModal(false); // Hide error modal
    }
  }, [selected_clmTpCd_Name]);

  useEffect(() => {
    setIsFormValid(validateFields());
  }, [
    phoneNumber,
    accountHolderName,
    accountNumber,
    accidentDate,
    encryptedAccountNumber,
    encryptedRRN,
    encryptedResidentNumber,
    selectedBank,
    selectedBankCd,
    accidentDateKey,
    claimTypeKey,
    selectedName,
    selected_clmTpCd_Name,
  ]);

  const navigate = useNavigate();
  const handleEncryptedAccountNumber = (encryptedData) => {
    setEncryptedAccountNumber(encryptedData);
  };
  const EncryptedAccountSecretNum = (encryptedData) => {
    setEncryptedResidentNumber(encryptedData);
  };
  const handleAccountNumberChange = (value) => {
    value = value.replace(/\D/g, "");
    setAccountNumber(value);
  };
  const handleDateSelect = (date) => {
    setAccidentDate(date);
  };
  const handleOptionClick = (key) => {
    setIsActive(key);
  };
  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length <= 1000) {
      setText(value);
    }
  };
  const isValidKoreanName = (name) => {
    const koreanRegex = /^[가-힣]+$/;
    return koreanRegex.test(name) && name.length >= 2;
  };

  const validateFields = () => {
    if (isSpecialClaimType) return true;

    const phoneRegex = /^01[0-9]-[0-9]{3,4}-[0-9]{4}$/;

    let phoneNumberError = phoneRegex.test(phoneNumber)
      ? ""
      : "유효하지 않은 전화번호입니다.";

    let accountHolderNameError = isValidKoreanName(accountHolderName)
      ? ""
      : "유효하지 않은 이름입니다.";

    setError({
      phoneNumber: phoneNumberError,
      accountHolderName: accountHolderNameError,
    });

    return !(phoneNumberError || accountHolderNameError);
  };

  const handleSubmit = () => {
    if (isFormValid || isSpecialClaimType) {
      const claimType = isActive;

      let formattedAccidentDate = null;
      if (accidentDate instanceof Date && !isNaN(accidentDate)) {
        const year = accidentDate.getFullYear();
        const month = (accidentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = accidentDate.getDate().toString().padStart(2, "0");
        formattedAccidentDate = `${year}${month}${day}`;
      }

      const phoneParts = phoneNumber.split("-");
      const arCcoCd1 = phoneParts[0] || "";
      const telofNo1 = phoneParts[1] || "";
      const telSno1 = phoneParts[2] || "";

      const collectionData = {
        text,
        encryptedData: encryptedRRN?.encryptedData || null,
        encryptedKey: encryptedRRN?.encryptedKey || null,
        iv: encryptedRRN?.iv || null,
        accountEncryptedData: encryptedAccountNumber?.encryptedData || null,
        accountIv: encryptedAccountNumber?.iv || null,
        accountEncryptedKey: encryptedAccountNumber?.encryptedKey || null,
        accountHolderName,
        depositorEncryptedKey: encryptedResidentNumber?.encryptedKey || null,
        depositorIv: encryptedResidentNumber?.iv || null,
        depositorEncryptedData: encryptedResidentNumber?.encryptedData || null,
        claimType,
        claim_types,
        accident_cause_cds,
        accidentDate: formattedAccidentDate,
        selectedBank,
        selectedBankCd,
        relationCd,
        arCcoCd1,
        telofNo1,
        telSno1,
        accidentDateKey,
        claimTypeKey,
        selectedName,
      };
      navigate("/claimAgree", { state: collectionData });
    }
  };

  return (
    <>
      <ClaimHeader titleText="해외여행 보험" />
      <div className={styles.sectionWrap}>
        <ClaimInfoSection
          isActive={isActive}
          handleOptionClick={handleOptionClick}
          setEncryptedRRN={setEncryptedRRN}
          setSelectedClaimDate={setSelectedClaimDate}
          setSelectedName={setSelectedName}
          isButtonDisabled={!isSpecialClaimType}
        />
        <div className={styles.boundaryWrap}>
          <div className={styles.boundary}></div>
        </div>
        <AccidentInfoSection
          text={text}
          handleChange={handleChange}
          handleDateSelect={handleDateSelect}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          error={error}
          setError={setError}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          setAccidentDate={setAccidentDate}
          accidentDate={accidentDate}
          setClaimTypeKey={setClaimTypeKey}
          setAccidentDateKey={setAccidentDateKey}
          selectedClaimDate={selectedClaimDate}
          isButtonDisabled={!isSpecialClaimType}
          encryptedRRN={encryptedRRN}
        />
        <div className={styles.boundaryWrap}>
          <div className={styles.boundary}></div>
        </div>
        <AccountInfoSection
          error={error}
          setError={setError}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          accountHolderName={accountHolderName}
          setAccountHolderName={setAccountHolderName}
          handleAccountNumberChange={handleAccountNumberChange}
          isValidKoreanName={isValidKoreanName}
          selectedBank={selectedBank}
          setSelectedBank={setSelectedBank}
          selectedBankCd={selectedBankCd}
          setSelectedBankCd={setSelectedBankCd}
          handleEncryptedAccountNumber={handleEncryptedAccountNumber}
          EncryptedAccountSecretNum={EncryptedAccountSecretNum}
          selectedClaimDate={selectedClaimDate}
          setRelationCd={setRelationCd}
          isButtonDisabled={!isSpecialClaimType}
        />
        <SecretNumberSection />
      </div>
      <div className={styles.buttonWrap}>
        <div className={styles.buttonBoundary}></div>
        <div className={styles.buttonBackground}></div>
        <Button
          buttonText="다음"
          onClick={handleSubmit}
          disabled={!isFormValid && !isSpecialClaimType}
        />
      </div>
      {isModal && (
        <ErrorModal
          message="재물 또는 배상 유형은 신청서를 작성 후 업로드 부탁드립니다."
          onClose={() => setIsModal(false)}
        />
      )}
    </>
  );
}

export default ClaimMembersInfo;
