import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../../css/calculationProcessCSS/indemnity.module.css";
import { selectData, setPriceData } from "../../redux/store";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Button from "../../components/common/Button";
import PlanData from "../../api/PlanData.json";
import meritzLogo from "../../assets/meritzLogo.svg";
import Loading from "../../components/modal/Loading";

const Indemnity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Redux state
  const selectedData = useSelector((state) => state.plan.selectedData);
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const endDate = useSelector((state) => state.calendar.selectedEndDate);
  const gender = useSelector((state) => state.user.gender);
  const dateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const companions = useSelector((state) => state.companions);
  const recommendedCountry = useSelector(
    (state) => state.country.recommendedCountryData
  );
  const selectedCountryData = useSelector(
    (state) => state.country.selectedCountryData
  );
  const priceData = useSelector((state) => state.priceData.priceData);
  const userInfo = useSelector((state) => state.user);

  // Local state
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priceActivated, setPriceActivated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // CSV 데이터 파싱
  const csvData = useMemo(() => {
    try {
      const dataParam = searchParams.get("data");
      return dataParam ? JSON.parse(decodeURIComponent(dataParam)) : null;
    } catch (error) {
      console.error("CSV 데이터 파싱 오류:", error);
      return null;
    }
  }, [searchParams]);

  // Derived state
  const userPersonnel = useMemo(
    () => (csvData ? csvData.inspeCnt : [userInfo].length),
    [csvData, userInfo]
  );

  const companionsPersonnel = useMemo(
    () => (csvData ? 0 : companions.length),
    [csvData, companions]
  );

  const totalPersonnel = useMemo(
    () => userPersonnel + companionsPersonnel,
    [userPersonnel, companionsPersonnel]
  );

  // Calculate national code
  const natlCds = useMemo(() => {
    if (csvData) {
      return csvData.natlCd;
    }
    if (selectedCountryData?.cityNatlCd) {
      return selectedCountryData.cityNatlCd;
    }
    if (recommendedCountry?.cityNatlCd) {
      return recommendedCountry.cityNatlCd;
    }
    return null;
  }, [csvData, selectedCountryData, recommendedCountry]);

  // Fetch data effect
  useEffect(() => {
    const fetchIndemnityData = async () => {
      // CSV 데이터가 있거나 필수 데이터가 모두 있는 경우에만 실행
      if (
        !csvData &&
        (!startDate || !endDate || !natlCds || totalPersonnel === 0)
      ) {
        return;
      }

      setIsLoading(true);

      try {
        let requestData;

        if (csvData) {
          // CSV 데이터 사용
          requestData = csvData;
        } else {
          // Redux 상태 데이터 사용
          const userInfoData = {
            inspeNm: "사용자",
            inspeBdt: dateOfBirth,
            gndrCd: gender,
          };

          const companionInfoData = companions.map((companion, index) => ({
            inspeNm: `동반자${index + 1}`,
            inspeBdt: companion.dateOfBirth,
            gndrCd: companion.gender,
          }));

          requestData = {
            insBgnDt: startDate,
            insEdDt: endDate,
            natlCd: natlCds,
            inspeCnt: totalPersonnel.toString(),
            inspeInfos: [userInfoData, ...companionInfoData],
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/price`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (!response.ok) {
          throw new Error(`알 수 없는 오류입니다.: ${response.status}`);
        }

        const result = await response.json();
        console.log("API 응답:", result);
        dispatch(setPriceData(result));
      } catch (error) {
        console.error("API 요청 실패:", error);
        setErrorMessage("데이터를 불러오는 중 오류가 발생했습니다.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchIndemnityData();
  }, [
    csvData,
    startDate,
    endDate,
    natlCds,
    totalPersonnel,
    dateOfBirth,
    gender,
    companions,
    dispatch,
  ]);

  useEffect(() => {
    const storedPriceActivated = sessionStorage.getItem("priceActivated");
    if (storedPriceActivated !== null) {
      setPriceActivated(storedPriceActivated === "true");
    }
  }, []);

  const priceToString = useCallback((price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const handleItemClick = useCallback(
    (platform, index) => {
      dispatch(selectData(platform));
      sessionStorage.setItem("selectedDataIndex", index);
      if (platform.name === "메리츠 추천 플랜") {
        setShowConfirmModal(true);
      }
    },
    [dispatch]
  );

  const handleConfirm = useCallback(() => {
    if (!priceActivated) {
      setErrorMessage("추천 플랜 가격을 활성화 후 이용해주세요.");
      return;
    }
    sessionStorage.setItem("selectedPlan", selectedData.name);
    navigate("/trip/confirm");
  }, [priceActivated, selectedData, navigate]);

  const handleRecommendationClick = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setPriceActivated(true);
    sessionStorage.setItem("priceActivated", "true");
    setShowConfirmModal(false);
  }, []);

  // 플랜 목록 렌더링 (기존 코드 유지)
  const memoizedPlatforms = useMemo(() => {
    const reorderedPlans = [
      PlanData.insurancePlans.find((plan) => plan.name === "메리츠 추천 플랜"),
      PlanData.insurancePlans.find((plan) => plan.name === "메리츠 고급 플랜"),
      PlanData.insurancePlans.find((plan) => plan.name === "메리츠 알뜰 플랜"),
    ];

    return reorderedPlans.map((platform, index) => {
      const isSelected = selectedData && selectedData.name === platform.name;

      let priceText = "???";
      if (priceData) {
        switch (platform.name) {
          case "메리츠 알뜰 플랜":
            priceText =
              priceData.BASIC?.ttPrem !== undefined
                ? `${priceToString(priceData.BASIC.ttPrem)}원`
                : "???";
            break;
          case "메리츠 고급 플랜":
            priceText =
              priceData.PREMIUM?.ttPrem !== undefined
                ? `${priceToString(priceData.PREMIUM.ttPrem)}원`
                : "???";
            break;
          case "메리츠 추천 플랜":
            if (priceActivated) {
              priceText =
                priceData.RECOMMEND?.ttPrem !== undefined
                  ? `${priceToString(priceData.RECOMMEND.ttPrem)}원`
                  : "???";
            }
            break;
          default:
            break;
        }
      }

      return (
        <div
          key={index}
          className={`${styles.wrap} ${isSelected ? styles.selected : ""}`}
          onClick={() => handleItemClick(platform, index)}
        >
          <div className={styles.ContentsFlexRow}>
            <div className={styles.ContentsImgWrap}>
              <img src={meritzLogo} alt="logo" className={styles.ContentsImg} />
            </div>
            <div className={styles.platformInfoWrap}>
              <span className={styles.platformName}>{platform.name}</span>
              <div className={styles.platformPrice}>{priceText}</div>
            </div>
          </div>
          <div className={styles.platformDetailWrap}>
            {platform.name === "메리츠 추천 플랜" && (
              <>
                <p>국가별, 특징을 고려한 고객 맞춤 보장!</p>
                <p>안전하고 안심할 수 있는 최적의 플랜 제공</p>
              </>
            )}
            {platform.name === "메리츠 고급 플랜" && (
              <>
                <p>엑티비티한 여행 계획이 있으세요?</p>
                <p>스카이 다이빙, 행글라이딩, 스쿠버 다이빙 등 보장</p>
              </>
            )}
            {platform.name === "메리츠 알뜰 플랜" && (
              <>
                <p>해외 출장이나 가볍게 떠나는 여행이세요?</p>
                <p>경제적인 여행, 합리적인 보장</p>
              </>
            )}
          </div>
          {platform.name === "메리츠 추천 플랜" && (
            <div
              className={styles.recommandModalBtn}
              onClick={handleRecommendationClick}
            >
              <p className={styles.recommandModaTitle}>
                클릭 한번으로 최적화된 플랜을 받아보세요.
              </p>
            </div>
          )}
        </div>
      );
    });
  }, [
    priceData,
    priceActivated,
    priceToString,
    handleItemClick,
    selectedData,
    handleRecommendationClick,
  ]);

  return (
    <div className={styles.indemnityContents}>
      <div className={styles.indemnityWrap}>
        <div className={styles.title}>
          <p>어떤 플랜이 좋으신가요? </p>
          <div className={styles.subTitle}>
            <p>최적화된 플랜</p>을 추천드려요
          </div>
        </div>
        {isLoading ? <Loading /> : memoizedPlatforms}
        <ConfirmModal isOpen={showConfirmModal} onClose={handleModalClose} />
        <div className="flex flex-col">
          <Button
            onClick={handleConfirm}
            disabled={!selectedData}
            buttonText="확인하기"
          />
        </div>
        {errorMessage && (
          <ErrorModal
            message={errorMessage}
            onClose={() => setErrorMessage("")}
          />
        )}
      </div>
    </div>
  );
};

export default Indemnity;
