import styles from "../../css/calculationProcessCSS/Confirm.module.css";
import meritzLogo from "../../assets/meritzLogo.svg";
import confirmCheck from "../../assets/confirmCheck.svg";
import { useSelector } from "react-redux";
import { getGenderString } from "../../components/calculationProcessPart/Gender";
import React, { useEffect, useState } from "react";
import ModifyModal from "../modal/Modifymodal";

function ConfirmAndGuarantee() {
  const selectedData = useSelector((state) => state.plan.selectedData);
  const priceData = useSelector((state) => state.priceData.priceData);
  const companions = useSelector((state) => state.companions);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userAge, setUserAge] = useState("");
  const selectedStartDate = useSelector(
    (state) => state.calendar.selectedStartDate
  );
  const [userPremium, setUserPremium] = useState(null);
  const [companionPremiums, setCompanionPremiums] = useState([]);
  const [isDataVisible, setIsDataVisible] = useState(false);
  const selectedEndDate = useSelector(
    (state) => state.calendar.selectedEndDate
  );
  const totalPrice = useSelector((state) => state.totalPrice.totalPrice);
  const selectedPlanName = useSelector((state) => state.plan.selectedPlanName);
  const userDateOfBirth = useSelector((state) => state.user.dateOfBirth);
  const userGender = useSelector((state) => state.user.gender);
  const membersInfo = useSelector((state) => state.members.members);
  const companionDataInfo = membersInfo.companionData || [];
  const companionName = companionDataInfo.map((e) => e.name);

  const getGenderCode = (birth, gender) => {
    const year = parseInt(birth.substring(0, 4));
    const baseCode = year >= 2000 ? 2 : 0;
    return (baseCode + parseInt(gender)).toString();
  };

  useEffect(() => {
    if (
      selectedData.name === "메리츠 추천 플랜" ||
      selectedData.name === "메리츠 고급 플랜" ||
      selectedData.name === "메리츠 알뜰 플랜"
    ) {
      let planData;
      if (selectedData.name === "메리츠 추천 플랜") {
        planData = priceData.RECOMMEND;
      } else if (selectedData.name === "메리츠 고급 플랜") {
        planData = priceData.PREMIUM;
      } else if (selectedData.name === "메리츠 알뜰 플랜") {
        planData = priceData.BASIC;
      } else {
        return;
      }

      if (planData && planData.opapiGnrCoprCtrInspeInfCbcVo) {
        const user = planData.opapiGnrCoprCtrInspeInfCbcVo.find(
          (item) => item.cusNm === "사용자"
        );
        const companions = planData.opapiGnrCoprCtrInspeInfCbcVo.filter(
          (item) => item.cusNm !== "사용자"
        );

        setUserPremium(user ? user.ppsPrem : null);
        setCompanionPremiums(
          companions.map((companion, index) => ({
            premium: companion.ppsPrem,
            dateOfBirth: companions[index]?.dateOfBirth || null,
            gender: companions[index]?.gender || companion.gndrCd,
          }))
        );
      }
    }
  }, [selectedData.name, priceData, companions]);

  const handleModifyClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);
    return `${year}년 ${month}월 ${day}일`;
  };
  const formattedDate = formatDate(userDateOfBirth);

  useEffect(() => {
    if (userDateOfBirth) {
      const birthYear = parseInt(userDateOfBirth.substring(0, 4), 10);
      const currentYear = new Date().getFullYear();
      const age = currentYear - birthYear;
      setUserAge(age);
    }
  }, [userDateOfBirth]);

  const toggleDataVisibility = () => {
    setIsDataVisible(!isDataVisible);
  };

  return (
    <>
      <div className={styles.confirmContents}>
        <div className={styles.confirmWrap}>
          <h3 className="text-lg font-semibold mb-4">
            선택하신 보장내용과 보험료를 안내해드립니다.
          </h3>
          <section className={styles.section}>
            <div className={styles.sectionWrap}>
              <div className={styles.sectionWrap_title}>
                <div className={styles.ContentsImgWrap}>
                  <img
                    src={meritzLogo}
                    alt="logo"
                    className={styles.ContentsImg}
                  />
                </div>
                <p className="text-xl font-semibold">
                  {selectedPlanName || selectedData.name}
                </p>
              </div>
              <div className={styles.section_DataContents}>
                <div className={styles.dataContext}>
                  <p>가입내용</p>
                  <span
                    onClick={handleModifyClick}
                    className={styles.modifyButton}
                  >
                    수정하기
                  </span>
                </div>
                <div className={styles.section_DataContentsWrap}>
                  <div className={styles.dataFlexbox}>
                    <p>출발 일정일</p>
                    <span>{formatDate(selectedStartDate)}</span>
                  </div>
                  <div className={styles.dataFlexbox}>
                    <p>도착 일정일</p>
                    <span>{formatDate(selectedEndDate)}</span>
                  </div>
                  <div className={styles.dataFlexbox}>
                    <p>가입자</p>
                    <div className={styles.dataFlexCol}>
                      <span>
                        {getGenderString(userGender)} (만 {userAge}세) 외{" "}
                        {companions.length}명
                      </span>
                      <p> ({formattedDate})</p>
                    </div>
                  </div>
                  <div className={styles.section_cost}>
                    <div className={styles.section_costWrap}>
                      <p>총 보험료</p>
                      <span>
                        {parseInt(
                          totalPrice.replace(/[^0-9]/g, "")
                        ).toLocaleString()}
                        원
                      </span>
                      <img
                        src={confirmCheck}
                        className="cursor-pointer ml-2"
                        onClick={toggleDataVisibility}
                        alt="confirmCheck"
                      />
                    </div>
                    <div
                      className={`bg-white rounded-xl w-full ${
                        isDataVisible ? "" : "hidden"
                      } bg-opacity-0 py-4`}
                    >
                      <div className={styles.detailInfo}>
                        <div className={styles.TextFlex}>
                          <p>
                            {membersInfo.name}(계약자) :{" "}
                            {userPremium
                              ? `${parseInt(
                                  userPremium.replace(/[^0-9]/g, "")
                                ).toLocaleString()}원`
                              : "정보 없음"}
                          </p>
                          <p>
                            ({userDateOfBirth.slice(2, 8)} -{" "}
                            {getGenderCode(userDateOfBirth, userGender)}
                            ******)
                          </p>
                        </div>
                        {companionPremiums.length > 0 &&
                          companions.length > 0 &&
                          companions.map((companion, index) => (
                            <div key={index} className={styles.TextFlex}>
                              <p>
                                {companionName[index]} :{" "}
                                {parseInt(
                                  companionPremiums[index]?.premium.replace(
                                    /[^0-9]/g,
                                    ""
                                  )
                                ).toLocaleString()}
                                원
                              </p>
                              <p>
                                {" "}
                                ({companion.dateOfBirth.slice(2, 8)} -
                                {getGenderCode(
                                  companion.dateOfBirth,
                                  companion.gender
                                )}
                                ******)
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ModifyModal isOpen={isModalOpen} onClose={closeModal} />
          </section>
        </div>
      </div>
    </>
  );
}

export default ConfirmAndGuarantee;
