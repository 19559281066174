const serviceData = [
  {
    content: "서비스 이용약관",
    dates: ["공고일: 2024.07.02일", "기준일: 2024.07.02일"],
    title: "제1장 총칙",
    sections: [
      {
        section: "제1조(목적)",
        subSections: [
          "이 약관은 주식회사 리트러스트(이하 “회사”라 합니다)가 운영하는 인슈어트러스트 플랫폼 서비스(이하 “서비스”라 합니다)의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 회사는 보험대리점으로서 제휴보험사로부터 보험계약 및 제반사항, 운영에 대한 권한을 부여받아 회원 및 비회원에게 서비스를 제공합니다.",
        ],
      },
      {
        section: "제2조(용어의 정의)",
        subSections: [
          "① 본 약관에서 사용하는 용어의 정의는 다음과 같으며, 여기에 명시되지 않은 용어는 관련 법령 및 일반적인 상관례에 따라 해석됩니다.",
          "1. 회원: 회사가 발행하는 멤버십 NFT를 보유하였거나 보험에 가입 후 NFT를 본인의 지갑으로 발급받은 후 플랫폼 사용을 위한 정보제공을 동의한 자를 말합니다.  ",
          "2. 보험가입 비회원: NFT를 발급받지 않았지만 회사를 통해 보험에 가입하고 플랫폼 사용을 위한 절차를 거처서 플랫폼을 사용하는 자를 말합니다.",
          "3. 앱/웹 서비스: 회사가 서비스 이용을 위해 응용소프트웨어 또는 웹을 통해 제공하는 서비스를 말합니다.",
          "4. Klip계정: NFT를 발급받은 보유회원은 카카오톡의 클립지갑과 연동된 Klip계정을 이용하여 회사의 서비스 일부를 편리하게 이용할 수 있습니다.",
          "5. 본인인증: 본인확인기관을 통해 본인 일치 여부를 확인하는 것을 의미합니다 (Pass, KCB, NICE신용평가정보 등).",
          "6. 개인정보: 살아 있는 개인에 관한 정보로서 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함합니다)를 말합니다.",
          "7. 제휴서비스: 회사가 제공하는 서비스에서 회사와 제휴 관계에 있는 제3자가 운영하는 서비스를 의미합니다",
          "8. 제공자: 제휴서비스를 회원에게 직접 개별 서비스로 제공하는 자를 말합니다.",
          "9. 회원사(제휴사): 플랫폼 운영사 혹은 회원을 보유하고 있는 단체로서, 해당 단체의 회원규약, 서비스 이용약관, 단체규약에 따라 회원사를 보험계약자로 하고 그 회원들을 피보험자로 하는 단체보험을 체결한 주체를 의미합니다.",
          "10. In-App 또는 웹 결제: 앱 또는 웹에서 유료상품을 구매하기 위한 결제행위를 말합니다.",
          "11. 보험회사: 보험업법에 따른 허가를 받아 보험업을 경영하는 자를 말합니다.",
          "12. 보험상품: 보험업법 제2조제1호에서 정의된 상품을 의미합니다.",
          "13. 보험계약: 회사가 단체보험 규약에 따라서 회원을 대표하는 보험계약자가 되고 회원을 피보험자로 하여 제휴 보험사와 체결한 단체보험계약을 말합니다.",
          "14. 멤버스포인트: 회사가 회원에게 제공하는 적립서비스를 말 합니다.",
          "15. 멤버스레벨: 다양한 활동의 일환으로 서비스 이용자의 레벨을 나타내는것을 말합니다.",
          "16. 간편결제: 모바일 기기에 신용카드나 계좌번호 등의 결제정보를 등록해 비밀번호 입력 등으로 유료상품을 구매하기 위한 결제행위를 의미합니다.",
          "17. 포인트 결제 : 회원이 적립 받은 포인트를 회사가 지정한 사용처에서 상품 등 구매 시 회사가 정한 절차를 거친 후 결제에 사용하는 것을 말합니다.",
          "② 본 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관련법령 및 기타 일반적인 상관례에 의합니다.",
        ],
      },
      {
        section: "제3조(약관의 명시와 개정)",
        subSections: [
          "① 회사는 고객이 서비스를 이용하기 전에 본 약관을 앱 또는 웹 서비스 화면에 게시하고, 본 약관의 중요한 내용을 확인할 수 있도록 합니다.",
          "②본 약관의 내용은 앱 또는 웹 서비스 화면에 게시하거나 기타의 방법으로 고객에게 공시하고, 이에 동의한 고객이 약관에서 정한 절차에 따라 NFT를 발급받거나 또는 발급받지 않고 서비스를 이용함으로써 효력이 발생합니다. 회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 이메일, 사업자등록번호, 통신판매업 신고번호 등을 고객이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의 내용은 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
          "③ 회사는 고객이 약관에 동의하기에 앞서 약관에 있는 내용 중 청약철회, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 확인을 구하여야 합니다.",
          "④ 회사가 약관을 개정할 경우에는 그 개정약관은 적용일자 기준으로 그 이전에 체결한 계약에 대해서는 개정 이전의 약관이 적용되며, 적용일자가 도래했을 때에는 개정약관이 적용됩니다.",
          "⑤ 본 약관은 회사가 필요하다고 인정되는 경우 대한민국 법령의 범위 내에서 개정할 수 있으며 회사가 약관을 개정할 경우에는 적용예정일 및 개정사유를 명시하고, 회사는 본 약관의 내용을 이용자가 알 수 있도록 서비스가 제공되는 앱 또는 웹의 초기화면에 게시하거나 연결화면을 제공하는 방법으로 이용자에게 공지합니다.",
          "⑥ 회사는 필요하다고 인정되는 경우에는 본 약관을 변경할 수 있습니다. 또한 법률 또는 정부의 명령, 지시, 권고나 법원의 판결 등으로 인한 필요에 따라 서비스의 전부 또는 일부를 변경 또는 중지할 수 있습니다.",
          "⑦ 회사가 약관을 개정하는 경우, 회사는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지하거나 수신통지방법(이메일, SMS 등)을 통해 개별적으로 알려드립니다.",
          "⑧ 회사는 본 조 제7항의 고지나 통지를 할 경우 '회원이 변경에 동의하지 아니한 경우 고지나 통지를 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 본다.'라는 취지의 내용을 고지하거나 통지합니다.",
          "⑨ “회원”은 개정된 약관에 대해 동의하지 않을 권리가 있으며, 개정된 약관에 동의하지 않을 경우 이용계약을 해지할 수 있습니다. 회원이 회사의 전항 단서에 따른 약관의 불리한 변경에 대하여 적용예정일까지 회사에게 부동의 의사를 표시하지 않거나 이용계약을 해지하지 않은 경우 변경된 약관을 승인한 것으로 봅니다.",
          "⑩ 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다.",
        ],
      },
      {
        section: "제4조 (약관 외 준칙)",
        subSections: [
          "① 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “개별약관”이라고 합니다)를 정하여 미리 공지할 수 있습니다. 회원 및 이용신청자가 개별약관에 동의하고 특정 서비스를 이용하는 경우 개별약관이 우선적으로 적용되고, 본 약관은 보충적인 효력을 갖습니다. 개별약관의 게시, 변경에 관해서는 제3조의 내용을 준용합니다. 단, 회사는 개별약관을 서비스가 제공되는 앱이나 웹의 특정 화면에만 게시할 수 있습니다.",
          "② 회사는 특정 회원과 개별적으로 본 약관 및 개별약관에 규정된 내용과 다른 내용의 계약(이하 “특별약관”이라고 합니다)을 체결할 수 있습니다. 이 경우 특별약관이 본 약관 및 개별약관에 우선하여 적용됩니다.",
          "③ 회원의 개별 “제휴서비스”의 이용에 대해서는 “제공자”의 약관, 운영정책 및 이용조건이 본 약관에 우선하여 적용됩니다.",
          "④ 본 약관에 명시되지 않은 사항은 『금융소비자 보호에 관한 법률』, 『개인정보보호법』, 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『전자문서 및 전자거래기본법』, 『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『소비자기본법』, 『표시광고의 공정화에 관한 법률』 등 관련법령 또는 상관례에 따릅니다.",
          "⑤ 회원은 회사가 제공하는 서비스를 이용함에 있어서 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『전자문서 및 전자거래기본법』, 『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『소비자기본법』, 『표시광고의 공정화에 관한 법률』 등 관련법령을 준수하여야 하며, 본 약관의 규정을 들어 관련법령 위반에 대한 면책을 주장할 수 없습니다.",
        ],
      },
    ],
  },
  {
    title: "제2장 서비스 이용계약 및 회원관리",
    sections: [
      {
        section: "제5조 (이용신청 및 방법)",
        subSections: [
          "① 회사가 제공하는 서비스에 관한 이용계약(이하 “이용계약”이라고 합니다)은 해당 서비스를 이용하고자 하는 자의 멤버십NFT발급 또는 보험가입통한 NFT발급과 “개인정보 수집∙이용∙취급∙위탁 동의서” 내용에 대하여 동의함으로써 성립합니다. 회사는 이용승낙의 의사를 NFT발급을 통하거나, 해당 서비스 화면에 게시하거나, 이메일 또는 기타 방법으로 이용신청자에게 통지합니다.",
          "② 회원 및 이용신청자는 이용신청시 회사에서 요구하는 제반 정보를 제공하여야 합니다.",
          "③ 회원 및 이용신청자는 제1항의 이용신청시 본인의 실명 및 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 본 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 환급 없이 이용계약을 취소하거나 해지할 수 있습니다.",
          "④회사는 미성년자인 이용신청자의 이용신청애 대한 승낙을 제한할 수 있습니다.",
          "⑤NFT의 지갑주소와 연동되어 카카오톡Klip계정을통한 로그인을 기반으로, 계정은 실명 1인당 1개의 계정을 사용하는 것을 원칙으로 합니다.",
          "⑥ 제공자 및 제휴서비스에서 제공하는 개별 서비스는 개인정보 제3자 제공동의(제공자의 이용약관 및 운영정책)를 받아 이용할 수 있습니다.",
          "⑦ 회원가입은 보험상품가입을 통한 Klip계정 연동을 통해 가능합니다. Klip계정으로만 가입하는 경우 추가적인 정보를 제공해야 할 수 있습니다. ",
          "⑧ 회사는 회사에서 제공하는 앱 또는 웹 서비스, 회원사 및 협력 채널 등의 각 고객을 대표한 단체보험계약자로서 회원 비원으로부터 보험 계약 체결 동의를 받고 회원 또는 비회원을 피보험자로 하는 단체보험을 체결할 수 있습니다.",
          "⑨ 동반가입의 경우, 회원 또는 비회원 중 1인이 위 제2조 11호 “보험계약”에 의하여 나머지 동반가입자들로부터 보험가입에 필요한 정보(성명, 휴대전화번호, 주민등록번호 및 기타 보험료 산출에 필요한 정보)를 회사에 고지하는 때에 보험가입에 필요한 각종 개인정보 활용동의와 본 약관에 동의할 권한을 부여받은 것으로 간주하고, 회사는 이를 바탕으로 보험 계약 체결 및 이용을 하게 할 수 있습니다.",
          "⑩ 회원은 언제든지 이 “약관”과 “개인정보 수집∙이용∙취급∙위탁 동의서”의 동의를 철회함에 따라 이용계약을 해지하며 회원에서 탈퇴할 수 있습니다. 이 경우 회원이 가입되어 있던 보험계약의 유지 여부는 보험약관에서 정한 바에 따릅니다.",
        ],
      },
      {
        section: "제6조 (이용신청의 승낙과 제한)",
        subSections: [
          "① 회사가 요구하는 정보에 대하여 회원 및 이용신청자가 실제 정보를 정확히 기재하여 이용신청을 한 경우에는 상당한 이유가 없는 한 이용신청을 승낙합니다.",
          "② 회원 및 이용신청자가 “개인정보 수집∙이용∙취급∙위탁 동의서”에 대하여 동의 후 회원가입절차를 완료한 시점에 이용신청이 승낙된 것으로 봅니다.",
          "③ 회사는 다음의 각 호에 해당하는 회원 및 이용신청자의 신청에 대하여는 승낙을 제한할 수 있으며, 이 사유가 해소될 때까지 승낙을 유보하거나 승낙한 이후에도 이용계약을 해지할 수 있습니다.",
          "1. 기술상 서비스 제공이 불가능한 경우",
          "2. 실명이 아니거나, 다른 사람의 명의사용 등 이용자 등록 시 허위로 신청하는 경우",
          "3. 이용자 등록 사항을 누락하거나 오기하여 신청하는 경우",
          "4. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우",
          "5. 서비스 이용목적 또는 이용행위가 법령에 위반되거나 제3자의 권리를 침해할 우려가 있는 경우",
          "6. 악성 프로그램 및 버그 이용, 시스템 취약점 악용 등 부정한 방법으로 서비스를 사용한 경우",
          "7. 기타 회사가 정한 이용신청 요건이 만족되지 않아 서비스 제공을 계속하는 것이 현저히 부적절하다고 판단되는 경우",
          "8. 고객센터 문의 시 폭언, 욕설, 성희롱 및 인터넷 유포 위협을 1년 내 2회 이상 한 경우",
          "9. 만 14세 미만의 자가 회사가 정한 절차를 따르지 아니하고 이용신청 하는 경우④ 회사는 다음 각 호의 어느 하나에 해당하는 위 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.",
          "④ 회사는 다음 각 호의 어느 하나에 해당하는 위 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.",
          "1. 본 약관 제5조에 위반하여 이용신청을 하는 경우",
          "2. 회사의 본인인증절차에서 실명가입신청이 아님이 확인된 경우",
          "3. 이미 가입된 회원인 경우",
          "4. 이미 가입한 회원과 휴대폰번호 또는 이메일이 동일한 경우",
          "5. 본 약관 제6조제3항에 기하여 회사에 의하여 이용약관이 해지된 전(前)회원이 재이용 신청을 하는 경우",
          "6. 가입신청시 기재하여야 할 필수 기재항목에 허위사실을 기재한 경우",
          "7. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우",
          "⑤ 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.",
          "1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우",
          "2. 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우",
          "3. 기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우",
        ],
      },
      {
        section: "제6조의 2 (이용신청 및 방법)",
        subSections: [
          "① 만 14세 미만의 자는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 법정대리인의 동의를 얻은 후 가입 및 서비스 이용을 신청하여야 하며, 회사가 관련 법령에 따라 법정대리인으로부터 개인정보 처리에 관한 동의를 받을 수 있도록 법정대리인의 정보를 제공하여야 합니다.",
          "② 회사는 개인정보 처리에 관한 법정대리인의 동의가 확인되지 아니하거나, 동의의 유효성 여부가 의심되는 만 14세 미만의 자에 대하여 가입신청 거절, 이용제한, 이용계약의 해지 등의 조치를 취할 수 있습니다.",
          "③ 위 제1항의 법정대리인은 회사가 마련한 방법과 절차에 따라 회사에 만 14세 미만 자의 개인정보의 열람, 정정, 갱신을 요청하거나 가입신청에 대한 동의를 철회할 수 있습니다.",
          "④ 회사는 만 14세 미만의 자가 본 약관에 따라 적합한 가입신청을 한 경우에도, 만 14세 미만의 자에 대하여 청소년 보호법, 신용평가 관련 법령, 그 외 정부기관의 가이드라인, 사회상규 등에 따라 미성년자에게 제공하는 것이 부적절하다고 판단되는 경우에는 서비스에 대한 이용제한 등의 조치를 취할 수 있습니다.",
        ],
      },
      {
        section: "제6조의 3(보험계약 신청)",
        subSections: [
          "① 회사는 회원 및 이용신청자가 보험계약을 신청할 수 있도록 다음의 내용을 알기 쉽게 제공하여야 합니다.",
          "1. 보험상품 등의 검색 및 선택",
          "2. 성명, 휴대전화번호, 이메일 등의 입력",
          "3. 단체보험규약, 단체보험서비스 이용약관, 보험상품약관내용, 기타 개인정보와 관련된 사항 등 보험계약과 관련하여 각종 법령에 규정된 필요 사항들",
          "4. 본 약관 및 보험계약에 있어서 각종 법령에 규정된 필요한 사항들에 동의한다는 표시",
          "5. 결제 방법의 선택",
          "② 회사는 미성년자가 보험계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하며, 일부 보험상품의 경우 연령 제한으로 인하여 보험 가입이 불가능할 수 있습니다.",
          "③ 회원 및 이용신청자는 서비스 이용 시 대금 지급 방법은 다음 각호의 방법 중 선택하여 이용할 수 있습니다.",
          "1. 신용카드 결제(간편결제 포함)",
          "2. 기타 전자적 방법의 대금 지급 등",
        ],
      },
      {
        section: "제7조 (이용계약의 변경 및 취소)",
        subSections: [
          "① 회원은 서비스 계약 후 전화, 앱 또는 웹 등을 이용하여 회사가 정한 방법과 기준에 따라 개별 금융상품의 계약내용변경을 신청할 수 있습니다.",
          "② 보험사의 요율변동에 따라 보험료에 변동이 있을 수 있으며 보장개시 전까지 변경이 가능합니다.",
          "③ 회사는 회원이 가입한 보험상품에 대하여 잔여 기간에 대한 환불을 진행할 수 있으며 환불 기준 및 환불 금액은 보험약관에서 정한 기준에 따릅니다.",
          "④ 기존에 체결했던 보험계약을 해지하고 다른 보험계약을 체결하는 경우, 보험료 인상 및 보장내용이 달라지거나 보험인수가 거절될 수 있습니다.",
        ],
      },
      {
        section: "제8조 (이용계약의 종료)",
        subSections: [
          "① 회원 또는 회사는 본 약관에서 정한 절차에 따라 이용계약을 해지할 수 있습니다.",
          "② 회원의 해지",
          "1. 회원은 별도의 방법으로 정해진 내용 및 절차에 따라 해지를 신청하며, 회사가 이를 확인함으로써 이용계약의 해지의 효력이 발생합니다.",
          "2. 본 항에 따라 해지를 한 회원은 본 약관이 정하는 회원가입절차와 관련조항에 따라 회원으로 다시 가입할 수 있습니다. 다만 회원이 부정한 목적으로 회원탈퇴 후 재가입을 신청하는 경우 회사는 재가입을 제한할 수 있습니다.",
          "3. 회원 탈퇴 시 모든 권한이 함께 삭제되며 재가입시에도 기존의 권한 복구는 불가능합니다.",
          "4. 단체보험계약의 경우, 보험기간 중도에 계약 해지 시 보험계약 중도해지 후 회원 탈퇴가 가능합니다.",
          "5. 이 때 회사에서는 위 제4호 탈퇴회원에게 남아있는 기간에 대한 보험료 환불을 진행할 수 있으며 환불 금액의 계산은 보험약관에서 정한 방법을 따릅니다. 환불 금액은 납입한 금액보다 적거나 없을 수 있습니다.",
          "③ 회사의 해지",
          "1. 회사는 다음과 같은 사유가 있는 경우 이용계약을 해지할 수 있습니다. 이 경우 회사는 회원에게 이메일, 전화, 기타의 방법을 통하여 구체적인 사유를 밝혀 관련 의사를 통지합니다. 이 경우 회사는 해당 회원에게 사전에 의견진술의 기회를 부여할 수 있습니다.",
          "가. 회원에게 본 약관 제6조에서 정하고 있는 이용신청의 승낙거부사유가 있음이 확인된 경우",
          "나. 회원이 회사나 다른 회원, 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우",
          "다. 회원이 본 약관에 위배되는 행위를 하거나 본 약관에서 정한 해지사유가 발생한 경우",
          "2. 이용계약은 회사의 해지의사가 회원에게 도달한 때에 종료되나, 회원이 제공한 연락처 정보의 오류로 인하여 회사가 해지의사를 통지할 수 없는 경우에는 회사가 웹 또는 앱에 해지의사를 공지하는 때에 종료됩니다",
          "3. 회원은 제휴서비스에 대하여 해지 요청을 할 수 있으며 회사가 마련한 탈퇴 절차에 따라 종료됩니다.",
          "4. 회사와 회원의 계약이 해지되는 경우 제공자가 제공하는 제휴서비스도 함께 해지되며 회원탈퇴로 인해 발생한 제휴서비스 이용 중단 등 불이익에 대한 책임은 회원 본인에게 있습니다.",
          "④ 이용계약의 종료와 관련하여 발생한 회원의 손해는 이용계약이 종료된 해당 회원이 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다.",
        ],
      },
      {
        section: "제9조 (리트러스트 쿠폰의 지급 및 사용 등)",
        subSections: [
          "① 쿠폰은 앱 또는 웹 서비스의 이용자가 회원인 경우에 지급됩니다.",
          "② 쿠폰의 유효기간은 이벤트에 따라 달라질 수 있습니다.",
          "③ 쿠폰은 현금으로 환급될 수 없으며 제3자와 유상으로 거래하거나 현금으로 전환할 수 없습니다.",
          "④ 쿠폰에 있어서 사용이 완료되거나 기간이 완료된 경우 복구가 불가능 합니다. 또한 회원 탈퇴시 미사용 쿠폰은 소멸되며, 회원 탈퇴 후 재가입하더라도 소멸된 쿠폰은 복구되지 않습니다.",
          "⑤ 회사의 전산장애 또는 기타사유로 비정상적으로 지급된 쿠폰에 대해서, 회사는 해당 쿠폰을 삭제하거나 원상 회복시키는 등 필요한 조치를 취할 수 있습니다.",
          "⑥ 쿠폰은 포인트와 합쳐서 중복 사용이 불가하며 쿠폰 또한 여러 개의 쿠폰으로 중복 사용이 불가합니다.",
        ],
      },
      {
        section: "제9조의2 (포인트 지급 및 사용 등)",
        subSections: [
          "① “포인트”란 플랫폼에서 서비스 이용 시 사용 할 수 있는 전자적 지급수단을 말합니다.",
          "1. 포인트는 이벤트 또는 회사의 서비스 정책, 마케팅 및 프로모션 등을 통하여 지급되는 포인트를 말합니다.",
          "2. 포인트는 회사가 별도로 명시한 경우를 제외하고 현금으로 환급될 수 없으며, 제3자와 유상으로 거래하거나 현금으로 전환할 수 없습니다.",
          "3. 회사가 승인하지 않은 방법으로 포인트를 획득/이용한 사실이 확인될 경우 포인트 소멸 혹은 회원자격을 정지 또는 해지할 수 있습니다.",
          "② 포인트는 회사의 정책에 따라 회원에게 부여되며, 포인트별 적립기준, 사용방법, 사용기한 및 제한에 관한 사항은 별도로 공지하거나 통지합니다.",
          "③ 포인트는 유효기간 내에서 사용할 수 있으며, 유효기간 경과 시 자동 소멸됩니다. 포인트의 유효기간은 지급일로부터 최대 1년(12개월) 입니다.",
          "예시) 2024년 2월 1일 적립된 포인트는 2025년 1월 31일까지 사용 가능합니다. 이벤트 또는 프로모션으로 지급된 포인트의 경우 기본 포인트 정책과 상이할 수 있으며 해당 이벤트 정책이 포인트 정책보다 우선 적용됩니다.",
          "④ 포인트는 유효기한 동안 사용되지 않거나 회원탈퇴 또는 자격상실 사유가 발생한 경우 자동소멸 됩니다. 이러한 사유로 소멸된 경우 재가입하더라도 소멸된 포인트는 복구되지 않습니다.",
          "⑤ 회사의 전산장애 또는 기타사유로 비정상적으로 지급된 포인트에 대해서, 회사는 해당 포인트를 삭제하거나 원상회복 등 필요한 조치를 취할 수 있습니다.",
          "⑥ 포인트 관련 회사의 정책은 회사의 영업 정책, 관계법령에 따라 변동될 수 있습니다. 별도의 고지없이 수시 변경될 수 있으나 회원에게 불리한 변경일 경우 회사 규정에 따라 공지 또는 통지하며 서비스 계속 이용 시 동의한 것으로 간주됩니다.",
          "⑦ 포인트와 쿠폰은 합쳐서 중복 사용이 불가합니다.",
        ],
      },
    ],
  },
  {
    title: "제3장 회원정보의 보호",
    sections: [
      {
        section: "제10조 (회원정보의 수집과 보호)",
        subSections: [
          "① 회사는 이용계약을 위하여 회원 및 이용신청자가 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원 및 이용신청자로부터 필요한 정보를 수집ㆍ이용할 수 있습니다. 이 경우 회사는 회원 및 이용신청자로부터 정보의 수집ㆍ이용ㆍ제공ㆍ조회에 대한 동의를 받습니다. 필수 수집항목이 아닌 개인정보의 수집ㆍ이용ㆍ제공ㆍ조회에 관한 회원 및 이용신청자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.",
          "② 회사가 정보의 수집ㆍ이용ㆍ제공ㆍ조회를 위하여 회원 및 이용신청자의 동의를 받는 경우, 회사는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공관련사항(제공받는 자, 제공목적, 제공정보의 내용)을 미리 명시하거나 고지합니다. 회원 및 이용신청자는 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.",
          "③ 회원 및 이용신청자는 회사에게 정보를 사실대로 제공하여야 합니다. 회사는 회원 및 이용신청자는 제공한 정보의 정확성을 확인하기 위하여 관련법령이 허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있고, 회원 및 이용신청자가 부당하게 증빙자료를 제공하지 않는 경우 회사는 서비스의 이용을 제한하거나 이용계약을 해지할 수 있습니다.",
          "④ 회사는 수집한 회원정보를 정보수집시에 밝힌 수집목적, 이용목적, 원활한 서비스의 제공 및 제공서비스의 확대 외의 용도로 사용하거나 제3자에게 제공하지 않습니다. 회사는 정보수집시에 밝힌 이용목적 이외에 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용ㆍ제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.",
          "⑤ 회사의 개인정보 관리책임자 및 연락처는 별도로 공개하는 “개인정보처리방침”에서 공지합니다.",
          "⑥ 회원 및 이용신청자는 언제든지 자신에 관한 정보를 열람할 수 있고, 회사 또는 정보관리책임자에게 잘못된 정보에 대한 정정을 요청할 수 있습니다. 회사는 회원 및 이용신청자로부터 정보정정의 요청이 있는 경우 그 오류를 정정하거나 정정하지 못하는 사유를 회원 및 이용신청자에게 통지하는 등 필요한 조치를 취할 때까지 당해 정보를 이용하지 않습니다. 다만, 다른 법률에 따라 개인정보의 제공을 요청 받은 경우에는 그러하지 아니 합니다.",
          "⑦ 회사 및 제휴 보험사는 보험의 계약과 관련하여 주민등록번호와 외국인등록번호 등의 고유식별번호를 사전 동의를 받은 후 처리합니다. 보험 모집을 주관하는 회사는 해당 보험상품을 공급하는 제휴 되는 보험사에 고유식별정보를 제공합니다.",
          "⑧ 회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가 분실, 도난, 유출, 변조되거나 위법하게 제3자에게 제공된 경우에는 그로 인한 회원의 손해에 대하여 책임을 부담합니다.",
          "⑨ 이용계약이 종료된 경우, 회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다.",
          "1.『상법』,『전자상거래 등에서의 소비자보호에 관한 법률』 등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관련법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.",
          "2. 회원이 회사에 대하여 미결제 서비스이용료가 있는 경우, 그 수금을 위하여 수금완료시까지 해당 회원의 이메일, 성명, 연락처, 주소 등 최소한의 필요정보를 보관합니다.",
          "3. 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.",
          "⑩ 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 회사는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유, 이용기간 등을 명시하여 회원의 동의를 받고, 개인정보의 수집ㆍ취급ㆍ관리 등을 위탁하는 경우에는 위탁업무의 내용 및 수탁사의 명칭 등에 관한 사항을 서면, 전자우편, 모사전송, 전화, 앱 또는 웹을 통해 미리 회원에게 고지하여 동의를 받거나 개인정보처리방침에 포함하여 공개합니다.",
          "⑪ 회사는 회원정보의 보호와 관리에 관한 “개인정보처리방침”을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 웹 또는 앱에 공개합니다.",
          "⑫ 회사는 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 화면(외부로 링크된 화면 등)에서는 회사의 개인정보처리방침이 적용되지 않습니다.",
        ],
      },
      {
        section: "제11조 (회원정보의 변경)",
        subSections: [
          "① 회원은 회원정보 관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위하여 실명, ID 등은 수정이 불가능합니다.",
          "② 회원은 이용 신청 시 기재한 사항이 변경되었을 경우, 즉시 온라인으로 수정해야 합니다.",
          "③ 다만 상기 제1항과 관련하여, 개명으로 인한 회원의 성명 변경시, 성명 변경은 본인인증절차를 거쳐 예외적으로 허용합니다.",
          "④ 회원 및 이해관계자는 회원정보 수정과 관련하여 회사가 자료를 요청하는 경우, 즉시 수정사항에 대한 증빙자료를 제공하여야 합니다.",
          "⑤ 회사의 회원에 대한 통지는 1) 회원정보에 기재된 휴대전화, 이메일 주소에 그 내용이 도착한 경우, 2) 회원의 과실로 회사가 앱 또는 웹에 공지하는 경우 각 도달한 것으로 봅니다. 회사는 회원이 변경사항을 알리지 아니하여 발생한 손해에 대하여 책임지지 않습니다.",
          "⑥ 회원이 변경된 회원정보를 수정하지 아니하거나 허위 내용으로 수정하여 회사에게 손해를 입힌 경우에 회원은 이에 대한 손해배상 책임을 부담합니다.",
        ],
      },
      {
        section: "제12조 (계정관리)",
        subSections: [
          "① 아이디(ID) 및 비밀번호에 대한 관리책임은 회원에게 있습니다. 서비스는 카카오톡의 Klip지갑과 연동하여 사용하거나 본인의 개인정보를 필요한 시점마다 제공하여 사용합니다. 개인의 계정정보를 타인에게 양도, 대여할 수 없습니다.",
          "② 회사의 귀책사유에 의하지 아니한 계정의 유출, 양도, 대여로 인한 손실이나 손해에 대하여 회사는 아무런 책임을 지지 않습니다.",
          "③ 회원이 계정을난당하거나 제3자가 이를 사용하고 있음을 인지한 경우, 즉시 회사에게 통보하여야 하고, 회사의 안내가 있는 경우에는 그에 따라야 합니다.",
        ],
      },
      {
        section: "제13조 (장기 미접속 계정에 대한 관리)",
        subSections: [
          "① 회원이 회원 가입 후 1년 이상 로그인을 하지 않은 경우 해당 회원의 아이디 및 계정은 휴면계정으로 전환되며 휴면회원이 됩니다.",
          "② 회사는 회원의 계정이 휴면계정으로 전환되기 30일 전 이메일 또는 휴대전화 메시지(SMS 및 앱푸시 등)을 통하여 휴면계정으로의 전환에 대하여 안내하고, 안내문에서 정한 기한 내에 서비스를 이용하지 않는 경우 회원의 계정을 휴면계정으로 전환시킬 수 있습니다.",
          "③ 회원계정이 휴면계정으로 전환되면 법령 및 약관에서 정한 최소한의 개인정보 및 서비스 이용정보 등은 보관되며, 추후 일반계정으로 변경하는 경우 보관되었던 개인정보 및 서비스 이용정보가 활용됩니다.",
          "④ 휴면계정으로 전환 후 회원의 로그인 없이 2년 이상 경과시, 휴면회원의 장기 미접속으로 인하여 회원 자격이 상실(탈퇴회원)될 수 있습니다.",
          "⑤ 본 약관 제13조제4항 및 제8조에 따라 회원 자격이 상실되면 회원의 개인정보 및 서비스 이용정보는 법률상 보관의무가 있는 항목을 제외하고 지체없이 삭제됩니다. 또한, 포인트, 쿠폰 등도 모두 소멸됩니다.",
        ],
      },
    ],
  },
  {
    title: "제4장 서비스의 이용",
    sections: [
      {
        section: "제14조 (서비스의 제공)",
        subSections: [
          "① 회사는 다음과 같은 서비스를 제공합니다.",
          "1. 보험상품에 대한 질의응답",
          "2. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결",
          "3. 구매계약이 체결된 재화 또는 용역의 배송",
          "4. 기타 회사가 정하는 업무",
          "5. 다른 회사와의 제휴 계약 등을 통해 회원에게 제공되는 일체의 서비스",
          "6. 단체보험의 보험상품정보 제공",
          "7. 보험료 계산",
          "8. 보험 가입홍보",
          "9. 보험금 온라인 청구서비스",
          "10. 보험 계약의 유지에 필요한 대행 서비스 제공(보험료 수납 관련 업무 등)",
          "11. 단체보험 계약자로서 회사의 기타 관련업무 수행",
          "12. 인슈어트러스트 플랫폼에 회원가입하고 이용하는 고객 대상 쿠폰 제공 및 포인트 적립 서비스",
          "13. 기타 수탁 업무 수행",
          "② 회사는 회원 및 이용신청자의 회원 가입을 승낙한 때부터 서비스를 개시합니다.",
          "③ 회사가 제공하는 보험 계약 내용에 변경이 발생하는 경우 문서에 의하여 계약의 내용을 변경할 수 있습니다. 변경되는 내용에 대해서는 서비스 정보를 제공하는 페이지에 변경되는 내용을 상세하게 공지합니다.",
          "④ 전항과 같이 변경이 발생하여 회원에게 손해가 발생할 경우 회사는 이에 대한 손해를 배상합니다. 다만 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",
        ],
      },
      {
        section: "제15조 (서비스 제공 시간)",
        subSections: [
          "① 서비스의 제공은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.",
          "② 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 제공 가능한 시간을 별도로 정할 수 있으며, 이 경우 그 내용을 사전에 공지합니다.",
          "③ 다만 제휴서비스 등은 각 서비스 별로 제공자의 운영정책에 따라 제공됩니다.",
        ],
      },
      {
        section: "제16조 (서비스 이용의 일시 중지 또는 중단)",
        subSections: [
          "① 회사는 다음 각 호의 사유 또는 업무상 및 기술상의 문제가 발생하는 경우 그 해결을 위하여 필요한 기간 동안, 서비스의 운영 목적상으로 필요한 경우에는 회사가 정한 기간 동안 서비스의 전부 또는 일부를 일시 중지 또는 중단할 수 있습니다.",
          "1. 서비스용 설비의 보수 등으로 인한 부득이한 경우",
          "2. 회원 및 이용신청자가 회사의 영업활동을 방해하는 경우",
          "3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 제공에 지장이 있는 경우",
          "4. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우",
          "② 제1항에 의한 서비스의 일시 중지 또는 중단의 경우 회사는 회원 및 이용신청자, 제휴사에게 사전 공지합니다. 다만, 회사가 통제할 수 없는 사유로 인하여 사전 공지가 불가능한 경우에는 사후에 공지합니다.",
        ],
      },
      {
        section: "제17조 (제휴서비스 등 이용)",
        subSections: [
          "① 회원은 자신이 이용하고자 하는 제휴서비스를 자율적으로 선택하여 이용합니다. 다만, 제공자는 관련 법령에 따라 의무화(본인인증서비스 등)되어 있는 서비스의 경우 회원에게 해당 내용을 안내하고 서비스의 이용을 권고할 수 있습니다.",
          "② 회사는 회원과 제휴사 간 체결한 제휴서비스에 대하여 회사의 과실 없이 발생한 문제에 대하여 책임을 부담하지 아니합니다.",
          "③ 제휴서비스의 해지 및 환불 등은 제휴사의 정책 등에 따라 처리되며 제공자는 제휴서비스에 대한 안내 및 환불 조건 등을 각 제휴서비스 페이지에 안내합니다.",
          "④ 회사는 제휴서비스의 변경 등이 발생한 경우 그 변경 내용 및 적용 시점을 본 약관 제3조에 따라 통지합니다.",
          "⑤ 회사는 제공자와 제휴서비스에 관한 계약이 해지, 종료되는 경우 해지 사실 및 해지 시점을 본 약관 제8조에 따라 통지함으로써 해당 제휴서비스의 제공을 중단할 수 있습니다.",
          "⑥ 제공자는 제휴서비스를 이용하는 회원에 대한 고객지원서비스(이용 문의, 불만사항 처리, 장애 처리 등)을 담당합니다.",
        ],
      },
      {
        section: "제18조 (정보의 제공 및 광고의 게재)",
        subSections: [
          "① 회사는 회원 및 이용신청자에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보를 회원 및 이용신청자가 마케팅 수신동의한 경우 앱 알림, 이메일, 카카오알림톡, SMS(Short Message Service), MMS(Multimedia Message Service) 또는 이에 준하는 전자적 의사표시 방법으로 제공할 수 있습니다.",
          "② 회사는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지 등에 게재할 수 있으며 회원 및 이용신청자는 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주합니다.",
        ],
      },
    ],
  },
  {
    title: "제5장 회사와 회원의 권리 의무",
    sections: [
      {
        section: "제19조 (회사의 권리 의무)",
        subSections: [
          "① 회사는 서비스 제공과 관련하여 알고 있는 고객의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 방송통신심의위원회의 요청이 있는 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.",
          "② 제1항의 범위 내에서, 회사는 업무와 관련하여 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 서비스 제공과 관련된 업무에 이를 사용할 수 있고, 이를 위하여 고객의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 고객은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해 서비스 이용이 변경되는 것은 고객의 책임입니다.",
          "③ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 회원의 이메일, 휴대폰번호로 SMS 전송 또는 이에 준하는 전자적 의사표시 등을 통하여 동 고객에게 통지합니다.",
          "④ 회사가 제공하는 서비스로 인하여 고객에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 중과실에 기해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상 손해에 한합니다.",
          "⑤ 회사는 『정보통신망 이용촉진 및 정보보호에 관한 법률』, 『통신비밀보호법』, 『전기통신사업법』 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.",
          "⑥ 회사는 본 약관이 정하는 바에 따라 서비스를 제공하기 위해 최선을 다합니다.",
          "⑦ 회사는 서비스 관련 정보의 제공을 위하여 회원의 이메일, 휴대폰번호로 SMS를 전송 또는 이에 준하는 전자적 의사표시를 보낼 수 있습니다.",
          "⑧ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게시하거나 SMS, 이메일 등을 통하여 동 회원에게 통지합니다.",
        ],
      },
      {
        section: "제20조 (회원의 권리 의무)",
        subSections: [
          "① 회원은 본 약관 및 개별약관, 특별약관 등이 정하는 바에 따라 서비스를 이용하며, 유료 서비스 이용 시 부과된 요금을 납부할 의무가 있습니다.",
          "② 회원은 서비스 이용 시 다음 각 호의 행위를 하여서는 안됩니다.",
          "1. 회원가입 신청 또는 등록정보 변경 시 허위 사실을 기재하거나, 부정하게 사용하는 행위",
          "2. 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위",
          "3. 회사 또는 제3자의 저작권 및 기타 권리를 침해하는 행위",
          "4. 법령, 공공질서 또는 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위",
          "5. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 또는 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위",
          "6. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 또는 수신자의 명시적인 수신 거부의사에 반하여 광고성 정보를 전송하는 행위",
          "7. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위",
          "8. 다른 회원의 개인정보를 동의 없이 수집, 저장, 공개하는 행위",
          "9. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위",
          "10. 윤락 행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위",
          "11. 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위",
          "12. 회사 또는 타인이 서비스에 게시한 정보를 변경하는 행위",
          "13. 스토킹(stalking) 등 다른 회원을 괴롭히는 행위",
          "14. 기타 불법적이거나 부당한 행위",
          "③ 회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 유료서비스를 판매하거나 기타 영리 활동을 할 수 없으며 해킹, 광고, 음란사이트 홍보 또는 이를 통한 영리행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 모든 결과에 대하여 회사는 책임지지 않으며 회원은 이와 같은 행위로 인하여 회사에 손해가 발생할 경우 배상하여야 합니다. 또한 회사는 위와 같은 사실을 발견한 경우 그와 같은 사실을 행정기관에 신고하거나 수사기관에 고발하는 등 법적 조치를 취할 수 있습니다.",
          "④ 회원은 관련법령, 본 약관의 규정 및 회사가 통지하거나 서비스 상에 공지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.",
        ],
      },
      {
        section: "제21조 (저작권 등의 귀속)",
        subSections: [
          "① 서비스 내 회사가 제작한 콘텐츠에 대한 저작권 및 기타 지적재산권은 회사의 소유입니다.",
          "② 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.",
          "③ 회원은 서비스 내에서 보여지거나 서비스와 관련하여 자신 또는 다른 회원이 앱 또는 홈페이지에 업로드 또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및 정보(이하 '이용자 콘텐츠'라 한다)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.",
          "1. 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)",
          "2. 이용자 콘텐츠를 제작한 회원의 사전 동의 없이 거래를 목적으로 회원 콘텐츠를 판매, 대여, 양도 행위를 하지 않음",
          "④ 제3항은 회사가 서비스를 운영하는 동안 유효하며 회원탈퇴 후에도 지속적으로 적용됩니다.",
        ],
      },
    ],
  },
  {
    title: "제6장 손해배상, 면책조항 등",
    sections: [
      {
        section: "제22조 (손해배상)",
        subSections: [
          "① 회사 또는 회사의 피고용인, 대리인, 기타 도급 및 위임 등으로 회사를 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 이용계약의 이행과 관련하여 회원에게 손해가 발생한 경우, 회사는 발생한 손해를 배상할 책임이 있습니다.",
          "② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책하여야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.",
          "③ 손해배상은 아래 기준으로 무료기간 연장 또는 무료쿠폰 지급 등을 통해 회원에게 배상합니다. 배상 방법에 대해서는 이메일, SMS 또는 이에 준하는 전자적 의사표시 등 회사에서 정하는 방법으로 합니다",
        ],
      },
      {
        section: "제23조 (회사의 면책)",
        subSections: [
          "① 회사는 국가의 비상사태, 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임을 지지 않습니다.",
          "② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.",
          "③ 회사는 회원이 다른 회원이 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은 손해에 대하여 책임을 지지 않습니다.",
          "④ 회사는 서비스를 통해 제공된 어떠한 문서나 상담의 내용에 대해서도 책임을 지지 않으며 그 밖에 서비스를 이용함으로써 기대되는 수익을 상실한 것에 대하여 책임지지 않습니다. 그러므로 회원이 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 할 때에는 반드시 사전에 전문가와 상의하여야 합니다.",
          "⑤ 회사가 제공하는 서비스에 포함된 모든 정보, 소프트웨어 등의 부정확함이나 오류가 발생할 수 있으며 회사가 제공하는 서비스를 통해 제공되는 정보의 정확성에 대하여 책임을 지지 않습니다.",
          "⑥ 회사는 서비스화면에 표시되는 제휴사 또는 제3자가 제공하는 상품 또는 정보 등의 정확성, 적시성, 타당성을 보증하지 않습니다.",
          "⑦ 회사는 고객이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스로부터 수반되는 잠재가치 및 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대하여 책임을 지지 않습니다.",
          "⑧ 회사는 고객이 사이트에 게재한 게시물의 신뢰도 및 정확성 등 내용에 대해 책임을 지지 않으며, 서비스에 게재된 어떠한 의견이나 정보에 대해 확인이나 대표할 의무가 없습니다. 아울러 회사는 회원이나 제3자에 의해 표출된 의견을 승인, 반대 또는 수정하지 않습니다",
        ],
      },
      {
        section: "제24조 (재판권 및 준거법)",
        subSections: [
          "① 서비스 이용과 관련하여 회사와 고객(제휴사 포함) 사이에 분쟁이 발생한 경우, 회사와 고객은 분쟁의 해결을 위해 성실히 협의합니다.",
          "② 회사와 회원간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 그 관할법원으로 합니다.",
          "③ 본 약관은 대한민국 법률에 따라 규율되고 해석됩니다.",
        ],
      },
      {
        section: "[부칙]",
        subSections: ["① (시행일) 본 약관은 2024년 7월 2일부터 시행합니다."],
      },
    ],
  },
];

export default serviceData;

//  {
//     title: "제4장 서비스의 이용",
//     sections: [
//     ],
//   },
