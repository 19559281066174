

const AccordionData = [
  {
    title: "통원 치료 시 서류",
    commonDocs: {
      title: "[공통]",
      docs: [
        "진료비 계산 영수증 (일자별 병원 및 약국 영수증, 처방전)",
        "진료비 세부내역서(비급여진료비 없는 경우 생략가능)",
      ],
    },
    additionalDocs: {
      title: "[추가서류]",
      amountRange: "청구금액 3만원 이상 10만원 이하 시",
      docs: [
        "진료비 계산 영수증(일자별 병원 및 약국 영수증, 처방전)",
        "진단명(질병분류코드) 및 통원일(기간)이 포함된 서류 예) 진단서, 통원확인서, 처방전 등",
      ],
    },
  },
  {
    title: "입원 치료 시 서류",
    docs: [
      "진료비 또는 입퇴원확인서(병명기재)",
      "진료비 계산 영수증",
      "진료비 세부 내역서",
    ],
  },
  {
    title: "유의사항",
    subtitle: "필요서류 안내",
    notice: [
      "의료비 외 서류는 필요서류 안내를 눌러 참고해 주세요.",
      "카드결제 영수증은 증빙서류가 아님(보상불가)",
      "사고내용, 상품에 따라 추가 서류를 요청할 수 있습니다.",
      "동일 질병으로 인하여 계속 치료받으신 경우, 청구서류(병원영수증)는 한 번에 첨부하시기 바랍니다.",
      "동일 사고(질병/상해)의 경우, 치료병원이 다르더라도 치료기간 내 모든 영수증을 등록하시기 바랍니다.",
      "본인 서류가 아닐 경우, 보상처리 되지 않습니다.",
      "첨부파일은 PDF 및 이미지(JPG, PNG, GIF 등 가능 TIF 불가) 파일만 업로드 가능하며, 파일명에 특수문자는 불가합니다.",
      "첨부파일 용량은 20MB 이하로 제한되며, 30개까지 업로드 가능합니다.",
    ],
  },
];

export default AccordionData;
