import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setGender,
  setDateOfBirth,
  addCompanion,
  updateCompanionGender,
  updateCompanionDateOfBirth,
  deleteCompanion,
} from "../../redux/store";
import styles from "../../css/calculationProcessCSS/Insert.module.css";

const gndrCd = {
  남자: "1",
  여자: "2",
};

export const getGenderString = (genderCode) => {
  if (!genderCode) {
    return "성별선택";
  }
  return genderCode === "1" ? "남" : "여";
};

const Gender = ({ faRetrustData, reOrderData }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCompanionDropdownOpen, setIsCompanionDropdownOpen] = useState({});
  const [selectedCompanionGender, setSelectedCompanionGender] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const userInfo = useSelector((state) => state.user);
  const companions = useSelector((state) => state.companions);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const reOrderUserData = reOrderData?.user;

  useEffect(() => {
    if (faRetrustData) {
      if (faRetrustData.gender === "male") {
        dispatch(setGender("1"));
      } else if (faRetrustData.gender === "female") {
        dispatch(setGender("2"));
      }

      if (faRetrustData.birthDate) {
        dispatch(setDateOfBirth(faRetrustData.birthDate));
      }
    }
  }, [faRetrustData, dispatch]);

  useEffect(() => {
    if (reOrderData && reOrderUserData) {
      if (reOrderUserData.gender === 1 || reOrderUserData.gender === 2) {
        dispatch(setGender(reOrderUserData.gender.toString()));
      }

      if (reOrderUserData.birth) {
        dispatch(setDateOfBirth(reOrderUserData.birth));
      }
    }
  }, [reOrderData, reOrderUserData, dispatch]);

  const addNewCompanion = () => {
    dispatch(addCompanion({ gender: "", dateOfBirth: "" }));
    setIsCompanionDropdownOpen({
      ...isCompanionDropdownOpen,
      [companions.length]: false,
    });
    setSelectedCompanionGender({
      ...selectedCompanionGender,
      [companions.length]: null,
    });
    setErrorMessages({ ...errorMessages, [companions.length]: "" });
  };

  const handleGenderSelect = (gender) => {
    if (!faRetrustData?.gender && !reOrderUserData?.gender) {
      dispatch(setGender(gndrCd[gender]));
    }
    setIsDropdownOpen(false);
  };

  const handleCompanionGenderSelect = (gender, index) => {
    dispatch(updateCompanionGender({ index: index, gender: gndrCd[gender] }));
    setIsCompanionDropdownOpen({ ...isCompanionDropdownOpen, [index]: false });
    setSelectedCompanionGender({ ...selectedCompanionGender, [index]: null });
  };

  const handleDateOfBirthChange = (e) => {
    if (!faRetrustData?.birthDate && !reOrderUserData?.birth) {
      const value = e.target.value;
      const formattedValue = value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{4})(\d{2})(\d{2})$/, "$1$2$3");
      dispatch(setDateOfBirth(formattedValue));
      if (formattedValue.length === 8 && !validateDateOfBirth(formattedValue)) {
        setErrorMessages({
          ...errorMessages,
          dateOfBirth: "가입자의 생년월일을 확인해주세요.",
        });
      } else {
        setErrorMessages({ ...errorMessages, dateOfBirth: "" });
      }
    }
  };

  const handleCompanionDateOfBirthChange = (e, index) => {
    const value = e.target.value;
    const formattedValue = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{4})(\d{2})(\d{2})$/, "$1$2$3");
    dispatch(
      updateCompanionDateOfBirth({ index: index, dateOfBirth: formattedValue })
    );
    if (formattedValue.length === 8 && !validateDateOfBirth(formattedValue)) {
      setErrorMessages({
        ...errorMessages,
        [index]: "동반인의 생년월일을 확인해주세요.",
      });
    } else {
      setErrorMessages({ ...errorMessages, [index]: "" });
    }
  };

  const validateDateOfBirth = (dateString) => {
    const validFormat = /^\d{8}$/;
    if (!validFormat.test(dateString)) return false;

    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);

    const isValidDate = (year, month, day) => {
      const date = new Date(year, month - 1, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
      );
    };

    if (!isValidDate(year, month, day)) {
      return false;
    }

    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 0 || age > 79) {
      return false;
    }

    return true;
  };

  const handleDeleteCompanion = (index) => {
    dispatch(deleteCompanion(index));
  };

  return (
    <>
      <div className="flex flex-col">
        <div className={styles.genderContentsBox}>
          <div className={styles.UserInfoBox}>
            <p className={styles.userInfo}>가입자 정보</p>
            <p className={styles.companionsTotal}>
              동반인 :{companions.length}명
            </p>
          </div>
          <div className={styles.userInpoInputFlex}>
            <input
              value={faRetrustData?.birthDate || userInfo.dateOfBirth}
              onChange={handleDateOfBirthChange}
              maxLength={8}
              type="tel"
              placeholder="예시 : 19910211"
              className={styles.InputContent}
              readOnly={!!(faRetrustData?.birthDate || reOrderUserData?.birth)}
            />
            <div className={styles.genderInput} ref={dropdownRef}>
              <input
                value={getGenderString(userInfo.gender)}
                readOnly
                placeholder="성별"
                className={styles.InputContent}
                style={
                  !userInfo.gender
                    ? { color: "#B8B9BC", fontWeight: "400" }
                    : {}
                }
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
              <div
                className={styles.faCheckStyle}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  transform="rotate(180)"
                  transform-origin="center center"
                >
                  <path d="M18 14L12 8L6 14" stroke="#96989C" />
                </svg>
              </div>
              {isDropdownOpen &&
                !faRetrustData?.gender &&
                !reOrderUserData?.gender && (
                  <>
                    <div className={styles.GenderOption}>
                      <button
                        onClick={() => handleGenderSelect("남자")}
                        className={styles.genderBtn}
                        role="menuitem"
                      >
                        남
                      </button>
                      <button
                        onClick={() => handleGenderSelect("여자")}
                        className={styles.genderBtn}
                        role="menuitem"
                      >
                        여
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        <div className="mb-2 text-[12px] text-[#E86565] font-normal">
          {errorMessages.dateOfBirth}
        </div>
        {companions.map((companion, index) => (
          <div key={index} className="">
            <div className={styles.companionIndex_DeleteBtn}>
              <p className={styles.companionIndex}>동반인{[index + 1]}</p>
              <span
                className={styles.DeleteBtn}
                onClick={() => handleDeleteCompanion(index)}
              >
                삭제
              </span>
            </div>
            <div className={styles.InputFlex}>
              <input
                value={companion.dateOfBirth}
                onChange={(e) => handleCompanionDateOfBirthChange(e, index)}
                maxLength={8}
                type="tel"
                placeholder="예시 : 19910211"
                className={styles.InputContent}
              />
              <div className={styles.genderInput} ref={dropdownRef}>
                <input
                  value={getGenderString(companion.gender)}
                  readOnly
                  className={styles.InputContent}
                  onClick={() => {
                    const updatedIsCompanionDropdownOpen = {
                      ...isCompanionDropdownOpen,
                    };
                    updatedIsCompanionDropdownOpen[index] =
                      !isCompanionDropdownOpen[index];
                    setIsCompanionDropdownOpen(updatedIsCompanionDropdownOpen);
                    setIsDropdownOpen(false);
                  }}
                />
                <div
                  className={styles.companionCheckicon}
                  onClick={() => {
                    const updatedIsCompanionDropdownOpen = {
                      ...isCompanionDropdownOpen,
                    };
                    updatedIsCompanionDropdownOpen[index] =
                      !isCompanionDropdownOpen[index];
                    setIsCompanionDropdownOpen(updatedIsCompanionDropdownOpen);
                    setIsDropdownOpen(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    transform="rotate(180)"
                    transform-origin="center center"
                  >
                    <path d="M18 14L12 8L6 14" stroke="#96989C" />
                  </svg>
                </div>
                {isCompanionDropdownOpen[index] && (
                  <div className={styles.GenderOption}>
                    <button
                      onClick={() => handleCompanionGenderSelect("남자", index)}
                      className={styles.genderBtn}
                      role="menuitem"
                    >
                      남
                    </button>
                    <button
                      onClick={() => handleCompanionGenderSelect("여자", index)}
                      className={styles.genderBtn}
                      role="menuitem"
                    >
                      여
                    </button>
                  </div>
                )}
              </div>
            </div>
            <p className="mt-2 mb-3 text-[#E86565] text-[12px]">
              {errorMessages[index]}
            </p>
          </div>
        ))}
        <div className={styles.AddcopanionButtonWrap} onClick={addNewCompanion}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <rect x="5.25" width="1.5" height="12" rx="0.75" fill="#386937" />
            <rect
              y="6.75"
              width="1.5"
              height="12"
              rx="0.75"
              transform="rotate(-90 0 6.75)"
              fill="#386937"
            />
          </svg>
          <button className={styles.AddcopanionButton_Text}>
            동반인 추가하기
          </button>
        </div>
      </div>
    </>
  );
};

export default Gender;
