import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "../../css/signUpProcessCSS/Finsh.module.css";
import { FinishSVG } from "../../components/common/SvgIcon/RestFinishSVG";
import ModalFinish from "../../components/modal/ModalFinish";
import FinishCheck from "../../assets/FinishCheck.svg";
import QRCodeModal from '../../components/modal/QRCodeModal';

function Finish() {
  const { tid } = useParams(); // useParams 훅을 사용하여 insurance_id를 가져옴
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { natlCd, nation, city, start_date, end_date } =
    Object.fromEntries(searchParams);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("NFT 생성중");
  const [isNFTReceived, setIsNFTReceived] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // 새로 추가된 부분
  const [cardId, setCardId] = useState(""); // mint 데이터를 저장할 상태 추가
  const [insuranceId, setInsuranceId] = useState(""); // mint 데이터를 저장할 상태 추가
  const [showQRModal, setShowQRModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const cardData = JSON.stringify({
    natlCd: natlCd,
    nation: nation,
    city: city,
    start_date: start_date,
    end_date: end_date,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/escrow-api/mint/${tid}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: cardData,
        });

        const cardInfo = await response.json();
        console.log(cardInfo);
        if (cardInfo.success) {
          setCardId(cardInfo.cards[0].card_id_hex);
          setInsuranceId(cardInfo.cards[0].insurance);
          setIsLoading(false);
          setButtonText("NFT 받기");
          setIsNFTReceived(true);
        } else {
          //에러처리
          console.error("Error:", cardInfo);
        }
      } catch (error) {
        //에러처리
        console.error("Error:", error);
      }
    };

    fetchData();
    //모바일 체크
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleNFTReceive = async () => {
    try {
      const response = await fetch("/auth/klip", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (response.ok) {
        const { request_id, deep_link } = result.data;
        if (isMobile) {
          window.location.href = deep_link;
        } else {
          setQrCodeData(deep_link);
          setShowQRModal(true);
        }

        // 이후 request_id를 사용하여 주소를 가져옴
        const addressResponse = await fetch(
          `/auth/klip_result?requestId=${request_id}`,
          {
            method: "GET",
          }
        );

        const addressResult = await addressResponse.json();

        if (addressResponse.ok) {
          // NFT 전송
          const sendNFTResponse = await fetch(
            `/escrow-api/sendNFT/${insuranceId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                card_id: cardId,
                address: addressResult.klaytn_address,
              }),
            }
          );

          if (sendNFTResponse.ok) {
            alert("NFT 전송 성공");

            const resetReduxAndSessionStorage = () => {
              sessionStorage.removeItem("reduxState");
            };
            resetReduxAndSessionStorage();
          } else {
            alert("NFT 전송 실패:", await sendNFTResponse.text());
          }
        }
      }
    } catch (error) {
      alert("Error:", error);
    }

    openModal();
  };

  return (
    <>
      <section className={styles.FinishWrap}>
        <div className={styles.FinishIcon}>
          <FinishSVG />
        </div>
        <div>
          <h3 className={styles.h3_Finish}>“보험가입이 완료 되었습니다.”</h3>
          <p className={styles.p_Finish}>
            가입 확인서는 이메일로 발송되었습니다.
          </p>
        </div>
        <div className="w-full px-[20px] ">
          <div
            className="flex w-[100%] gap-[8px] justify-center items-center rounded-[12px]"
            style={{ backgroundImage: `url('/images/Btn.png')` }}
          >
            {!isLoading && (
              <div className="">
                <img src={FinishCheck} alt="" />
              </div>
            )}
            <button
              className={styles.button}
              disabled={isLoading}
              onClick={isLoading ? null : handleNFTReceive}
            >
              {/* 로딩 애니메이션과 텍스트를 함께 표시 */}
              <div className={styles.buttonContent}>
                {[...buttonText].map((char, index) => (
                  <span key={index} className={styles.buttontext}>
                    {char}
                  </span>
                ))}
                {isLoading && (
                  <div className={`${styles.loading} ${styles.dotAnimation}`}>
                    <span className="text-sm text-white">.</span>
                    <span className="text-sm text-white">.</span>
                    <span className="text-sm text-white">.</span>
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
        <p className={styles.FinishText}>
          잠시만 기다려주세요 <br /> NFT발급에 5초~ 1분까지 걸릴 수 있어요!
        </p>
      </section>
      <div>
        {/* 기다리는 동안 알아보기 버튼 */}
        <p className={styles.bottomText} onClick={openModal}>
          기다리는 동안 알아보기
        </p>
      </div>
      {/* 모달 */}
      {showModal && (
        <ModalFinish
          isOpen={showModal}
          onClose={closeModal}
          isNFTReceived={isNFTReceived}
        />
      )}
      {showQRModal && (
        <QRCodeModal
          onClose={() => setShowQRModal(false)}
          qrCodeData={qrCodeData}
        />
      )}
    </>
  );
}

export default Finish;
