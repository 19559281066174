import React from "react";
import styles from "../../css/Claim/claimIntro.module.css";

function CallBtn({url}) {
  const handleCall = () => {
    window.location.href = url;
  };
  return (
    <>
      <div className={styles.ClipLoginBtn} onClick={handleCall}>
        <p>전화 걸기</p>
      </div>
    </>
  );
}

export default CallBtn;
