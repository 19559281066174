// ClaimExtendSelectDate.js
import React, { useState } from "react";
import ClaimHeader from "../../components/claimComponents/ClaimHeader";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../css/Claim/claimExtendSelectDate.module.css";
import ExtendCalendar from "./ExtendCalendar";
import dayjs from "dayjs";
import Button from "../../components/common/Button";

function ClaimExtendSelectDate() {
  const location = useLocation();
  const filteredData = location.state || {};
  const navigate = useNavigate();

  const insuranceStartDate = filteredData?.insurance_start_date
    ? dayjs(filteredData?.insurance_start_date)
    : null;

  const insuranceEndDate = filteredData?.insurance_end_date
    ? dayjs(filteredData?.insurance_end_date)
    : null;

  const [modifyDate, setModifyDate] = useState("");
  const startDate = insuranceStartDate
    ? insuranceStartDate.format("YYYY-MM-DD")
    : null;

  const handleSelectDate = (date) => {
    const selectedDate = dayjs(date);
    setModifyDate(selectedDate.format("YYYY-MM-DD"));
  };

  const navigation = () => {
    navigate("/claimExtendCostChk", {
      state: { modifyDate, startDate, filteredData },
    });
  };

  return (
    <>
      <ClaimHeader titleText="여행기간 도착일 연장" />
      <div className={styles.container}>
        <div className={styles.containerWrap}>
          <div className={styles.contents}>
            <h3>
              변경하실 도착일 정보를
              <br /> 입력해주세요.
            </h3>
            <div className={styles.inputWrap}>
              <p>출발일</p>
              <ExtendCalendar
                initialDate={
                  insuranceStartDate
                    ? insuranceStartDate.format("YYYY-MM-DD")
                    : null
                }
                disabled={true}
              />
            </div>
            <div className={styles.inputWrap}>
              <p>도착일</p>
              <ExtendCalendar
                insuranceStartDate={insuranceStartDate}
                insuranceEndDate={insuranceEndDate}
                onSelect={handleSelectDate}
              />
            </div>
          </div>
          <div className={styles.textbox}>
            <p>당일날은 도착일 연장 서비스가 불가능합니다.</p>
          </div>
        </div>
        <Button onClick={navigation} disabled={!modifyDate} />
      </div>
    </>
  );
}

export default ClaimExtendSelectDate;
