import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../css/Progressbar/Progressbar.module.css";

function SignProgress() {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  // 페이지 이동 시 섹션의 완료 상태를 업데이트
  useEffect(() => {
    const pathname = location.pathname;

    if (
      pathname === "/signup/member" ||
      pathname === "/signup/companionmembers"
    ) {
      setActiveStep(2);
    } else if (pathname === "/signup/guarantee") {
      setActiveStep(3);
    } else if (pathname.startsWith("/signup/finish/")) {
      setActiveStep(4);
    } else {
      setActiveStep(1); // 기본적으로 첫 번째 섹션은 항상 활성화된 상태
    }
  }, [location.pathname]);

  const stepTitles = ["가입자 정보", "청약내용", "결제", "가입완료"];

  return (
    <>
      <div className={styles.ProgressbarWrap2}>
        <div className={styles.flexCenter}>
          {stepTitles.map((title, index) => (
            <div key={index} className={styles.flexColumnCenter}>
              {activeStep >= index + 1 ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_20_6916)">
                      <circle cx="10" cy="10" r="10" fill="#C1D1C1" />
                      <circle cx="10" cy="10" r="5" fill="#386937" />
                    </g>
                    <defs>
                      <clipPath id="clip0_20_6916">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_20_6917)">
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                      stroke="#B8B9BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_20_6917">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <p
                className={`${styles.stepText} ${
                  activeStep >= index + 1
                    ? styles.stepText_active
                    : styles.stepText_inactive
                }`}
              >
                {title}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.Progressbarline}></div>
      </div>
    </>
  );
}

export default SignProgress;
