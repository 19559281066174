
import ConfirmAndGuarantee from "../../components/common/ConfrimAndGuarantee";
import GoPay from "../../components/niceComponents/GoPay"


function GuaranteeChk() {
  return (
    <>
      <ConfirmAndGuarantee />
      <GoPay  />
    </>
  );
}

export default GuaranteeChk;
