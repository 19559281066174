import React from "react";
import styles from "../../css/Claim/claimIntro.module.css";
import claimintroPlan from "../../assets/claimintroPlan.svg";
import ClaimFooter from "./ClaimFooter";
import ClaimIntroSlide from "../../components/claimComponents/ClaimIntroSlide";
import CallBtn from "../../components/claimComponents/CallBtn";

function EmergencySupport() {

  return (
    <>
      <div className="h-[100%] bg-white">
        <ClaimIntroSlide />
        {/* 이벤트 배너 */}
        <div className={styles.eventBannerWrap}>
          <div className={styles.eventBannerWraper}>
            {/* 두번째 배너 시작 */}
            <div className={styles.callBanner}>
              <div className={styles.secondBannerWrap}>
                <div className={styles.contentsFlex}>
                  <div className={styles.secondBanner_Text}>
                    <h3>
                      연중무휴 24시간
                      <br />
                      우리말 서비스
                    </h3>
                    <p>해외에서 필요한 긴급 의료상담 전용</p>
                  </div>
                  <img src={claimintroPlan} alt="claimintroPlan" />
                </div>
                <CallBtn url={"tel:+8223602407"}/>
              </div>
            </div>
          </div>
        </div>
        <ClaimFooter />
      </div>
    </>
  );
}

export default EmergencySupport;
