import React, { useState } from "react";
import CommonSecureKeyboard from "./CommonSecureKeyboard";
import Loading from "../modal/Loading";
import usePublicKey from "../../api/PublicGetApi";
import createPostData from "../../api/CreatePostData";
import ErrorModal from "../modal/ErrorModal"; // Adjust path as needed

const validateResidentNumber = (residentNumber) => {
  if (residentNumber.length !== 13) {
    return false;
  }
  const digits = residentNumber.slice(0, -1).split("").map(Number);
  const multipliers = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += digits[i] * multipliers[i];
  }
  const remainder = sum % 11;
  let checkDigit = 11 - remainder;
  if (checkDigit >= 10) {
    checkDigit -= 10;
  }
  return checkDigit === Number(residentNumber.slice(-1));
};

const SecureInputForm = ({
  initialValue = "",
  placeholder,
  error,
  onChange,
  onClick,
  maxLength,
  maskValue,
  selectedBirth,
}) => {
  const publicKey = usePublicKey();
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(initialValue);
  const [modalError, setModalError] = useState(null);

  const handleEncryption = async () => {
    if (loading) return;

    try {
      setLoading(true);
      if (!publicKey) {
        throw new Error("유효하지 않는 접근입니다.");
      }

      let encryptedData = "";

      if (maskValue === "secret") {
        // 주민등록번호 처리
        const birthDate = selectedBirth ? selectedBirth.slice(2) : ""; // 생년월일
        const combinedValue = birthDate + localValue;

        // 유효성 검증
        if (
          combinedValue.length !== 13 ||
          !validateResidentNumber(combinedValue)
        ) {
          throw new Error("유효하지 않은 주민등록번호입니다.");
        }

        // 암호화된 데이터 생성
        encryptedData = await createPostData(combinedValue, publicKey);
      } else if (maskValue === "accountSecret") {
        encryptedData = await createPostData(localValue, publicKey);
      } else {
        encryptedData = await createPostData(localValue, publicKey);
      }

      // 암호화된 데이터를 onClick 콜백 함수로 전달
      onClick(encryptedData);
    } catch (error) {
      setModalError(error.message || "암호화 실패"); // 에러 메시지 설정
      console.error("암호화 실패:", error); // 콘솔에 에러 로그 출력
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalError(null); // 모달 닫기
  };

  return (
    <div>
      <CommonSecureKeyboard
        value={localValue}
        onChange={(value) => {
          setLocalValue(value);
          onChange(value);
        }}
        placeholder={placeholder}
        maxLength={maxLength}
        onConfirm={handleEncryption}
        maskValue={maskValue}
      />
      {error && <p style={{ color: "#E86565" }}>{error}</p>}
      {loading && <Loading />}

      {/* ErrorModal */}
      {modalError && <ErrorModal message={modalError} onClose={closeModal} />}
    </div>
  );
};

export default SecureInputForm;
