import React, { useState } from "react";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../modal/ErrorModal";
import RevocationSuccess from "../modal/RevocationSuccess";

function ExtendBtn({ payData }) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const moid = payData?.moid;

  const onCloseModal = () => {
    setError(false);
    navigate("/claimExtendDate");
  };

  const onCloseSuccessModal = () => {
    setSuccess(false);
    navigate("/claimExtendDate");
  };

  const handleExtend = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      moid: moid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/trip-api/processExtend", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.isExtended) {
          setSuccess(true);
          setMessage("연장이 성공적으로 처리되었습니다.");
        } else {
          throw new Error("Extension failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(true);
        setMessage("연장 처리 중 오류가 발생했습니다.");
      });
  };

  return (
    <>
      {error && <ErrorModal message={message} onClose={onCloseModal} />}
      {success && (
        <RevocationSuccess message={message} onClose={onCloseSuccessModal} />
      )}
      <Button buttonText="연장하기" onClick={handleExtend} />
    </>
  );
}

export default ExtendBtn;
