import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Claim/mainPage.module.css";
import MainSlick from "../../components/claimComponents/MainSlick";
import claimMainRejoin from "../../assets/claimMainRejoin.svg";
import claimMainFriend from "../../assets/claimMainFriend.svg";
import claimMainContract_check from "../../assets/claimMainContract_check.svg";
import claimCheck from "../../assets/claimCheck.svg";
import claimRedeem from "../../assets/claimRedeem.svg";
import commonDownArrow from "../../assets/commonDownArrow.svg";
import ClaimFooter from "./ClaimFooter";
import insureTrustLogo from "../../assets/insureTrustLogo.svg";
import ClaimUtilsApi from "../../api/ClaimUtilsApi";

function MainPage() {
  const loggedIn = useSelector((state) => state.cookie.cookie);
  const birthSecondPart = useSelector((state) => state.claim.birthSecondPart);
  const navigate = useNavigate();
  const tokenData = useSelector((state) => state.insurance.insurances);
  const data = tokenData?.Insurances || [];
  const mrzUser = loggedIn?.mrzUser;
  ClaimUtilsApi();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = formatDate(new Date());

  // 데이터를 필터링하여 만료된 보험을 제외합니다.
  const filteredData = data.filter((item) => {
    const endDate = formatDate(new Date(item.Contract?.insurance_end_date));
    return endDate >= currentDate;
  });

  const insuranceClaimInfo = [
    "보험금 청구는 필요서류가 완비되었을 때 접수가 완료되며, 이후 최종심사 후에 보험금이 지급됩니다.",
    "보험금 청구 접수완료 시 담당자가 지정되며 ‘보험금 청구 현황’ 화면에서 확인할 수 있습니다.",
    "온라인 청구 : 청구 1건당) 200만원 이하 건만 가능",
    "200만원 초과 시 ‘원본 서류’ 제출이 필요하므로 우편/방문 접수를 이용하시기 바랍니다.",
    `보험금 청구는 각각 상해, 질병별로 접수하셔야 합니다.
      예)넘어짐, 감기 2가지 청구시 각각 접수 고혈압으로 10회 치료시 한번에 접수`,
  ];
  // link: "/maintenance" 준비중 페이지,
  const gridItems = [
    { text: "재가입", image: claimMainRejoin, link: "/trip" },
    {
      text: "NFT\n선물",
      image: claimMainFriend,
      link: "/claimReferral",
    },
    {
      text: "계약\n조회",
      image: claimMainContract_check,
      link: "/claimContractInfo",
    },
    { text: "청구\n하기", image: claimCheck, link: "/claimMembersIntro" }, //"claimMember"
    { text: "청구\n확인", image: claimCheck, link: "/claimConfirm" },
    { text: "도착일\n연장", image: claimRedeem, link: "/claimExtendDate" },
  ];

  // Function to toggle accordion menu state
  const [accordionStates, setAccordionStates] = useState({
    0: false,
    1: false,
  });

  const toggleAccordion = (index) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const validateToken = () => {
    return loggedIn && birthSecondPart;
  };

  const handleGridItemClick = (item) => {
    switch (item.link) {
      case "/claimMembersIntro":
      case "/claimConfirm":
      case "/claimReferral":
        handleValidTokenClick(item);
        break;
      case "/claimContractInfo":
        if (loggedIn) {
          navigate("/claimContractInfo");
        } else {
          navigate("/claimLogin");
        }
        break;
      default:
        navigate(item.link);
        break;
    }
  };

  const handleValidTokenClick = (item) => {
    if (validateToken()) {
      navigate(item.link, { state: { birthSecondPart } });
    } else if (!loggedIn) {
      navigate("/claimLogin", { state: { redirect: item.link } });
    } else if (mrzUser === true) {
      navigate(item.link);
    } else {
      navigate("/Combine", { state: { targetRoute: item.link } });
    }
  };

  const revocationNavi = () => {
    if (loggedIn) {
      navigate("/claimRevocation");
    } else {
      navigate("/claimLogin");
    }
  };

  return (
    <div className="h-auto bg-white overflow-hidden">
      <div className={styles.MainPageWrap}>
        {/* Header Section */}
        <div
          className={`${styles.MainHeader} ${
            filteredData && filteredData.length > 0 && loggedIn
              ? styles.loggedInHeader
              : ""
          }`}
        >
          <div className={styles.headerWrap}>
            <div className="flex flex-col px-[20px] py-[20px] gap-[18px]">
              <div className={styles.MyInfo}>
                <img src={insureTrustLogo} alt="insuTrust" />
              </div>
              <img
                src="/images/Claim/mainImage.png"
                alt="/images/Claim/mainImage.png"
              />
            </div>
            {loggedIn && <MainSlick />}
          </div>
        </div>
        <div
          className={`${styles.gridWrapper} ${
            filteredData && filteredData.length > 0 && loggedIn
              ? styles.loggedInGrid
              : ""
          }`}
        >
          {gridItems.map((item, index) => (
            <div
              key={index}
              className={styles.gridContainer}
              onClick={() => handleGridItemClick(item)}
            >
              <div className={styles.gridText}>
                {item.text.split("\n").map((line, idx) => (
                  <React.Fragment key={idx}>
                    {line}
                    {idx < item.text.split("\n").length - 1 && <br />}
                  </React.Fragment>
                ))}
              </div>
              <div></div>
              <div>
                <img src={item.image} alt="" />
              </div>
              <div></div>
            </div>
          ))}
        </div>
        <div className={styles.ModifyContents}>
          <div className={styles.ModifyButtonWrap}>
            <button className={styles.ModifyButton} onClick={revocationNavi}>
              개시 전<br />
              취소 · 청약 철회
            </button>
          </div>
        </div>

        {/* Insurance Claim Info Text Section */}
        <div className={styles.textBoxWrap}>
          <ul className={styles.textBoxFlexCol}>
            {insuranceClaimInfo.map((info, index) => (
              <li key={index} className={styles.TextList}>
                <span className={styles.TextListStyle}>·</span>
                <p>{info}</p>
              </li>
            ))}
          </ul>
        </div>
        {/* Accordion Section */}
        <div className={styles.Noticewrap}>
          <div className={styles.NoticeFelxCol}>
            {/* Accordion 1 */}
            <div className={styles.NoticeBgColor}>
              <div
                className={styles.NoticeFelxRowBox}
                onClick={() => toggleAccordion(0)}
              >
                <div className={styles.NoticeTextBox}>
                  <p>보험금 청구 절차</p>
                  <span>유의사항</span>
                </div>
                <img
                  src={commonDownArrow}
                  alt="commonDownArrow"
                  className={accordionStates[0] ? styles.Rotate : ""}
                />
              </div>
              <div className={accordionStates[0] ? styles.boundary : ""}></div>
              <div
                className={
                  accordionStates[0]
                    ? styles.NoticeContent.active
                    : styles.NoticeContent
                }
              >
                <ul className={styles.hiddenTextBox}>
                  <li>1. 사고/진료내역 입력</li>
                  <li>2. 필요 서류 전송</li>
                  <li>3. 보상 담당자 배정/심사 : 사고 심사 및 보험금 결정</li>
                  <li>4. 보험금 지급</li>
                </ul>
              </div>
            </div>

            {/* Accordion 2 */}
            <div className={styles.NoticeBgColor}>
              <div
                className={styles.NoticeFelxRowBox}
                onClick={() => toggleAccordion(1)}
              >
                <div className={styles.NoticeFexMethod}>
                  <p>팩스 및 우편 청구 방법</p>
                </div>
                <img
                  src={commonDownArrow}
                  alt="commonDownArrow"
                  className={accordionStates[1] ? styles.Rotate : ""}
                />
              </div>
              <div className={accordionStates[1] ? styles.boundary : ""}></div>
              <div
                className={
                  accordionStates[1]
                    ? styles.NoticeContent.active
                    : styles.NoticeContent
                }
              >
                <ul className={styles.hiddenTextBox_two}>
                  팩스
                  <li className="">
                    <span>・</span>
                    번호 : 0000-000-0000 / 0000 <br /> (피보험자 이름, 연락처
                    기재 필요)
                  </li>
                  <li className="">
                    <span>・</span>
                    팩스 수신 후 1
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClaimFooter />
    </div>
  );
}

export default MainPage;
