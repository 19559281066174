const ClaimAgreeData = [
  {
    title: "[필수] 개인(신용 정보 수집・이용에 관한 동의)",
    isActive: null,
    agreeItems: [
      { id: "uniqueId1", title: "고유식별정보", checked: null },
      { id: "uniqueId2", title: "민감정보", checked: null },
      { id: "uniqueId3", title: "개인(신용)정보", checked: null },
    ],
    description: [
      "귀하는 개인(신용)정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의는 철회할 수 있습니다. 정보동의시 계약의 체결 및 이행 목적달성에 부합하는 최소한의 정보만 수집·이용 및 제공하며, 본 동의는 ‘보험계약 인수심사·체결·이행·유지·관리’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행 및 정상적인 서비스 제공이 불가능합니다. 당사의 고의 또는 과실 등 귀책사유로 인한 개인정보 유출로 고객님에게 발생한 손해에 대해 법령 등에 따라 보상받으실 수 있습니다.",
      "주식회사 리트러스트(이하 “회사”)는 『개인정보보호법』 및 『신용정보의 이용 및 보호에 관한 법률』에 따라 귀하의 개인(신용) 정보를 다음과 같이 수집, 이용하고자 합니다.",
      "수집한 위 개인(신용) 정보는 1) 보험가입 설계, 2) 보험계약 체결을 위하여 보험회사로 제공됩니다.",
      "Ⅰ. 보험료 산출시",
      "1. 수집 ∙ 이용 목적",
      "회사를 통하여 단체보험계약을 체결함에 있어",
      "가) 보험계약 상담, 재무설계서비스, 보험계약 인수여부 판단(건강진단 및 계약 적부조사 포함)",
      "나) 실손의료보험계약·기타손해보험계약 등 ‘실제 발생하는 손해만을 보상하는 실손형 보험’의 중복가입 확인을 위한 보험가입내역 조회",
      "2. 보유 및 이용기간: 동의일로부터 1년까지",
      "3. 수집 ∙ 이용 항목",
      "1) 고유식별정보: 주민등록번호, 운전면허번호, 외국인등록번호, 여권번호",
      "2) 민감정보: 피보험자의 질병·상해에 관한 정보 (진료기록, 기왕증, 흡연여부, 상병명 등)",
      "3) 개인(신용)정보:",
      "A. 일반개인정보: 성명, 주소, 생년월일, 이메일, 유·무선 전화번호, 국적, 직업, 운전여부, 국내거소신고번호, 외국인체류자격·코드, 전문보험계약자(보험설계사·보험대리점·보험중개사 등)의 정보, 사업자등록증상의 정보, 법률 및 국제협약 등의 의무이행을 위한 정보, CI, 통신사, 음성정보",
      "B. 신용거래정보: 보험계약정보(상품종류, 기간, 보험가입금액 등), 보험금정보(보험금 지급사유, 지급금액, 사고정보 등), 계약알릴 의무사항(취미 등)",
      "C. 신용능력정보: 소득 및 재산 정보, 보험가입물건 정보(피보험차량정보 등)",
      "D. 공공정보: 실명확인증표 정보, 손해보험협회에서 수집 • 관리하는 전문보험계약자(보험설계사 • 보험대리점 • 보험중개사 등)의 정보",
      "Ⅱ. 보험료 결제시",
      "1. 수집·이용 목적",
      "회사를 통하여 단체보험계약을 체결함에 있어 보험사주)의",
      "가) 보험계약 인수심사·체결·이행·유지·관리(부활 및 갱신 포함)",
      "나) 보험금 등 지급·심사, 적부 및 조사(보험사기 조사포함)",
      "다) 순보험요율의 산출·검증",
      "라) 민원처리 및 분쟁 대응",
      "마) 가입한 보험계약 상담",
      "바) 금융거래 관련 업무(금융거래 신청, 자동이체 등)",
      "사) 보험모집질서 유지(공정경쟁질서 유지에 관한 협정업무 포함)",
      "아) 보험계약·보험금 청구에 이해관계가 있는 자에 대한 법규 및 계약상 의무이행에 활용",
      "주) 보험사는  귀하께서 가입하시는 보험상품과 관련된 보험사를 의미합니다.",
      "2. 보유 및 이용기간",
      "동의일로부터 거래종료주) 후 5년까지 (단, 보험금 지급, 금융사고 조사, 보험사기 방지·적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유·이용 및 별도보관)",
      "주) 거래종료일이란 “①보험계약 만기, 해지, 취소, 철회일 또는 소멸일, ②보험금 청구권 소멸시효 완성일(상법 제662호), ③ 채권·채무관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한 날”을 의미함",
      "3. 수집·이용 항목",
      "1) 고유식별정보: 주민등록번호, 운전면허번호, 외국인등록번호, 여권번호",
      "2) 민감정보: 피보험자의 질병·상해에 관한 정보(진료기록 상병명, 기왕증 등)",
      "3) 개인(신용)정보",
      "A. 일반개인정보: 성명, 주소, 생년월일, 이메일, 유·무선 전화번호, 국적, 직업, 국내거소신고번호, 외국인체류자격·코드, 전문보험계약자(보험설계사·보험대리점·보험중개사 등)의 정보, 사업자등록증상의 정보, 가족관계증명서류, 법률 및 국제협약 등의 의무이행을 위한 정보",
      "B. 신용거래정보신용능력정보: 금융거래업무 관련정보(납입 계좌정보, 보험료 및 보험금 출수납 등), 보험계약정보(상품종류, 기간, 보험가입금액 등), 보험금정보(보험금 지급사유, 지급금액, 사고정보, 본인 또는 본인의 위임을 받아 취득한 각종 조사서, 판결문, 증명서, 확인서 등), 계약전 알릴의무 사항(취미 등)",
      "C. 신용능력정보: 소득 및 재산정보, 보험가입물건 정보",
    ],
  },
  {
    title: "[필수] 단체보험서비스 이용 동의",
    isActive: null,
    agreeItems: [
      { id: "uniqueId4", title: "단체 서비스 동의", checked: null },
      { id: "uniqueId5", title: "NFT발행 동의", checked: null },
      { id: "uniqueId6", title: "개인정보관리 동의", checked: null },
    ],
    description: [
      "단체보험서비스 이용약관",
      "공고일:2023.05.10일",
      "기준일:2024.05.10일",
      "제 1조 (목적)",
      "1) 본 약관은 주식회사 리트러스트(이하 '회사'라 합니다)가 운영하는 웹사이트(insu.retrust.world) 와 모바일어플리케이션 '인슈어트러스트' (웹사이트와 모바일어플리케이션 모두를 합하여 이하 '플랫폼'이라 합니다)을 기반으로 '회사'를 단체보험 계약자로 하고 플랫폼 회원을 피보험자로 하여 제휴 보험사와 함께 제공하는 보험서비스를(이하 '서비스'라 합니다) 이용함에 있어 회원 및 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.",
      "2) '회사'는 '플랫폼'에 관한 제반기술과 운영에 대한 모든 권한을 갖고 있으며 플랫폼을 통하여 제공되는 서비스는 보험모집인(보험대리점)인 '회사' 주식회사 리트러스트와 하기 별지에 기술된 제휴보험사가 전담합니다.",
      "제2조 (용어의 정의)",
      "본 약관에서 사용되는 용어의 정의는 다음과 같습니다.",
      "1) '플랫폼'이란 '회사'가 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 서비스를 거래할 수 있도록 설정한 가상의 영업장을 의미합니다.",
      "A. 'NFT증서'라 함은 이용자 중 본 플랫폼을 통해 보험가입을 하고 가입의 결과로 플랫폼에서 발행되어 사용자의 보험관련 활동을 보조하는 블록체인 기반의 증서를 말합니다.",
      "B. '클립지갑'이라 함은 카카오톡 서비스내의 블록체인 지갑 서비스로 고객이 NFT증서를 확인하고 증서에서 가입확인, 청구확인 등을 통해 회사의 플랫폼으로 접근하는 서비스를 이용할 수 있는 외부 모바일어플리케이션을 말합니다.",
      "2) '가입서비스'라 함은 '회원'만이 이용할 수 있는 서비스로, 보험가입 등 개인정보입력과 약관에 대한 동의가 요구되는 서비스를 말합니다.",
      "3) '이용자'라 함은 플랫폼을 이용하는 자를 말하며 가입신청 절차를 진행중인 자를 말합니다.",
      "4) '회원'이라 함은 이용자 중 본 약관의 내용에 동의하고 가입서비스의 이용을 위하여 회사와 서비스 이용계약을 체결한 자를 말하며, 회원은 일반회원, 준회원 및 동반회원으로 구분됩니다.",
      "A. '일반회원'이라 함은 이용자 중 본 약관의 내용에 동의한 자로서 본 약관에 규정된 정식회원 가입 절차를 거친 자를 말 합니다.",
      "B. '준회원'이라 함은 플랫폼에 실명인증에 필요한 개인정보를 제공한 후 간편회원 가입 절차를 거친 자로서, 회사가 제공하는 서비스를 일시적으로 이용가능한 자를 말합니다. 동반회원 중 간편회원 가입절차를 거친 자도 포함합니다.",
      "C. '동반회원'이라 함은 일반회원 또는 준회원이 아닌 자로서, 일반회원 또는 준회원 중 1인(대표자)이 보험가입에 필요한 정보(성명, 휴대전화번호, 주민등록번호 및 기타 보험료 산출에 필요한 동반회원의 개인정보)를 플랫폼에 입력하는 때에 보험가입에 필요한 각종 개인정보 활용동의와 본 약관에 동의할 권한을 부여한 것으로 간주되는 자를 말합니다.",
      "D. 위 'C'호에서 '동반회원' 중 미성년자가 포함되는 경우, 법정대리인인 '대표자'가 미성년 동반회원의 '개인(신용)정보의 수집∙이용∙취급∙위탁 제공', 단체보험서비스 이용약관 및 단체보험규약에 동의한 것으로 간주합니다.",
      "5) '이용자 등'이란 위에서 규정된 이용자와 회원 모두를 포괄하여 지칭합니다.",
      "6) '보험사'라 함은 보험업법에 따른 허가를 받아 보험업을 경영하는 자를 말합니다.",
      "7) '보험상품'이라 함은 보험업법 제2조제1호에서 정의된 상품을 의미합니다.",
      "8) '보험계약'이라 함은 회사가 단체보험 규약에 따라서 회원을 대표하는 보험계약자가 되고 회원을 피보험자로 하여 제휴 보험사와 체결한 단체보험계약을 말 합니다.",
      "9) '주식회사 리트러스트'는 보험업법 제87조에 따라 등록되고, 동법 제83조에 따라 보험계약을 모집할 수 있는 법인보험대리점을 말합니다.",
      "10) '제휴보험사'라 함은 보험업법에 따른 허가를 받아 보험업을 경영하는 자로서, 회사와 보험대리점 계약을 체결한 자를 말합니다.",
      "11) '제휴사'라 함은 회사가 운영하는 플랫폼에서 위 제4항 회원을 위하여 각종 용역 서비스를 제공하는 주식회사 리트러스트 이외의 법인을 의미합니다.",
      "12) '회원사'라 함은 자신 고유의 플랫폼 운영사 혹은 회원을 보유하고 있는 단체로서, 해당 단체의 회원규약, 서비스 이용약관, 단체규약에 따라 회원사를 보험계약자로 하고 그 회원들을 피보험자로 하는 단체보험을 체결한 주체를 말합니다.",
      "제3조 (약관의 효력 등)",
      "1) 본 약관의 내용은 앱 또는 웹 서비스 화면에 게시하거나 기타의 방법으로 고객에게 공시하고, 이에 동의한 일반회원, 준회원 및 동반회원 등 본 약관에서 정한 절차에 따라 서비스 가입/이용함으로써 효력이 발생합니다. 회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 이메일, 사업자등록번호, 통신판매업 신고번호 등을 이용자가 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
      "2) 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.",
      "3) 본 약관을 개정할 경우에는 적용일 및 개정사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지된 사항을 게시합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.",
      "4) 본 약관이 개정되는 경우 그 적용일자 경과 후 상당한 기간이 경과할 때까지 플랫폼 화면 또는 화면과의 연결화면을 통해 공지하거나 수신통지방법(이메일, SMS 등)을 통해 개별적으로 알려드립니다. 이 때, 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 적용됩니다. 다만, 이미 계약을 체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 3)항에 의한 개정약관의 공지기간 내에 회사에 송신하고 회사의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.",
      "5) 회원은 서비스를 계속 이용함으로써 개정된 약관에 동의한 것으로 간주됩니다.",
      "6) 회원은 개정된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있습니다.",
      "7) 본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 정부가 제정한 전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에 따릅니다.",
    ],
  },
  {
    title: "[필수] 단체보험 규약 ",
    isActive: null,
    agreeItems: [
      { id: "uniqueId7", title: "위치 동의", checked: null },
      { id: "uniqueId8", title: "증권 상품 동의.", checked: null },
      { id: "uniqueId9", title: "혜택에 대한 내용", checked: null },
    ],
    description: [
      "단체보험 규약",
      "(B2C)단체보험 규약",
      "",
      "기준일:2024.05.10",
      "제1조 목적",
      "",
      "1) 본 규약은 주식회사 리트러스트(이하 “회사”라 합니다)가 운영하는 앱 또는 웹 플랫폼(이하 “플랫폼”이라 합니다)의 일반회원, 준회원 및 동반회원을 피보험자로 하는 단체보험서비스 이용에 관하여 회사와 일반회원, 준회원 및 동반회원간의 협약사항 규정을 그 목적으로 합니다.",
      "",
      "2) 회사는 “플랫폼”에 관한 제반기술과 운영에 대한 모든 권한을 갖고 있으며 플랫폼을 통하여 제공되는 서비스는 보험모집인(보험대리점)인 회사와 하기 별지에 기술된 제휴보험사가 전담합니다.",
      "제2조 내용",
      "",
      "회사가 본 규약에 동의하고 가입한 일반회원, 준회원 및 동반회원에게 제공하는 단체보험서비스의 종류는 회사에서 제공하는 보험을 지칭합니다.",
      "",
      "제3조 회원자격",
      "",
      "1) 회사는 “단체보험 서비스 이용약관” 및 본 규약에 동의하고 가입한 자들을 일반회원, 준회원 및 동반회원으로 하여 보험가입을 위한 단체(상법 및 보험업법상의 단체보험의 단체)를 구성하며, 보험가입에 동의한 자들을 피보험자로 하여 단체보험계약을 체결합니다.",
      "",
      "2) 본 규약에 동의한 자들은 본 규약의 당사자가 됩니다.",
      "",
      "3) 위 제2항 규약 당사자들은 회사가 요구하는 개인정보를 입력하고 본 규약에 대하여 동의 여부를 묻는 박스를 클릭하고 체크표시를 함으로써 단체보험의 피보험자가 될 수 있는 회원자격을 얻게 됩니다.",
      "",
      "4) “준회원”이라 함은 플랫폼에 실명인증에 필요한 개인정보를 제공한 후 간편회원 가입 절차를 거친 자로서, 회사가 제공하는 서비스를 일시적으로 이용가능한 자를 말합니다. 동반회원 중 간편회원 가입절차를 거친 자도 포함합니다.",
      "",
      "5) 동반회원(동반가입자) 가입의 경우 다음과 같습니다.",
      "",
      "A. 일반회원 또는 준회원 중 1인(대표자)이 “단체보험계약”에 의하여 나머지 동반회원들로부터 보험가입에 필요한 정보(성명, 휴대전화번호, 주민등록번호 및 기타 보험료 산출에 필요한 정보)를 회사의 “플랫폼”에 입력하는 때에 보험가입에 필요한 “개인(신용)정보의 수집∙이용∙취급∙위탁 제공”에 대한 동의와 “단체보험 서비스 이용약관” 및 본 규약에 동의할 권한을 부여받은 것으로 간주합니다.",
      "",
      "B. 회사는 이를 바탕으로 보험 계약 체결 및 이용을 하게 할 수 있으며, 일반회원 또는 준회원 중 1인(대표자)이 “개인(신용)정보의 수집∙이용∙취급∙위탁 제공”에 대한 동의와 “단체보험 서비스 이용약관” 및 본 규약에 동의하는 경우 동반회원도 단체보험의 구성원이 된 것으로 간주합니다.",
      "",
      "C. 위 “A”호의 동반회원 중 미성년자가 포함되는 경우, 법정대리인(대표자)이 각종 “개인(신용)정보의 수집∙이용∙취급∙위탁 제공” 동의서 및 본 약관에 동의하는 경우 미성년 동반회원에 대한 “개인(신용)정보의 수집∙이용∙취급∙위탁 제공” 동의서와 본 협약에 동의한 것으로 간주합니다.",
      "",
      "6) 대표자를 포함한 동반회원들은 회사를 단체보험계약자로하고, 자신들을 피보험자로 하는 단체보험계약을 체결함에 있어 회사가 일괄로 가입하는 방식에 동의합니다.",
      "7) 단체보험계약에 따른 세부내용은 보험사가 제공하는 보험약관에 따르며 본 약관에 정하지 않은 사항은 회사가 보험사와 정한 약정에 따릅니다.",
      "",
      "제4조 단체보험의 가입",
      "",
      "1) 회사는 일반회원, 준회원 및 동반회원을 위하여 계약자로서 보험사와 단체계약을 체결할 수 있습니다.",
      "",
      "2) 일반회원, 준회원 및 동반회원은 동의 절차를 거쳐 가입 신청을 합니다.",
      "",
      "3) 일반회원, 준회원 및 동반회원은 본 규약, “단체보험 서비스 이용약관” 및 “보험상품약관”, 플랫폼에서 제공되는 “상품설명서”를 충분히 읽고 숙지한 후 가입 신청을 하며 요구되는 사항에 대해서 보험사에게 성실히 고지합니다.",
      "",
      "4) 일반회원, 준회원 및 동반회원은 회사를 단체보험의 계약자로 일반회원, 준회원 및 동반회원을 피보험자로 하는 단체보험계약을 체결함에 있어 회사가 일괄로 가입하는 방식에 동의합니다.",
      "",
      "5) 보험계약의 청약을 하고자 하는 일반회원, 준회원 및 동반회원은 “보험상품약관”, “상품설명서”, Q&A 등을 충분히 읽고 이해한 후 청약을 하며, 보험사에서 고지를 요구하는 사항에 대하여 성실히 답합니다.",
      "",
      "6) 일반회원, 준회원 및 동반회원이 가입한 단체보험계약의 수익자는 피보험자 생존시 단체보험계약상 피보험자(일반회원, 준회원 및 동반회원) 본인이 되며, 피보험자 본인 사망시에는 그 법정상속인이 수익자가 됩니다.",
      "",
      "7) 위 6)항의 단체보험계약의 동반회원이 미성년자인 경우, 본 약관 제3조 제1)항의 “대표자”는 동반회원의 법정대리인이어야 하며, 단체 대표자가 가입을 진행할 시에는 법정대리인으로부터 동의를 받아야 합니다.",
      "",
      "8) 회사는 단체보험계약자로서 단체보험의 가입, 유지, 피보험자(일반회원, 준회원 및 동반회원) 관리, 보험료 수금 및 납입 등에 필요한 제반 업무는 주식회사 리트러스트보험서비스㈜에 위임합니다.",
      "제5조 기타사항",
      "",
      "1) 회사는 기존 서비스의 변경, 새로운 서비스 출시, 법령의 개폐, 회원요구 사항이 있어 본 규약을 변경할 필요가 있는 경우 일반회원, 준회원 및 동반회원들을 위하여 이 규약을 즉시 변경합니다. 그리고 변경된 규약은 즉시 효력을 발휘합니다.",
      "",
      "2) 회사는 규약변경을 위하여 필요한 경우 일반회원, 준회원 및 동반회원들에게 의견을 구할 수 있으며, 일반회원 및 준회원들은 이에 성실히 응합니다.",
      "",
      "3) 규약변경을 희망하시는 일반회원, 준회원 및 동반회원께서는 회사에 그 구체적 변경안과 이유를 제시하여 규약변경을 제안할 수 있습니다. 이에 대하여 회사는 전항의 규정에 따라 규약을 변경합니다.",
      "",
      "4) 회사는 단체보험가입 등 규약에 직접적으로 영향을 받는 서비스를 이용하는 일반회원, 준회원 및 동반회원에게는 규약적용에 부동의 하는지 의견을 구합니다. 이때 규약에 부동의 하는 일반회원 및 준회원들은 단체보험계약을 체결하지 않으실 수 있습니다.",
      "",
      "5) 회사는 단체보험계약에 있어서 보험업법 제2조제10호에 근거하여 보험회사를 위하여 보험계약의 체결을 대리하는 자로서 동법 제84조에 따라 등록된 보험대리점입니다. 회사의 “개인(신용)정보의 수집∙이용∙취급∙위탁 제공” 동의서의 내용을 바탕으로 한 개인정보를 취급합니다.",
      "[별지]",
      "",
      "1. 2024년 5월 10일 현재 제1조의 제휴보험사는 아래와 같으며, 향후 제휴보험사는 변동될 수 있습니다.",
      "",
      "※제휴보험사: 메리츠화재",
      "",
      "",
      "2. 2024년 5월 10일 현재 제1조의 제휴보험사는 아래와 같으며, 향후 제휴보험사는 변동될 수 있습니다.",
      "",
      "※제휴보험사: 메리츠화재",
    ],
  },
];

export default ClaimAgreeData;
