import "./App.css";
import Router from "./share/Router";
import BrowserSelector from './components/common/BrowserSelector';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="layoutWrapper">
      <div className="layoutContents">
        <BrowserSelector />
        <Router />
      </div>
    </div>
  );
}

export default App;
