// ClaimCalendar.js 청구하기 캘린더
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import commonCalendar from "../../assets/commonCalendar.svg";
import CalendarModal from "./CalendarModal";

const ClaimCalendar = ({
  onSelect,
  initialDate,
  disabled = false,
  claimableContracts,
  encryptedRRN,
  ableDate,
  ...props
}) => {
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    initialDate ? dayjs(initialDate) : null
  );

  const findMatchingContract = (selectedDay, contracts) => {
    return contracts.find(
      (contract) =>
        selectedDay.isSame(dayjs(contract.insurance_start_date), "day") ||
        selectedDay.isSame(dayjs(contract.insurance_end_date), "day") ||
        (selectedDay.isAfter(dayjs(contract.insurance_start_date)) &&
          selectedDay.isBefore(dayjs(contract.insurance_end_date)))
    );
  };

  const callClaimApi = async (contractId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      encryptedData: encryptedRRN.encryptedData,
      encryptedKey: encryptedRRN.encryptedKey,
      iv: encryptedRRN.iv,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/trip-api/auth/regist/${contractId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("API 호출 실패");
      }
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.log(error, "API 호출에 실패했습니다.");
    }
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClaimSelect = (date) => {
    const selectedDay = dayjs(date);
    setSelectedDate(selectedDay);

    if (Array.isArray(claimableContracts) && claimableContracts.length > 0) {
      const matchingContract = findMatchingContract(
        selectedDay,
        claimableContracts
      );
      if (matchingContract) {
        setSelectedContractId(matchingContract.contract_id);
        callClaimApi(matchingContract.contract_id);
      }
    }

    onSelect(date);
  };

  useEffect(() => {
    if (selectedContractId) {
      callClaimApi(selectedContractId);
    }
  }, [selectedContractId]);

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(dayjs(initialDate));
    }
  }, [initialDate]);

  return (
    <div
      className={`bg-[#F3F4F6] flex w-full rounded-xl ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      <div className="flex gap-2 items-center p-4">
        <img
          src={commonCalendar}
          onClick={handleOpen}
          className={`text-2xl cursor-pointer ${
            disabled ? "pointer-events-none" : ""
          }`}
          alt="Open calendar"
        />
        <Typography variant="h6">
          <p
            className={`text-[14px] ${
              selectedDate ? "text-[#1B1E28]" : "text-[#96989c]"
            } font-normal`}
          >
            {selectedDate
              ? selectedDate.format("YYYY-MM-DD")
              : "청구일자를 선택해주세요."}
          </p>
        </Typography>
      </div>
      <CalendarModal
        open={open}
        onClose={handleClose}
        onSelect={handleClaimSelect}
        initialDate={initialDate}
        disabled={disabled}
        ableDate={ableDate}
        showAbleDates={true}
        isExtendCalendar={false}
        {...props}
      />
    </div>
  );
};

export default ClaimCalendar;
