import React, { useState } from "react";

function Authenticate() {
  const [sEncData, setSEncData] = useState("");

  const fnPopup = () => {
    const form = document.createElement("form");
    form.method = "post";
    form.action =
      "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    form.target = "popupChk";

    const encodeDataInput = document.createElement("input");
    encodeDataInput.type = "hidden";
    encodeDataInput.name = "EncodeData";
    encodeDataInput.value = sEncData;
    form.appendChild(encodeDataInput);

    const mInput = document.createElement("input");
    mInput.type = "hidden";
    mInput.name = "m";
    mInput.value = "checkplusService";
    form.appendChild(mInput);

    document.body.appendChild(form);

    // Define popup window size
    const width = 600;
    const height = 400;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    // Open popup window
    const popup = window.open(
      "",
      "popupChk",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (popup) {
      form.target = "popupChk";
      form.submit();
      document.body.removeChild(form); // Remove the form from the main document after submission
    } else {
      alert(
        "팝업 창이 차단되었습니다. 팝업 차단을 해제하고 다시 시도해주세요."
      );
    }
  };

  return (
    <div>
      <button type="button" onClick={fnPopup}>
        CheckPlus 안심본인인증 Click
      </button>
    </div>
  );
}

export default Authenticate;
