import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Claim/claimExtendDate.module.css";
import Loading from "../../components/modal/Loading";
import ClaimSubHeaders from "../../components/claimComponents/ClaimSubHeaders";
import DownArrow from "../../assets/commonDownArrow.svg";
import cityData from "../../api/CityinfoData.json";
import ErrorModal from "../../components/modal/ErrorModal";
import { useSelector } from "react-redux";

function ClaimExtendDate() {
  const [extendData, setExtendData] = useState([]);
  const [activeBtns, setActiveBtns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
   const token = useSelector((state) => state.cookie.cookie);
   console.log(token);
  

  const filterCountry = (countryCd) => {
    const country = cityData.find((e) => e.cityNatlCd === countryCd);
    return country ? country.korNatlNm : country.korCityNm;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/contracts/extendable`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setExtendData(result.data || []);
      } catch (error) {
        console.error("Fetching data failed:", error);
        setIsModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (extendData.length === 0) {
      setIsModalOpen(true);
    }
  }, [extendData]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/claimMain");
  };

  const toggleActive = (index, moid) => {
    setActiveBtns((prev) => {
      const newActiveBtns = [...prev];
      newActiveBtns[index] = !newActiveBtns[index];
      return newActiveBtns;
    });
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      navigate(`/claimExtendDate/${moid}`, { state: extendData });
    }, 1000);
  };

  const handleToggleShow = () => setShowAll((prev) => !prev);

  if (loading) return <Loading />;
  if (extendData.length === 0)
    return (
      <ErrorModal
        onClose={handleCloseModal}
        message="해당 데이터가 없습니다."
      />
    );

  return (
    <>
      <ClaimSubHeaders titleText="여행기간 도착일 연장" />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h3>도착일을 연장할 계약을 선택해주세요.</h3>
            <span>*출국 당일날에는 도착일 연장이 되지않습니다.</span>
          </div>
          <div className={styles.contentWrap}>
            {extendData
              .slice(0, showAll ? extendData.length : 3)
              .map((e, i) => (
                <div
                  key={i}
                  className={styles.contentsList}
                  onClick={() => toggleActive(i, e.moid)}
                >
                  <div className={styles.contentsListTitle}>
                    <h3>해외여행 실손의료보험</h3>
                    <div className={styles.toggleSwitch}>
                      <div
                        className={`${styles.toggleBtnWrap} ${
                          activeBtns[i] ? styles.active : ""
                        }`}
                      >
                        <div className={styles.imageWrap}>
                          <div className={styles.imageBg}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.boundary}></div>
                  <ul>
                    <li>
                      <p>출발일:</p>
                      <p>{e.insurance_start_date}</p>
                      <p>도착일:</p>
                      <p>{e.insurance_end_date}</p>
                    </li>
                    <li>
                      <p>여행지:</p>
                      <p>{filterCountry(e.trip_country)}</p>
                    </li>
                    <li>
                      <p>증권번호</p>
                      <p>15440-56752</p>
                    </li>
                  </ul>
                </div>
              ))}
          </div>
          {extendData.length > 3 && (
            <div className={styles.showMore} onClick={handleToggleShow}>
              <p>{showAll ? "접기" : "펼쳐보기"}</p>
              <img
                src={DownArrow}
                alt=""
                className={showAll ? styles.imgActive : ""}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ClaimExtendDate;
