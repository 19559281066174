import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Recommend from "../../components/calculationProcessPart/Recommend";
import Button from "../../components/common/Button";
import Header from "../../components/common/Header";
import SearchModal from "../../components/modal/SearchModal";
import Loading from "../../components/modal/Loading";
import { setSelectedCountryData, clearCountryData } from "../../redux/store";
import styles from "../../css/calculationProcessCSS/TripSelect.module.css";
import CityinfoData from "../../api/CityinfoData.json";
import commonSearch from "../../assets/commonSearch.svg";

// getImageUrl 함수 정의
const getImageUrl = async (countryCode) => {
  const imageUrl = `/nationImages/${countryCode}_92.png`;
  try {
    const response = await fetch(imageUrl);
    if (response.ok) {
      return imageUrl;
    } else {
      throw new Error("Image not found");
    }
  } catch (error) {
    return "/nationImages/NC01_92.png";
  }
};

function TripSelect() {
  const [countryDataes, setCountryDataes] = useState([]);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null); // 선택된 국가 정보 상태
  const [countryImageUrl, setCountryImageUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to store selected country in sessionStorage
  const storeSelectedCountry = (country) => {
    sessionStorage.setItem("selectedCountry", JSON.stringify(country));
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 10;
      setIsHeaderSticky(window.scrollY >= headerHeight);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setCountryDataes(CityinfoData);
      } catch (error) {
        console.error("에러 메세지:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const storedCountry = sessionStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(JSON.parse(storedCountry));
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const fetchImageUrl = async () => {
        const url = await getImageUrl(selectedCountry.cityNatlCd);
        setCountryImageUrl(url);
      };
      fetchImageUrl();
    }
  }, [selectedCountry]);

  const NextButton = () => {
    if (!selectedCountry) {
      alert("선택한 나라가 없습니다!");
    } else {
      dispatch(setSelectedCountryData(selectedCountry));
    }
    navigate("/trip/indemnity");
  };

  const handleSelectCountry = (selectedData) => {
    dispatch(clearCountryData()); // 선택된 국가 데이터를 초기화
    setSelectedCountry(selectedData); // 선택한 국가 정보 저장
    storeSelectedCountry(selectedData); // Store selected country in sessionStorage
    setIsModalOpen(false); // 모달 닫기
  };

  // 선택된 국가에 대한 데이터 필터링 함수
  const getSelectedCountryData = () => {
    if (!selectedCountry) return null;

    const foundCountry = countryDataes.find(
      (country) => country.cityNatlCd === selectedCountry.cityNatlCd
    );
    return foundCountry;
  };

  const selectedCountryData = getSelectedCountryData();
  console.log(selectedCountryData?.cityNatlCd);

  const defaultImageUrl = "/nationImages/NC01_92.png";

  return (
    <>
      <section className={styles.TripSection}>
        <Header TitleText={true} isSticky={isHeaderSticky} />
        {isLoading && <Loading />} {/* 데이터 로딩 중이면 로딩 컴포넌트 표시 */}
        <div>
          <p className={styles.title}>
            어떤 곳으로 여행을 하실 계획
            <br /> 이신가요?
          </p>
          <div
            className={`${styles.stickycontainer} ${
              isHeaderSticky ? styles.stickyHeader : ""
            }`}
          >
            <div className={styles.tripSelectBox}>
              <p className={styles.tripNation}>여행국가</p>
              <div
                className={styles.inputSearchboxWrap}
                onClick={() => setIsModalOpen(true)}
              >
                <input
                  type="text"
                  className={styles.inputSearchBox}
                  placeholder="여행 국가 선택"
                  readOnly
                />
                <img
                  src={commonSearch}
                  alt="search icon"
                  className={styles.SearchIcon}
                />
              </div>
            </div>
          </div>

          {/* 선택된 국가 데이터 출력 */}
          {selectedCountry && selectedCountryData && (
            <div className={styles.selectedCountryWrap}>
              <div className={styles.selectedContents}>
                <div className={styles.selectedContentsWrap}>
                  <div
                    className={styles.selectedImageWrap}
                    style={{
                      backgroundImage: `url(${countryImageUrl}), url(${defaultImageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>

                  <div className={styles.selectedContentsInfo}>
                    <div className={styles.selectedTitle}>
                      <div className={styles.ImageWrapper}>
                        <img
                          src={
                            selectedCountryData.imageUrl
                              ? selectedCountryData.imageUrl
                              : "/nationImages/defalutImage.png"
                          }
                          alt="이미지"
                          className={styles.fetchImages}
                        />
                      </div>
                      {selectedCountryData.korNatlNm}
                    </div>
                    <div className={styles.selectedData}>
                      <div className={styles.selectedDataItem}>
                        <span className={styles.dataLabel}>
                          수도: {selectedCountryData.capitalCity}
                        </span>
                      </div>
                      <div className={styles.selectedDataItem}>
                        <span className={styles.dataLabel}>
                          대륙: {selectedCountryData.continent}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Recommend Component */}
          <Recommend />

          {/* 다음 버튼 */}
          <div className={styles.buttonWrap}>
            <div className={styles.buttonWrap_bg}></div>
            <div className={styles.buttonWrap_line}></div>
            <Button
              onClick={NextButton}
              disabled={!selectedCountry}
              buttonText="다음"
            />
          </div>
        </div>
        {/* 검색 모달 */}
        {isModalOpen && (
          <SearchModal
            onClose={() => setIsModalOpen(false)}
            onSelectCountry={handleSelectCountry} // 선택한 국가 정보를 처리할 함수 전달
          />
        )}
      </section>
    </>
  );
}

export default TripSelect;
