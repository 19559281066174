import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "../../css/nice/nice.module.css";
import { login } from "../../redux/store"; // login 액션을 올바르게 가져옴
import { FaMobileAlt } from "react-icons/fa"; // Font Awesome 스타일

const GoNice = () => {
  const [niceWin, setNiceWin] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // useDispatch 훅 추가
  const [error, setError] = useState(null);

  // receiveToken 함수를 useEffect를 사용하여 설정
  useEffect(() => {
    window.receiveToken = (result) => {
      dispatch(login(JSON.stringify(result))); // 로그인 액션 디스패치
      const myHeaders = new Headers();
      myHeaders.append("token", result.token);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch("/trip-api/auth/getCookie", requestOptions)
        .then((response) => response.json()) // JSON 형태의 응답 처리
        .then((data) => {
          console.log(data); // JSON 데이터를 콘솔에 출력
          if (niceWin) {
            niceWin.close(); // 팝업 창 닫기
          }
          navigate("/claimMain");
        })
        .catch((error) => {
          console.error(error);
          setError("인증 처리 중 오류가 발생했습니다. 다시 시도해 주세요.");
        });
    };
  }, [navigate, niceWin, dispatch]);

  const handleClick = () => {
    const newWindow = window.open(
      `${process.env.REACT_APP_BASE_URL}/checkplus_main`,
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );
    setNiceWin(newWindow); // 팝업 창 설정
  };

  return (
    <>
      <div className={styles.ClipLoginBtn} onClick={handleClick}>
        <FaMobileAlt className={styles.icon} size={20} />
        <p>휴대폰 본인인증 로그인</p>
      </div>
      {error && (
        <p className={styles.errorMessage} role="alert">
          {error}
        </p>
      )}
    </>
  );
};

export default GoNice;
