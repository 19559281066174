import React, { useState, useEffect } from "react";
import CustomInput from "./CustomInput";
import Loading from "../../components/modal/Loading";
import styles from "../../css/Comnons/secureKeyboard.module.css";
import cancel from "../../assets/commonX.svg";

const SecureKeyboard = ({ onChange, value, setSecretValue }) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [maskedValue, setMaskedValue] = useState("");
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputValue(value || "");
    updateMaskedValue(value || "");
  }, [value]);

  const updateMaskedValue = (val) => {
    if (val.length === 0) {
      setMaskedValue("");
    } else if (val.length <= 7) {
      setMaskedValue("*".repeat(val.length - 1) + val.slice(-1));
    } else {
      setMaskedValue("*".repeat(7) + val.slice(-1));
    }
  };

  const handleKeyPress = (value) => {
    if (/^\d*$/.test(value)) {
      const newValue = inputValue + value;
      const truncatedValue = newValue.slice(0, 7); // 최대 7자리까지만 유지
      updateMaskedValue(truncatedValue);
      setInputValue(truncatedValue);
    }
  };

  const handleDelete = () => {
    if (inputValue.length > 0) {
      const newValue = inputValue.slice(0, -1);
      updateMaskedValue(newValue);
      setInputValue(newValue);
    }
  };

  const handleDeleteAll = () => {
    setInputValue("");
    setMaskedValue("");
  };

  const toggleKeyboard = () => {
    setKeyboardActive(!keyboardActive);

    // 키보드를 토글할 때 입력값과 마스킹된 값을 초기화
    if (!keyboardActive) {
      setInputValue("");
      setMaskedValue("");
    }
  };

  const handleConfirm = () => {
    onChange(inputValue); // 변경된 값을 부모 컴포넌트로 전송
    toggleKeyboard(); // 키보드 닫기
  };

  return (
    <div>
      <div onClick={toggleKeyboard}>
        <CustomInput
          type="text"
          value={maskedValue || " "}
          maxLength={7} // 최대 7자리까지 보여줌
          readOnly
          className={styles.customInput}
        />
      </div>
      {keyboardActive && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.modalContentWrap}>
                <span className={styles.modalTitle}>
                  뒷자리를 입력해주세요.
                </span>
                <img src={cancel} alt="cancel" onClick={toggleKeyboard} />
              </div>
              <div className={styles.progressBar}>
                <CustomInput
                  type="text"
                  value={maskedValue || " "}
                  maxLength={7} // 최대 7자리까지 보여줌
                  readOnly
                />
              </div>
              {error && (
                <p style={{ color: "#E86565", marginTop: "8px" }}>{error}</p>
              )}
            </div>
            <div className={styles.keyboard}>
              {[...Array(10).keys()].map((number, index) => (
                <div
                  key={index}
                  className={styles.keyboardKey}
                  onClick={() => handleKeyPress(number.toString())}
                >
                  {number}
                </div>
              ))}
              <div className={styles.keyboardKey} onClick={handleDelete}>
                삭제
              </div>
              <div className={styles.keyboardKey} onClick={handleDeleteAll}>
                전체삭제
              </div>
            </div>
            <div className={styles.modalActions}>
              <button
                className={styles.closeButton}
                onClick={handleConfirm}
                disabled={inputValue.length === 0}
                style={{ opacity: inputValue.length === 0 ? 0.5 : 1 }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default SecureKeyboard;
