import React, { useState, useEffect, useRef, useCallback } from "react";
import commonSearch from "../../assets/commonSearch.svg";
import { InputDeleteSVG } from "../common/SvgIcon/RestFinishSVG";
import Loading from "../../components/modal/Loading";
import ErrorModal from "../../components/modal/ErrorModal";
import commonX from "../../assets/commonX.svg";
import CityinfoData from "../../api/CityinfoData.json";

function SearchModal({ onClose, onSelectCountry }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allResults, setAllResults] = useState([]);
  const [error, setError] = useState(null);
  const searchInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    setIsActive(true);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (selectedData) {
      onSelectCountry(selectedData);
      onClose(); // 선택 후 모달 닫기
    }
  }, [selectedData, onSelectCountry, onClose]);

  const fetchCountries = () => {
    if (Array.isArray(CityinfoData)) {
      const countrySet = new Set(
        CityinfoData.map((data) => data.korNatlNm).filter(Boolean)
      );
      const citySet = new Set(
        CityinfoData.map((data) => data.korCityNm).filter(Boolean)
      );
      const sortedCountries = Array.from(countrySet).sort((a, b) =>
        a.localeCompare(b, "ko-KR")
      );
      const sortedCities = Array.from(citySet).sort((a, b) =>
        a.localeCompare(b, "ko-KR")
      );
      const combinedResults = [...sortedCountries, ...sortedCities];
      setAllResults(combinedResults);
      setSearchResults(combinedResults);
    } else {
      console.error("데이터가 배열 형태가 아닙니다:");
    }
  };

  const handleErrorConfirm = () => {
    setError(null);
    setSearchTerm(""); // 검색어 초기화
    setSearchResults(allResults); // 검색 결과 초기화
    setIsActive(true); // 활성 상태로 변경
  };

  const handleInputClick = useCallback(() => {
    setIsActive(true);
    setSearchTerm("");
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      const searchValue = event.target.value;
      setSearchTerm(searchValue);
      if (searchValue === "") {
        setSearchResults(allResults);
      } else {
        const filteredResults = allResults.filter((result) =>
          result.toLowerCase().includes(searchValue.toLowerCase())
        );
        setSearchResults(filteredResults);
      }
    },
    [allResults]
  );

  const serverData = useCallback(
    async (searchValue) => {
      setIsLoading(true);

      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({ searchValue }),
          redirect: "follow",
        };

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/searchNatlCd`,
          requestOptions
        );

        if (response.ok) {
          const data = await response.json();
          setIsActive(false);
          if (
            data.opapiTrvCityNatlInfCbcVo &&
            data.opapiTrvCityNatlInfCbcVo.length > 0
          ) {
            const trvRskGrdeCd = data.opapiTrvCityNatlInfCbcVo[0].trvRskGrdeCd;
            if (trvRskGrdeCd === "XX") {
              setError("해당 국가는 여행이 제한된 국가입니다.");
              setSearchTerm(""); // 검색어 초기화
              setSearchResults(allResults); // 검색 결과 초기화
              setIsActive(true); // 활성 상태로 변경
            } else {
              setSelectedData(data.opapiTrvCityNatlInfCbcVo[0]);
            }
          } else {
            console.error("서버 응답 오류: 데이터 형식이 잘못되었습니다.");
          }
        } else {
          console.error("서버 응답 오류:", response.status);
        }
      } catch (error) {
        console.error("데이터를 불러오는 중 오류가 발생했습니다:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [allResults]
  );

  const handleSearch = useCallback(
    (searchValue) => {
      const selectedData = CityinfoData.find(
        (data) =>
          data.korNatlNm === searchValue || data.korCityNm === searchValue
      );
      if (selectedData) {
        serverData(selectedData.cityNatlCd);
      } else {
        setError("검색 결과가 없습니다.");
      }
    },
    [serverData]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="fixed bottom-0 left-0 right-0 flex justify-center items-center h-full bg-black bg-opacity-60 z-50">
          <div className="commonModal bg-white rounded-t-lg w-full h-[420px] absolute bottom-0 p-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-[#353535] text-[20px] font-bold relative bg-white z-10 p-4">
                여행 국가 선택
              </p>
              <img
                src={commonX}
                alt="Close"
                onClick={onClose}
                className="cursor-pointer"
              />
            </div>
            <div
              className={`rounded-lg mb-3 focus:outline-none py-3 cursor-pointer w-full text-center flex items-center relative bg-slate-100 ${
                isActive ? "border-[#386937] border" : ""
              }`}
              onClick={handleInputClick}
            >
              <input
                ref={searchInputRef}
                className="text-sm px-10 py-[6px] outline-none bg-[#f3f4f6] w-full"
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="여행지를 검색해주세요"
              />
              {isActive && searchTerm !== "" && (
                <div
                  className="flex justify-end mr-2"
                  onClick={() => setSearchTerm("")}
                >
                  <InputDeleteSVG />
                </div>
              )}
              <div className="flex">
                <img
                  src={commonSearch}
                  className={`absolute cursor-pointer top-[28%] right-[90%] ${
                    isActive || searchTerm === "" ? "block" : "hidden"
                  }`}
                  alt="Search"
                  onClick={() => handleSearch(searchTerm)}
                />
              </div>
            </div>
            <div className="rounded-lg max-h-[250px] overflow-auto scrollbar-hide">
              <ul>
                {searchResults.map((result, index) => (
                  <li
                    key={index}
                    className="p-2 text-sm mb-1 text-[#1B1E28] font-medium cursor-pointer"
                    onClick={() => handleSearch(result)}
                  >
                    {result}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {error && <ErrorModal message={error} onClose={handleErrorConfirm} />}
    </>
  );
}

export default SearchModal;
