import ClaimRevocationSlide from "../../components/claimComponents/ClaimRevacationSlide";
import styles from "../../css/Claim/claimRevocation.module.css";
import { useNavigate } from "react-router-dom";
import ClaimSubHeaders from "../../components/claimComponents/ClaimSubHeaders";

function ClaimRevocation() {
  const navigate = useNavigate();

  return (
    <>
      <>
        <ClaimSubHeaders titleText="개시 전 취소 청약 철회" />
        <div className={styles.Wrapper}>
          <section className={styles.section}>
            <h3 className={styles.title}>
              여행 보험/장기체류보험
              <br /> 가입내역확인
            </h3>
            <span
              className={styles.moreBtn}
              onClick={() => {
                navigate("/claimRevocationAll");
              }}
            >
              전체보기
            </span>
            <ClaimRevocationSlide />
            <div className={styles.noticeContents}>
              <ul>
                <li>
                  <span>・</span>
                  <p>
                    여행보험 중 개시전 취소/청약철회를 하실 계약을 선택해 주시기
                    바랍니다. 증권번호를 클릭하시면 개시전 취소/청약철회를
                    신청하실 수 있습니다.
                  </p>
                </li>
                <li>
                  <div className={styles.secondTextFlexbox}>
                    <div>
                      <p>・</p>
                      <p>보험료 환급안내</p>
                    </div>
                    <p className={styles.texts}>
                      보험기간이 시작되지 않은 보험계약 또는 청약일로부터 30일
                      이내의 계약만 가능하며, 보험료를 전액 환급해 드립니다
                    </p>
                  </div>
                  <span>
                    단, 아래의 경우에 해당하면 여행보험 개시전 취소/청약철회를
                    할 수 없습니다.
                  </span>
                </li>
                <li>
                  <p>[철회]</p>
                  <p>1. 청약일로부터 30일 이상 경과한 계약</p>
                  <p>2. 회사지원 진단계약</p>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </>
    </>
  );
}

export default ClaimRevocation;
