import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import insureTrustLogo from "../../assets/insureTrustLogo.svg";

function ClaimIntroSlide() {
  const swiperRef = useRef(null);
  const images = ["slide1.png", "slide2.png", "slide3.png", "slide4.png"];
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        const newIndex = (activeIndex + 1) % images.length;
        swiperRef.current.swiper.slideTo(newIndex);
        setActiveIndex(newIndex);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex, images.length]);
  // dsa
  const handleIndicatorClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
      setActiveIndex(index);
    }
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex); // Update activeIndex when slide changes
  };

  return (
    <div className="w-full relative">
      <Swiper
        ref={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img
                src={`/images/${image}`}
                alt={`Slide ${index}`}
                className="w-full h-full object-cover opacity-100"
              />
              <div className="absolute top-0 left-0 flex items-center justify-center">
                <img src={insureTrustLogo} alt="" width={170} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="top-[20px] relative">
        <ul className="flex gap-[5px] absolute bottom-[26px] right-2 z-40">
          {images.map((_, index) => (
            <li
              key={index}
              className={`w-[8px] h-[8px] rounded-full cursor-pointer ${
                index === activeIndex ? "bg-white" : "bg-[#eee]"
              }`}
              style={{ width: index === activeIndex ? "18px" : "9px" }}
              onClick={() => handleIndicatorClick(index)}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ClaimIntroSlide;
