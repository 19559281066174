import React, { useState } from "react";
import CustomInput from "./CustomInput";
import styles from "../../css/Comnons/secureKeyboard.module.css";
import commonX from "../../assets/commonX.svg";

const CommonSecureKeyboard = ({
  value,
  onChange,
  maxLength,
  placeholder,
  onConfirm,
  maskValue,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [keyboardActive, setKeyboardActive] = useState(false);

  const handleKeyPress = (keyValue) => {
    const newValue = inputValue + keyValue;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleDelete = () => {
    const newValue = inputValue.slice(0, -1);
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleDeleteAll = () => {
    setInputValue("");
    onChange("");
  };

  const toggleKeyboard = () => {
    setKeyboardActive(!keyboardActive);
    if (!keyboardActive) {
      setInputValue("");
    }
  };

  const handleConfirm = () => {
    onConfirm(inputValue);
    toggleKeyboard();
  };

  const handleClose = () => {
    toggleKeyboard();
    setInputValue("");
  };

  const updateMaskedValue = (val) => {
    if (val.length === 0) {
      return "";
    } else if (val.length <= 7) {
      return "*".repeat(val.length - 1) + val.slice(-1);
    } else {
      return "*".repeat(6) + val.slice(-1); // 최대 7자리까지만 허용
    }
  };

  const accountMaskValue = (value) => {
    if (!value) return "";

    let visiblePart = value.slice(0, 3); // 처음 3글자는 그대로 보여줌
    let maskedPart = "";

    // 마스킹 처리
    for (let i = 3; i < Math.min(value.length, 7); i++) {
      maskedPart += "*";
    }

    // 입력값의 길이가 8글자 이상이면 나머지 부분을 그대로 붙임
    if (value.length > 7) {
      maskedPart += value.slice(7);
    }

    return visiblePart + maskedPart;
  };

  const accountSecretMaskValue = (value) => {
    if (!value) return "";

    // 입력값을 13자리로 제한
    const limitedValue = value.slice(0, 13);

    let maskedValue = "";
    for (let i = 0; i < limitedValue.length; i++) {
      if (i < 6) {
        maskedValue += limitedValue[i]; // 처음 6글자는 그대로 보여줌
      } else if (i === limitedValue.length - 1) {
        maskedValue += limitedValue[i]; // 현재 입력한 마지막 숫자는 보여줌
      } else {
        maskedValue += "*"; // 7번째부터 마지막 전까지는 *로 마스킹
      }
    }

    return maskedValue;
  };

  const getMaskedValue = (value) => {
    if (maskValue === "secret") {
      return updateMaskedValue(value);
    } else if (maskValue === "account") {
      return accountMaskValue(value);
    } else if (maskValue === "accountSecret") {
      return accountSecretMaskValue(value);
    }
    return value;
  };

  return (
    <div>
      <div onClick={toggleKeyboard}>
        <CustomInput
          type="text"
          value={getMaskedValue(inputValue)} // 마스킹된 값으로 표시
          maxLength={maxLength}
          readOnly
          placeholder={placeholder}
          className={styles.customInput}
        />
      </div>
      {keyboardActive && (
        <div className={styles.overlay}>
          <div className={styles.modals}>
            <div className={styles.modalContents}>
              <div className={styles.modalContentWrap}>
                <span className={styles.modalTitles}>정보를 입력해주세요.</span>
                <img src={commonX} alt="닫기" onClick={handleClose} />
              </div>
              <div className={styles.inputWrap}>
                <CustomInput
                  value={getMaskedValue(inputValue)} // 마스킹된 값으로 표시
                  maxLength={maxLength}
                  onChange={(e) => {
                    setInputValue(e.target.value); // 입력 값 업데이트
                    onChange(e.target.value); // 부모 컴포넌트에도 업데이트 전달
                  }}
                  readOnly
                  placeholder={placeholder}
                  className={styles.customInput}
                />
              </div>
              <div className={styles.keyboard}>
                {[...Array(10).keys()].map((number) => (
                  <div
                    key={number}
                    className={styles.keyboardKeys}
                    onClick={() => handleKeyPress(number.toString())}
                  >
                    {number}
                  </div>
                ))}
                <div className={styles.keyboardKeys} onClick={handleDelete}>
                  삭제
                </div>
                <div className={styles.keyboardKeys} onClick={handleDeleteAll}>
                  전체삭제
                </div>
              </div>
              <div className={styles.modalActions}>
                <button
                  className={`${styles.closeButtons} ${
                    !inputValue.trim() ? styles.disabled : ""
                  }`}
                  onClick={handleConfirm}
                  disabled={!inputValue.trim()}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonSecureKeyboard;
