import React, { useState } from "react";
import commonX from "../../assets/commonX.svg";
import commonCalendar from "../../assets/commonCalendar.svg"; // Import the calendar icon
import styles from "../../css/Claim/claimConfirmEdit.module.css";
import Button from "../common/Button";
import ClaimCalendar from "../../components/claimComponents/CalendarModal";

function ClaimConfirmEdit({ handleClose }) {
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOpenStartDateCalendar = () => {
    setStartDateOpen(true);
  };

  const handleCloseStartDateCalendar = () => {
    setStartDateOpen(false);
  };

  const handleOpenEndDateCalendar = () => {
    setEndDateOpen(true);
  };

  const handleCloseEndDateCalendar = () => {
    setEndDateOpen(false);
  };

  const handleStartDateSelect = (date) => {
    setStartDate(date);
    setStartDateOpen(false);
  };

  const handleEndDateSelect = (date) => {
    setEndDate(date);
    setEndDateOpen(false);
  };

  return (
    <>
      <div className={styles.modalBackground}>
        <div className={styles.modalContent}>
          <div className={styles.modalContentWrap}>
            <div className={styles.modalTitle}>
              <h1>조회기준 선택</h1>
              <img src={commonX} alt="close" onClick={handleClose} />
            </div>
            <div className={styles.filterContentsWrap}>
              <div className={styles.orderByFilter}>
                <span>조회기준</span>
                <div className={styles.filterOrderByViewBtn}>
                  <button>사고일자</button>
                </div>
              </div>
              <div className={styles.orderByFilter}>
                <span>정렬기준</span>
                <div className={styles.filterOrderByViewBtn}>
                  <button>최근순</button>
                  <button>과거순</button>
                </div>
              </div>
              <div className={styles.orderByFilter}>
                <span>조회기간</span>
                <div className={styles.filterOrderDate}>
                  <button>1개월</button>
                  <button>3개월</button>
                  <button>6개월</button>
                  <button>1년</button>
                </div>
                <div className={styles.filterInsert}>
                  <p>직접입력</p>
                </div>
                <p>날짜선택</p>
                <div className={styles.dateInputContents}>
                  <div className={styles.dateInput}>
                    <div className={styles.dateInputButton}>
                      <div onClick={handleOpenStartDateCalendar}>
                        {startDate ? (
                          startDate.toLocaleDateString()
                        ) : (
                          <img src={commonCalendar} alt="calendar" />
                        )}
                      </div>
                      <ClaimCalendar
                        open={startDateOpen}
                        onClose={handleCloseStartDateCalendar}
                        onSelect={handleStartDateSelect}
                      />
                    </div>
                  </div>
                  <div className={styles.dateInput}>
                    <div className={styles.dateInputButton}>
                      <div onClick={handleOpenEndDateCalendar}>
                        {endDate ? (
                          endDate.toLocaleDateString()
                        ) : (
                          <img src={commonCalendar} alt="calendar" />
                        )}
                      </div>
                      <ClaimCalendar
                        open={endDateOpen}
                        onClose={handleCloseEndDateCalendar}
                        onSelect={handleEndDateSelect}
                      />
                    </div>
                  </div>
                </div>
                <p className={styles.explain}>
                  청구일자 조회기준 : 최초 청구일(추가 청구시 추가 청구일)
                </p>
              </div>
            </div>
          </div>
          <Button buttonText="조회" />
        </div>
      </div>
    </>
  );
}

export default ClaimConfirmEdit;
