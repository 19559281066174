import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/common/CustomInput";
import styles from "../../css/signUpProcessCSS/Member.module.css";
import { useLocation } from "react-router-dom";
import Button from "../../components/common/Button";
import AgreeTerms from "../../components/modal/AgreeTerms";
import { setMembersData } from "../../redux/store";

function CompanionMembers() {
  const dispatch = useDispatch();
  const priceData = useSelector((state) => state.priceData.priceData);
  const inspeCnts = priceData?.RECOMMEND?.inspeCnt || 1;
  const companions = useSelector((state) => state.companions) || [];
  const location = useLocation();
  const queryData = location.state;

  const companionsBirth = companions?.map((e) => e.dateOfBirth) || [];
  const companionsGender = companions?.map((e) => e.gender) || [];

  const initialState = {
    name: "",
    englishName: "",
    phoneNumber: "",
    email: "",
  };

  // Redux에서 가져온 동반인 데이터로 초기 상태 설정
  const getInitialMembers = () => {
    return companions.map((companion) => ({
      name: companion.koreanName || "",
      englishName: companion.englishName || "",
      phoneNumber: companion.phoneNumber || "",
      email: companion.email || "",
    }));
  };

  const [members, setMembers] = useState(
    Array(Math.max(0, (inspeCnts || 1) - 1)).fill(initialState)
  );
  const [errors, setErrors] = useState(
    Array(Math.max(0, (inspeCnts || 1) - 1)).fill(initialState)
  );
  const [showModal, setShowModal] = useState(false);

  // 모달 상태 관리
  useEffect(() => {
    const modalState = sessionStorage.getItem("modalOpen");
    if (modalState === "true") {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("modalOpen", showModal);
  }, [showModal]);

  // 멤버 데이터 초기화 및 관리
  useEffect(() => {
    const storedMembers = sessionStorage.getItem("members");
    const reduxMembers = getInitialMembers();
    const targetLength = Math.max(0, (inspeCnts || 1) - 1);

    if (reduxMembers.length > 0) {
      // Redux 데이터가 있는 경우
      const updatedMembers = reduxMembers.slice(0, targetLength);
      if (updatedMembers.length < targetLength) {
        const additionalMembers = Array(
          targetLength - updatedMembers.length
        ).fill(initialState);
        setMembers([...updatedMembers, ...additionalMembers]);
      } else {
        setMembers(updatedMembers);
      }
    } else if (storedMembers) {
      // sessionStorage 데이터 사용
      const parsedMembers = JSON.parse(storedMembers);
      if (parsedMembers.length < targetLength) {
        const newMembers = Array(targetLength - parsedMembers.length).fill(
          initialState
        );
        setMembers([...parsedMembers, ...newMembers]);
      } else {
        setMembers(parsedMembers.slice(0, targetLength));
      }
    }
  }, [companions, inspeCnts]);

  useEffect(() => {
    if (members.length > 0) {
      sessionStorage.setItem("members", JSON.stringify(members));
    }
  }, [members]);

  // 입력 핸들러
  const handleInputChange = (index, key, value) => {
    const updatedMembers = [...members];
    updatedMembers[index] = { ...updatedMembers[index], [key]: value };
    setMembers(updatedMembers);
  };

  const handleInputClear = (index, key) => {
    const updatedMembers = [...members];
    updatedMembers[index] = { ...updatedMembers[index], [key]: "" };
    setMembers(updatedMembers);
  };

  const handleEnglishNameChange = (value, index) => {
    value = value.replace(/[^a-zA-Z\s]/g, "").toUpperCase();
    handleInputChange(index, "englishName", value);
  };

  const handlePhoneNumberChange = (value, index) => {
    value = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");
    handleInputChange(index, "phoneNumber", value);
  };

  const handleEmailChange = (value, index) => {
    value = value.replace(/[^a-zA-Z0-9@._%+-]/g, "");
    handleInputChange(index, "email", value);
  };

  // 유효성 검사 함수
  const isValidPhone = (phoneNumber) => {
    const regex = /^01[0-9]-[0-9]{3,4}-[0-9]{4}$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return regex.test(email);
  };

  const updateErrorField = (index, field, value) => {
    const updatedErrors = [...errors];
    updatedErrors[index] = { ...updatedErrors[index], [field]: value };
    setErrors(updatedErrors);
  };

  const isAllEmpty = () => {
    return members.every(
      (member) =>
        !member.name.trim() &&
        !member.englishName.trim() &&
        !member.phoneNumber.trim() &&
        !member.email.trim()
    );
  };

  const getGenderCode = (birth, gender) => {
    const year = parseInt(birth.substring(0, 4));
    const baseCode = year >= 2000 ? 2 : 0;
    return (baseCode + parseInt(gender)).toString();
  };

  // 모달 관련 함수
  const closeModal = () => {
    setShowModal(false);
    sessionStorage.setItem("modalOpen", "false");
  };

  // 가입 처리 함수
  const signUp = async (e) => {
    if (e) {
      e.preventDefault();
    }

    let valid = true;
    const newErrors = members.map((member) => {
      let nameError = "";
      let englishNameError = "";
      let phoneNumberError = "";
      let emailError = "";

      if (!member.name.trim()) {
        nameError = "이름을 입력해주세요.";
        valid = false;
      }

      if (!member.englishName.trim()) {
        englishNameError = "영문 이름을 입력해주세요.";
        valid = false;
      }

      if (!member.phoneNumber.trim()) {
        phoneNumberError = "전화번호를 입력해주세요.";
        valid = false;
      } else if (!isValidPhone(member.phoneNumber)) {
        phoneNumberError = "유효하지 않은 전화번호입니다!";
        valid = false;
      }

      if (!member.email.trim()) {
        emailError = "이메일을 입력해주세요.";
        valid = false;
      } else if (!isValidEmail(member.email)) {
        emailError = "유효하지 않은 이메일 주소입니다.";
        valid = false;
      }

      return {
        name: nameError,
        englishName: englishNameError,
        phoneNumber: phoneNumberError,
        email: emailError,
      };
    });
    setErrors(newErrors);

    if (valid) {
      const companionData = members.map((member, index) => ({
        name: member.name,
        englishName: member.englishName,
        phoneNumber: member.phoneNumber,
        email: member.email,
        dateOfBirth: companionsBirth[index],
        gender: companionsGender[index],
      }));

      const userDataAndCompanionData = {
        ...queryData,
        companionData: companionData,
      };

      dispatch(setMembersData(userDataAndCompanionData));
      setShowModal(true);
    }
  };

  return (
    <>
      <section className="w-full">
        <form className={styles.memberForm}>
          <h3 className={styles.H3_title}>
            보험가입에 필요한 동반인 정보를
            <br /> 제공해주세요
          </h3>
          {members.map((member, index) => (
            <div key={index} className={styles.membersForm}>
              <p className="text-[#386937] font-semibold">동반인 {index + 1}</p>
              <div className={styles.inputWrap}>
                <label className={styles.styledLabel}>이름</label>
                <CustomInput
                  placeholder="이름"
                  maxLength={4}
                  value={member.name}
                  error={errors[index]?.name}
                  onChange={(value) => handleInputChange(index, "name", value)}
                  onClear={() => handleInputClear(index, "name")}
                />
                {errors[index]?.name && (
                  <p style={{ marginTop: "8px", color: "#E86565" }}>
                    {errors[index].name}
                  </p>
                )}
              </div>

              <div className={styles.inputWrap}>
                <label className={styles.styledLabel}>주민번호</label>
                <div className={styles.identifyNum}>
                  <CustomInput
                    placeholder="앞자리"
                    value={companionsBirth[index]?.substring(2) || ""}
                    readOnly
                  />
                  <div>
                    <span>-</span>
                  </div>
                  <CustomInput
                    placeholder="뒷자리"
                    value={
                      companionsBirth[index] && companionsGender[index]
                        ? getGenderCode(
                            companionsBirth[index],
                            companionsGender[index]
                          ) + "******"
                        : "******"
                    }
                    readOnly
                  />
                </div>
              </div>

              <div className={styles.inputWrap}>
                <label className={styles.styledLabel}>
                  영문명 (영문가입 증명서용)
                </label>
                <CustomInput
                  placeholder="(여권과 동일하게 입력 하세요.)"
                  maxLength={16}
                  value={member.englishName}
                  error={errors[index]?.englishName}
                  onChange={(value) => handleEnglishNameChange(value, index)}
                  onClear={() => handleInputClear(index, "englishName")}
                />
                {errors[index]?.englishName && (
                  <p style={{ marginTop: "8px", color: "#E86565" }}>
                    {errors[index].englishName}
                  </p>
                )}
              </div>

              <div className={styles.inputWrap}>
                <label className={styles.styledLabel}>전화번호</label>
                <CustomInput
                  placeholder="전화번호"
                  type="tel"
                  maxLength={13}
                  value={member.phoneNumber}
                  onChange={(value) => handlePhoneNumberChange(value, index)}
                  onClear={() => handleInputClear(index, "phoneNumber")}
                  error={errors[index]?.phoneNumber}
                />
                {errors[index]?.phoneNumber && (
                  <p style={{ marginTop: "8px", color: "#E86565" }}>
                    {errors[index].phoneNumber}
                  </p>
                )}
              </div>

              <div className={styles.inputWrap}>
                <label className={styles.styledLabel}>이메일</label>
                <CustomInput
                  placeholder="이메일"
                  type="email"
                  maxLength={25}
                  value={member.email}
                  onChange={(value) => handleEmailChange(value, index)}
                  onClear={() => handleInputClear(index, "email")}
                  error={errors[index]?.email}
                />
                {errors[index]?.email && (
                  <p style={{ marginTop: "8px", color: "#E86565" }}>
                    {errors[index].email}
                  </p>
                )}
              </div>

              <div className="py-2">
                <div className="w-full h-2 bg-[#DBE5DB] absolute left-0"></div>
              </div>
            </div>
          ))}
        </form>
      </section>
      <div className="py-4">
        <Button
          buttonText="가입하기"
          onClick={signUp}
          disabled={isAllEmpty()}
        />
      </div>
      {showModal && <AgreeTerms isOpen={true} onClose={closeModal} />}
    </>
  );
}

export default CompanionMembers;
