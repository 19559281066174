import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { selectEndDate } from "../../redux/store";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

function EndCalendar({ onClose, faRetrustData }) {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.calendar.selectedStartDate);
  const [selectedDate, setSelectedDate] = useState(null);
  console.log(faRetrustData.endDate);
  useEffect(() => {
    if (faRetrustData && faRetrustData.endDate) {
      const endDate = dayjs(faRetrustData.endDate);
      setSelectedDate(endDate);
      dispatch(selectEndDate(endDate.format("YYYYMMDD")));
    } else if (startDate) {
      setSelectedDate(dayjs(startDate, "YYYYMMDD"));
    }
  }, [faRetrustData, startDate, dispatch]);

  const [minDate, setMinDate] = useState(dayjs().startOf("day"));
  const maxDate = dayjs().add(3, "month").endOf("month").subtract(1, "day");

  const shouldDisableDate = (day) => {
    if (startDate) {
      return dayjs(day).isBefore(dayjs(startDate, "YYYYMMDD"), "day");
    }
    return false;
  };

  const handleApply = () => {
    if (selectedDate) {
      dispatch(selectEndDate(selectedDate.format("YYYYMMDD")));
      onClose(selectedDate.format("YYYYMMDD"));
    }
  };

  const ServerDay = (props) => {
    const { day, outsideCurrentMonth } = props;
    const isStartDate = dayjs(day).isSame(dayjs(startDate, "YYYYMMDD"), "day");

    if (outsideCurrentMonth) {
      return (
        <PickersDay {...props} outsideCurrentMonth={outsideCurrentMonth} />
      );
    }

    return (
      <Badge
        key={day.toString()}
        overlap="rectangular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={
          isStartDate ? (
            <span className="text-[9px] top-[2px] font-bold text-[#fff] relative right-5 tracking-tighter w-[32px] bg-black text-center">
              출발일
            </span>
          ) : undefined
        }
      >
        <PickersDay {...props} outsideCurrentMonth={outsideCurrentMonth} />
      </Badge>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <div className="calendar-container2">
        <DateCalendar
          value={selectedDate}
          onChange={(newDate) => {
            setSelectedDate(newDate);
            dispatch(selectEndDate(newDate.format("YYYYMMDD")));
          }}
          shouldDisableDate={shouldDisableDate}
          showDaysOutsideCurrentMonth={false}
          minDate={minDate}
          maxDate={maxDate}
          views={["month", "day"]}
          className="calendar2"
          slots={{
            day: ServerDay,
          }}
        />
      </div>

      <div className="mt-5 border-t-2 "></div>
      <div className="flex w-[92%] mx-auto justify-between mt-2 items-center mb-4">
        <span className="font-extrabold text-xs ">도착일</span>
        <span className="text-[#386937] text-[13px] font-semibold">
          {selectedDate
            ? selectedDate.format("YYYY년 MM월 DD일")
            : "날짜를 선택하세요"}
        </span>
      </div>
      <div className="py-2">
        <button
          className="w-[100%] mx-auto text-center py-4 rounded-2xl bg-[#386937] text-white font-semibold text-sm"
          onClick={handleApply}
        >
          적용
        </button>
      </div>
    </LocalizationProvider>
  );
}

export default EndCalendar;
