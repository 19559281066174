import React from 'react';
import rightArrow from "../assets/commonRightArrow.svg";
import { useNavigate } from 'react-router-dom';

function Maintenance() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  };

  return (
    <>
      <div className="w-full">
      <div className="flex flex-col gap-2 p-[20px]">
        <div className="">
        <img
            alt="리트러스트Logo"
            src={process.env.PUBLIC_URL + "images/introIMG/REtrust_s.png"}
          />
          </div>
        <div className="flex gap-[4px] items-center" onClick={goBack}>
          <p className='font-bold text-[#1b1e28] text-[16px] ml-2 cursor-pointer'>되돌아가기</p>
          <img src={rightArrow} alt="rightArrow" width="14px"/> 
        </div>
      </div>
        <div className='w-full h-full relative translate-y-[30%]'>
          <div className="flex flex-col justify-center items-center gap-2 px-[20px]">
            <div className="bg-[#ebf0eb] w-[200px] h-[200px] rounded-full flex justify-center items-center mb-4">
              <img src="/images/trip.png"  alt="" className='w-full h-full object-fill' />
            </div>
            <h2 className='text-[26px] mb-1 text-[#386937]'>해당 페이지는 준비중입니다..</h2>
            <p className='text-[16px] text-center text-[#1b1e28] font-medium leading-[20px]'>더 나은 서비스를 제공하기 위해 해당페이지는 <br/> 작업중에 있습니다!</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Maintenance;
