import React, { useEffect, useState } from "react";
import styles from "../../css/Claim/claimDocuments.module.css";
import selectedPreview from "../../assets/selectedPreview.svg";
import claimDeleteBtn from "../../assets/claimDeleteBtn.svg"; // 삭제 버튼 아이콘 추가
import UpLoadPagenation from "./UpLoadPagenation"; // UpLoadPagenation 컴포넌트 불러오기

function ClaimDocumentsUpload({
  previews,
  setTotalFiles,
  setPreviews,
  setImageName,
  collectionData,
}) {
  const [modalOpenIndex, setModalOpenIndex] = useState(null); // 현재 열린 모달의 인덱스를 관리
  const [modalFileType, setModalFileType] = useState(""); // 모달에 출력할 파일 타입 상태
  const [modalImageSrcs, setModalImageSrcs] = useState([]); // 모달에 출력할 이미지 소스 리스트 상태
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // 현재 이미지 인덱스 상태

  const calculateTotalFiles = () => {
    let total = 0;
    previews.forEach((previewList) => {
      total += previewList.length;
    });
    return total;
  };

  useEffect(() => {
    const totalFiles = calculateTotalFiles();
    setTotalFiles(totalFiles);
  }, [previews, setTotalFiles]);

  // 모달 열기 함수
  const handleModalOpen = (fileType, imageSrcs, index, fileIdx) => {
    setModalFileType(fileType);
    setModalImageSrcs(imageSrcs);
    setCurrentImageIndex(fileIdx);
    setModalOpenIndex(index);
  };

  // 모달 닫기 함수
  const handleModalClose = () => {
    setModalOpenIndex(null);
  };

  // 파일 삭제 함수
  const handleFileDelete = (index, fileIndex) => {
    const updatedPreviews = [...previews];
    updatedPreviews[index] = previews[index].filter(
      (_, idx) => idx !== fileIndex
    );
    setPreviews(updatedPreviews);
    setImageName((prevImageName) => {
      const newImageName = [...prevImageName];
      newImageName.splice(fileIndex, 1); // remove the item at fileIndex
      return newImageName;
    });
  };

  const isPropertyOrLiability =
    collectionData?.claim_types === "재물" ||
    collectionData?.claim_types === "배상";

  return (
    <div className={styles.FileToUploadContents}>
      <span className={styles.subtitle}>
        하단의 필요서류 첨부 버튼을 눌러서 (해당서류)를 업로드 해주세요
      </span>

      {isPropertyOrLiability ? (
        <div className={styles.uploadFiles}>
          <span>재물·배상 PDF 업로드</span>
          <div className={styles.previewContents}>
            {previews[0].length === 0
              ? null
              : previews[0].map((preview, fileIdx) => (
                  <div className={styles.previewContentsImages} key={fileIdx}>
                    {fileIdx === 0 && (
                      <div className={styles.empty}>
                        <img
                          src={selectedPreview}
                          alt="전체보기"
                          onClick={() =>
                            handleModalOpen(
                              "재물·배상 PDF 업로드",
                              previews[0],
                              0,
                              fileIdx
                            )
                          }
                        />
                      </div>
                    )}
                    <div className={styles.empty}>
                      <img
                        src={preview}
                        alt="preview"
                        className={styles.previewImage}
                      />
                      <div
                        className={styles.deleteButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFileDelete(0, fileIdx); // 파일 삭제 핸들러 호출
                        }}
                      >
                        <img src={claimDeleteBtn} alt="delete" />
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ) : (
        ["진료비 계산 영수증", "진료비 세부내역서", "진단서"].map(
          (title, idx) => (
            <div className={styles.uploadFiles} key={idx}>
              <span>{title}</span>
              <div className={styles.previewContents}>
                {previews[idx].length === 0
                  ? null
                  : previews[idx].map((preview, fileIdx) => (
                      <div
                        className={styles.previewContentsImages}
                        key={fileIdx}
                      >
                        {fileIdx === 0 && (
                          <div className={styles.empty}>
                            <img
                              src={selectedPreview}
                              alt="전체보기"
                              onClick={() =>
                                handleModalOpen(
                                  title,
                                  previews[idx],
                                  idx,
                                  fileIdx
                                )
                              }
                            />
                          </div>
                        )}
                        <div className={styles.empty}>
                          <img
                            src={preview}
                            alt="preview"
                            className={styles.previewImage}
                          />
                          <div
                            className={styles.deleteButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFileDelete(idx, fileIdx); // 파일 삭제 핸들러 호출
                            }}
                          >
                            <img src={claimDeleteBtn} alt="delete" />
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )
        )
      )}
      {modalOpenIndex !== null && (
        <UpLoadPagenation
          onClose={handleModalClose}
          fileType={modalFileType}
          imageSrcs={modalImageSrcs}
          currentImageIndex={currentImageIndex}
          onDelete={(fileIndex) => handleFileDelete(modalOpenIndex, fileIndex)} // onDelete prop 전달
        />
      )}
    </div>
  );
}

export default ClaimDocumentsUpload;
