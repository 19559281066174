import React, { useState } from "react";
import styles from "../../css/Claim/claimDocuments.module.css";
import UploadFileModal from "../../components/modal/UploadFileModal";
import ClaimDocumentsUpload from "../../components/claimComponents/ClaimDocumentsUpload";
import ClaimDocAccordion from "../../components/claimComponents/ClaimDocAccodian";
import { useLocation } from "react-router-dom";
import SucceedModal from "../../components/modal/SucceedModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Loading from "../../components/modal/Loading";
import ClaimSubHeaders from "../../components/claimComponents/ClaimSubHeaders";

function ClaimDocuments() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previews, setPreviews] = useState([[], [], []]); // 미리보기 이미지 상태
  const [isSucceedModalOpen, setIsSucceedModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const collectionData = location.state;
  const [imageName, setImageName] = useState([]);
  const [totalFiles, setTotalFiles] = useState("");
  const [arrayResultData, setArrayResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendDocuments = async () => {
    if (totalFiles === 0) {
      alert("첨부된 파일이 없습니다. 필요서류를 먼저 첨부해주세요.");
      return;
    }
    if (collectionData.claimTypeKey === '3' || collectionData.claimTypeKey === '4') {

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const emailData = {
        method: "claim",
        claim_type: collectionData.claimTypeKey,
        attachments: imageName
      }
      const raw = JSON.stringify(emailData);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/member-api/claimMRZ`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`서버 오류: ${response.status}`);
        }

        const result = await response.json();

        if (result.errCd === "00001") {
          setArrayResultData(result);
          setIsSucceedModalOpen(true); //  모달 열기
        } else {
          let errorMessage = "서류 전송에 실패했습니다.";
          if (result.errMsg) {
            errorMessage += ` (${result.errMsg})`;
          }
          setErrorMessage(errorMessage);
          setIsErrorModalOpen(true);
        }
      } catch (error) {
        console.error("오류", error);
        setErrorMessage(
          "서류 전송 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요."
        );
        setIsErrorModalOpen(true); // 에러 모달 열기
      } finally {
        setIsLoading(false);
      }
      return
    }
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const claimData = {
      encryptedKey: collectionData.encryptedKey,
      iv: collectionData.iv,
      encryptedData: collectionData.encryptedData,
      accountEncryptedKey: collectionData.accountEncryptedKey,
      accountIv: collectionData.accountIv,
      accountEncryptedData: collectionData.accountEncryptedData,
      submission_type: collectionData.claimType,
      claim_type: collectionData.claimTypeKey,
      accident_cause_cd: collectionData.accidentDateKey,
      accident_date: collectionData.accidentDate,
      accident_details: collectionData.text,
      submitter_name: collectionData.selectedName,
      contect_channel_cd: "23",
      area_telecom_cd: collectionData.arCcoCd1,
      phone_exchange_cd: collectionData.telofNo1,
      phone_serial_cd: collectionData.telSno1,
      bank_cd: collectionData.selectedBankCd,
      bank_name: collectionData.selectedBank,
      depositor_relation_cd: collectionData.relationCd,
      depositorEncryptedKey: collectionData?.depositorEncryptedKey || null,
      depositorIv: collectionData?.depositorIv || null,
      depositorEncryptedData: collectionData?.depositorEncryptedData || null,
      depositor_name: collectionData.accountHolderName,
      file_list: imageName,
      file_quatity: totalFiles,
    };

    const raw = JSON.stringify(claimData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/trip-api/accident`, //accident 경로변경
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`서버 오류: ${response.status}`);
      }

      const result = await response.json();

      if (result.errCd === "00001") {
        setArrayResultData(result);
        setIsSucceedModalOpen(true); //  모달 열기
      } else {
        let errorMessage = "서류 전송에 실패했습니다.";
        if (result.errMsg) {
          errorMessage += ` (${result.errMsg})`;
        }
        setErrorMessage(errorMessage);
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error("오류", error);
      setErrorMessage(
        "서류 전송 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요."
      );
      setIsErrorModalOpen(true); // 에러 모달 열기
    } finally {
      setIsLoading(false);
    }
  };

  // 모달 열기
  const openModal = () => {
    setIsModalOpen(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 파일 업로드 후 미리보기 이미지 업데이트
  const handleFileUpload = (newPreviews) => {
    setPreviews(newPreviews);
  };

  const closeSucceedModal = () => {
    setIsSucceedModalOpen(false);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <ClaimSubHeaders titleText="필요서류 전송" />
      <div className={styles.Contents}>
        <div className={styles.ContentsWrap}>
          <h3>
            보험금 청구에 <br /> 필요한 서류를 전송해주세요.
          </h3>
          {/* 아코디언 섹션 */}
          <ClaimDocAccordion />
        </div>
        <div className={styles.Boundary}></div>
        {/* 첨부서류 확인 섹션 */}
        <section className="section3">
          <div className={styles.sectionWrap3}>
            <p> 첨부서류 확인</p>
            <ClaimDocumentsUpload
              previews={previews}
              setTotalFiles={setTotalFiles}
              setPreviews={setPreviews} // 추가
              setImageName={setImageName} // 추가
              collectionData={collectionData}
            />
            {/* 첨부서류 확인 버튼 */}
            <div className={styles.btnFlex}>
              <button className={styles.firstBtn} onClick={openModal}>
                필요서류 첨부
              </button>
              <button
                className={styles.secondBtn}
                onClick={handleSendDocuments}
                style={{ opacity: totalFiles === 0 ? 0.4 : 1 }}
                disabled={totalFiles === 0}
              >
                서류 전송하기
              </button>
            </div>
          </div>
        </section>
      </div>
      {/* 파일 업로드 모달 */}
      {isModalOpen && (
        <UploadFileModal
          closeModal={closeModal}
          previews={previews}
          setPreviews={handleFileUpload}
          collectionData={collectionData}
          setImageName={setImageName}
          imageName={imageName}
        />
      )}
      {/* 성공 모달 */}
      {isSucceedModalOpen && (
        <SucceedModal
          arrayResultData={arrayResultData}
          onClose={closeSucceedModal}
          message="보험금 청구"
        />
      )}
      {/* 에러 모달 */}
      {isErrorModalOpen && (
        <ErrorModal message={errorMessage} onClose={closeErrorModal} />
      )}
      {isLoading && <Loading />}
    </>
  );
}

export default ClaimDocuments;
