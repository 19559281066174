import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { generatePDF } from "../calculationProcessPart/InsurancePDFTemplate";

const EstimateButton = ({
  startDate,
  endDate,
  selectedCountry,
  userGender,
  userDateOfBirth,
  companions,
  koreanName,
  englishName,
  email,
  phoneNumber,
  isFileUploaded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const requestMadeRef = useRef(false);
  const pdfGeneratedRef = useRef(false);

  // 캐시 키 생성
  const cacheKey = useMemo(() => {
    return `priceData_${startDate}_${endDate}_${selectedCountry?.cityNatlCd}_${userGender}_${userDateOfBirth}_${companions.length}`;
  }, [
    startDate,
    endDate,
    selectedCountry,
    userGender,
    userDateOfBirth,
    companions,
  ]);

  // 모든 필수 데이터가 있는지 확인
  const hasAllRequiredData = useMemo(() => {
    const isValid = !!(
      startDate &&
      endDate &&
      selectedCountry &&
      userGender &&
      userDateOfBirth &&
      koreanName &&
      englishName &&
      email &&
      phoneNumber &&
      isFileUploaded
    );
    return isValid;
  }, [
    startDate,
    endDate,
    selectedCountry,
    userGender,
    userDateOfBirth,
    koreanName,
    englishName,
    email,
    phoneNumber,
    isFileUploaded,
  ]);

  // PDF 생성 함수 단순화
  const createInsurancePDF = useCallback(
    async (data) => {
      if (!data || pdfGeneratedRef.current) return;

      setIsLoading(true);
      try {
        await generatePDF({
          priceData: data,
          startDate,
          endDate,
          selectedCountry,
          koreanName,
          englishName,
          email,
          phoneNumber,
        });

        pdfGeneratedRef.current = true;
      } catch (error) {
        console.error("PDF 생성 중 오류:", error);
        setErrorMessage("PDF 생성 중 오류가 발생했습니다.");
        pdfGeneratedRef.current = false;
      } finally {
        setIsLoading(false);
      }
    },
    [
      startDate,
      endDate,
      selectedCountry,
      koreanName,
      englishName,
      email,
      phoneNumber,
    ]
  );

  const fetchPriceData = useCallback(async () => {
    if (!isFileUploaded || !hasAllRequiredData || requestMadeRef.current) {
      return;
    }

    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
   
      await createInsurancePDF(parsedData);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    requestMadeRef.current = true;

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const inspeInfos = [
        {
          inspeNm: "계약자",
          inspeBdt: userDateOfBirth,
          gndrCd: userGender,
        },
        ...companions.map((companion, index) => ({
          inspeNm: `동반${index + 1}${companion.gender === "2" ? "여" : ""}`,
          inspeBdt: companion.dateOfBirth,
          gndrCd: companion.gender,
        })),
      ];

      const requestBody = {
        insBgnDt: startDate.replace(/-/g, ""),
        insEdDt: endDate.replace(/-/g, ""),
        natlCd: selectedCountry.cityNatlCd,
        inspeCnt: String(inspeInfos.length),
        inspeInfos: inspeInfos,
      };

      const response = await fetch(
        "https://insudev.retrust.world/trip-api/price",
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(requestBody),
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
     
      sessionStorage.setItem(cacheKey, JSON.stringify(result));
      await createInsurancePDF(result);
    } catch (error) {
      console.error("API 요청 중 오류:", error);
      setErrorMessage("견적 조회 중 오류가 발생했습니다.");
   
      requestMadeRef.current = false;
    } finally {
      setIsLoading(false);
    }
  }, [
    isFileUploaded,
    hasAllRequiredData,
    startDate,
    endDate,
    selectedCountry,
    userGender,
    userDateOfBirth,
    companions,
    cacheKey,
    createInsurancePDF,
  ]);

  useEffect(() => {
    if (isFileUploaded && hasAllRequiredData && !requestMadeRef.current) {
      fetchPriceData();
    }
  }, [isFileUploaded, hasAllRequiredData, fetchPriceData]);

  return (
    <div className="flex flex-col items-center gap-4 mt-8">
      {isLoading && (
        <div className="flex items-center gap-2 text-blue-600">
          <svg
            className="animate-spin h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span>견적서 생성중...</span>
        </div>
      )}
      {errorMessage && (
        <div className="text-red-500 text-sm font-medium bg-red-50 px-4 py-2 rounded-lg">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default EstimateButton;
