import Button from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import ClaimDocAccordion from "../../components/claimComponents/ClaimDocAccodian";
import styles from "../../css/Claim/claimMemberIntro.module.css";
import ClaimSubHeaders from "../../components/claimComponents/ClaimSubHeaders";


function ClaimMemberIntro() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/claimMembersInfo");
  };

  return (
    <>
      <ClaimSubHeaders titleText="청구 전 확인사항" />
      <div className={styles.container}>
        <div className={styles.containerWrap}>
          <ClaimDocAccordion />
        </div>
      </div>
      <Button buttonText="확인" onClick={handleClick} />
    </>
  );
}

export default ClaimMemberIntro;
