import CustomInput from "../../components/common/CustomInput";
import styles from "../../css/Claim/claimMembers.module.css";
import { useState } from "react";

function Auth() {
  const [error, setError] = useState({
    name: "",
    secretNumber: "",
    phoneNumber: "",
  });
  const [name, setName] = useState("");
  const [BirthFirstPart, setFirstPart] = useState("");
  const [BirthsecondPart, setSecondPart] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <div className={styles.section}>
      <div className={styles.sectionWrap}>
        <h2 className="py-4">휴대폰 인증을 진행해주세요</h2>
        <div className={styles.formWrap}>
          <div className={styles.inputWrap}>
            <label className="">이름</label>
            <CustomInput
              placeholder="이름"
              maxLength={4}
              value={name}
              error={error.name}
              onChange={(value) => {
                setName(value);
                setError({ ...error, name: "" });
              }}
              onClear={() => {
                setName("");
              }}
            />
            {error.name && <p style={{ color: "#E86565" }}>{error.name}</p>}
          </div>
          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>주민번호</label>
            <div className={styles.identifyNum}>
              <CustomInput
                placeholder="앞자리"
                error={error.secretNumber}
                type="tel"
                maxLength={6}
                value={BirthFirstPart}
                onChange={(value) => {
                  setFirstPart(value);
                  setError({ ...error, secretNumber: "" });
                }}
                onClear={() => {
                  setFirstPart("");
                }}
              />
              <div>
                <span className="mx-1">-</span>
              </div>
              <CustomInput
                placeholder="뒷자리"
                maxLength={7}
                type="tel"
                error={error.secretNumber}
                value={BirthsecondPart}
                onChange={(value) => {
                  setSecondPart(value);
                  setError({ ...error, secretNumber: "" });
                }}
                onClear={() => {
                  setSecondPart("");
                }}
              />
            </div>
            {error.secretNumber && (
              <p style={{ color: "#E86565" }}>{error.secretNumber}</p>
            )}
          </div>

          <div className={styles.inputWrap}>
            <label className={styles.styledLabel}>휴대폰 번호</label>
            <div className="flex gap-1">
              <CustomInput
                placeholder="'-'빼고 입력해주세요."
                type="tel"
                maxLength={13}
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value);
                  setError({ ...error, phoneNumber: "" });
                }}
                onClear={() => {
                  setPhoneNumber("");
                }}
                error={error.phoneNumber}
              />
              {error.phoneNumber && (
                <p style={{ color: "#E86565" }}>{error.phoneNumber}</p>
              )}
              <div className="basis-[50%]">
                <CustomInput placeholder="통신사" />
              </div>
            </div>
            <label>인증번호</label>
            <CustomInput />
          </div>
        </div>
      </div>
      <div className="boundary"></div>
      <div className="">
        <div className="">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Auth;
