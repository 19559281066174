import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../../css/Claim/claimConfirm.module.css";
import commonDownArrow from "../../assets/commonDownArrow.svg";
import claimConfirmWound from "../../assets/claimConfirmWound.svg";
import commonUpArrow from "../../assets/commonUpArrow.svg";
import commonRightBig from "../../assets/commonRightBig.svg";
import ClaimConfirmEdit from "../../components/modal/ClaimConfirmEdit";
import ConfirmTable from "../../components/claimComponents/ConfirmTable";
import Loading from "../../components/modal/Loading";
import SecureKeyboard from "../../components/common/SecureKeyboard";
import CreatePostData from "../../api/CreatePostData";
import usePublicKey from "../../api/PublicGetApi";
import ErrorModal from "../../components/modal/ErrorModal";
import ClaimUtilsApi from "../../api/ClaimUtilsApi";
import ClaimSubHeaders from "../../components/claimComponents/ClaimSubHeaders";
import ClaimConfirmGuide from "../../components/modal/ClaimConfirmGuide";

const ClaimConfirm = () => {
  const [isOpenIndex, setIsOpenIndex] = useState([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [secretValue, setSecretValue] = useState("");
  const [error, setError] = useState("");
  const publicKey = usePublicKey();
  const token = useSelector((state) => state.cookie.cookie);
  const userBirth = token?.birth || "";
  const sliceUserBirth = userBirth.slice(2);
  const navigate = useNavigate();

  const claimDetails = [
    { label: "사고번호", key: "acdNo" },
    { label: "사고일자", key: "acdDt", isDate: true },
    { label: "피보험자", key: "inspePolhdNm" },
  ];

  ClaimUtilsApi();

  useEffect(() => {
    const fetchClaimList = async (encryptedData) => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          encryptedData: encryptedData.encryptedData,
          encryptedKey: encryptedData.encryptedKey,
          iv: encryptedData.iv,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/trip-api/claimlist`,
          requestOptions
        );

        const result = await response.json();
        if (result.errCd !== "00001") {
          setError(result.errMsg);
        } else if (!response.ok) {
          setError("네트워크 서버가 불안정 합니다.");
        }

        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
        setIsLoading(false);
      }
    };

    if (secretValue && publicKey) {
      const encryptData = async () => {
        try {
          const fullSecretValue = sliceUserBirth + secretValue;
          const encryptedData = await CreatePostData(
            fullSecretValue,
            publicKey
          );
          console.log("암호화된 주민등록번호:", encryptedData);
          const loadingTimeout = setTimeout(() => {
            setIsLoading(true);
          }, 1000);

          await fetchClaimList(encryptedData);

          clearTimeout(loadingTimeout);
        } catch (error) {
          setError("오류가 발생하였습니다.");
          setIsLoading(false);
        }
      };

      encryptData();
    } else {
      setIsLoading(false);
    }
  }, [secretValue, publicKey, sliceUserBirth]);

  const getBackgroundColor = (progStatNm) => {
    switch (progStatNm) {
      case "청구서류미접수":
        return "#E4BD26";
      case "접수":
        return "#55BAD1";
      case "종결":
        return "#B8B9BC";
      default:
        return "transparent";
    }
  };

  const formatDate = (date) => {
    if (!date || date.length !== 8) return date;
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    return `${year}년 ${month}월 ${day}일`;
  };

  const handleCloseEditModal = () => setIsOpenEditModal(false);

  const onClose = () => {
    setError("");
    setIsOpenEditModal(false);
    navigate("/claimMain");
  };

  const activeAccordion = (index) => {
    setIsOpenIndex((prevIndexState) => {
      const newState = [...prevIndexState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <>
      <ClaimSubHeaders titleText="청구현황 확인" />
      <div className={styles.section}>
        <div className={styles.sectionWrap}>
          <div
            className={`${styles.apiContents} ${
              !secretValue ? styles.apiContentsWithMargin : ""
            }`}
          >
            {!secretValue ? (
              <div className={styles.apiContentsFlexCol}>
                <ul>
                  <li>청구현황 확인을 위해</li>
                  <li>
                    <p className={styles.highlight}>주민등록번호 뒷자리</p>
                    <p className={styles.text}>를</p>
                  </li>
                  <li> 입력해주세요.</li>
                </ul>
                <p className={styles.desc}>
                  🧳입력하신 정보는 사용 후 저장하지 않습니다
                  <br />
                </p>
                <SecureKeyboard onChange={setSecretValue} />
              </div>
            ) : (
              <>
                {isLoading ? (
                  <Loading />
                ) : error ? (
                  <ErrorModal message={error} onClose={onClose} />
                ) : (
                  <div className={styles.dataContents}>
                    {data &&
                      data.comsPcsInqRslMSGBcVo &&
                      data.comsPcsInqRslMSGBcVo.map((item, index) => {
                        const clmId = item ? item.clmId : undefined;
                        return (
                          <div className={styles.dataContentsWrap} key={index}>
                            <div className={styles.dataContentsTitle}>
                              <div className={styles.dataContentsTitleImage}>
                                <img
                                  src={claimConfirmWound}
                                  alt="claimConfirmWound"
                                />
                                <p>{item.clmTpNm}</p>
                              </div>
                              <span
                                style={{
                                  backgroundColor: getBackgroundColor(
                                    item.progStatNm
                                  ),
                                }}
                              >
                                {item.progStatNm}
                              </span>
                            </div>
                            <div className={styles.boundaryLine}></div>
                            <div className={styles.dataInformationWrap}>
                              <ul>
                                {claimDetails.map((detail, idx) => (
                                  <li key={idx}>
                                    <span>{detail.label}</span>
                                    <p>
                                      {detail.isDate
                                        ? formatDate(item[detail.key])
                                        : item[detail.key] || ""}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className={styles.moreBtnWrap}>
                              <div onClick={() => activeAccordion(index)}>
                                {isOpenIndex[index] && (
                                  <div className={styles.accordionContent}>
                                    <div className={styles.acoordionTitle}>
                                      <p>지급내역</p>
                                      <div
                                        className={styles.acoordionTitleBtnWrap}
                                      >
                                        <button className="cursor-pointer">
                                          <Link
                                            to={`/claimPaymentStatement/${clmId}`}
                                          >
                                            지급내역서 발급
                                          </Link>
                                        </button>
                                        <img
                                          src={commonRightBig}
                                          alt="RightBig"
                                        />
                                      </div>
                                    </div>
                                    <ConfirmTable />
                                  </div>
                                )}
                              </div>
                              {clmId && (
                                <div
                                  className={styles.moreBtn}
                                  onClick={() => activeAccordion(index)}
                                >
                                  <span>
                                    {isOpenIndex[index] ? "접기" : "더보기"}
                                  </span>
                                  <img
                                    src={
                                      isOpenIndex[index]
                                        ? commonUpArrow
                                        : commonDownArrow
                                    }
                                    alt={
                                      isOpenIndex[index]
                                        ? "upArrow"
                                        : "downArrow"
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {secretValue && !isLoading && !error && <ClaimConfirmGuide />}
      </div>
      {isOpenEditModal && (
        <ClaimConfirmEdit handleClose={handleCloseEditModal} />
      )}
    </>
  );
};

export default ClaimConfirm;
