import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/store"; // login 액션을 올바르게 가져옴
import claimintroClip from "../../assets/claimintroClip.svg";
import styles from "../../css/Claim/claimIntro.module.css";
import QRCodeModal from '../modal/QRCodeModal';

function ClipLogin() {
  const location = useLocation(); // useLocation 훅 사용
  const dispatch = useDispatch(); // useDispatch 훅 추가
  const [showModal, setShowModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const checkLoginStatus = async (request_id) => {
    try {
      const addressResponse = await fetch(
        `/auth/klip_login?requestId=${request_id}`,
        {
          method: "GET",
        }
      );
      const addressResult = await addressResponse.json();
      if (addressResult.success) {
        dispatch(login(JSON.stringify(addressResult)));
        setShowModal(false);

        const queryParams = new URLSearchParams(location.search);
        const redirectPath = queryParams.get("redirect") || "/claimMain";
        console.log("이전 경로:", redirectPath);
        window.location.href = redirectPath;
      }
    } catch (error) {
      console.error("Error checking login status:", error);
      setError("로그인 상태 확인 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleNFTReceive = async () => {
    setError(null);
    try {
      const response = await fetch("/auth/klip", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log(result);

      if (response.ok) {
        const { request_id, deep_link } = result.data;
        if (isMobile) {
          window.location.href = deep_link;
        } else {
          setQrCodeData(deep_link);
          setShowModal(true);
        }
        checkLoginStatus(request_id);
      } else {
        throw new Error("Failed to initiate Klip login");
      }
    } catch (error) {
      console.error("Error in handleNFTReceive:", error);
      setError("로그인을 시작하는 데 문제가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  return (
    <>
      <div className={styles.ClipLoginBtn} onClick={handleNFTReceive}>
        <img src={claimintroClip} alt="클립 로그인" />
        <p>클립으로 로그인</p>
      </div>
      {error && <p className={styles.errorMessage} role="alert">{error}</p>}
      {showModal && (
        <QRCodeModal
          onClose={() => setShowModal(false)}
          qrCodeData={qrCodeData}
        />
      )}
    </>
  );
}

export default ClipLogin;
