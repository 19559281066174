import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";

// 폰트 파일 import
import NotoSansRegular from "../../assets/fonts/NotoSansKR-Regular.ttf";
import NotoSansSemiBold from "../../assets/fonts/NotoSansKR-SemiBold.ttf";
import NotoSansBold from "../../assets/fonts/NotoSansKR-Bold.ttf";
import NotoSansMedium from "../../assets/fonts/NotoSansKR-Medium.ttf";

// 폰트 등록
Font.register({
  family: "Noto Sans KR",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: NotoSansMedium,
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: NotoSansSemiBold,
      fontWeight: 600,
      fontStyle: "normal",
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  // 페이지 기본 스타일
  page: {
    padding: "10mm",
    fontFamily: "Noto Sans KR",
    backgroundColor: "white",
  },

  // 헤더 섹션
  header: {
    marginBottom: "12mm",
  },

  title: {
    fontSize: 24,
    fontWeight: 700,
    color: "#111827",
    paddingBottom: "5mm",
    borderBottom: "2pt solid #111827",
    marginBottom: "8mm",
    fontFamily: "Noto Sans KR",
  },

  // 정보 그리드 레이아웃
  infoGrid: {
    flexDirection: "row",
    gap: "12mm",
  },

  infoColumn: {
    flex: 1,
  },

  infoRow: {
    flexDirection: "row",
    marginBottom: "5mm",
    alignItems: "center",
  },

  label: {
    width: "25mm",
    color: "#4B5563",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Noto Sans KR",
  },

  value: {
    flex: 1,
    color: "#111827",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Noto Sans KR",
  },

  // 보험료 섹션
  premiumSection: {
    marginBottom: "10mm",
  },

  sectionTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#1F2937",
    marginBottom: "5mm",
    fontFamily: "Noto Sans KR",
  },

  planGrid: {
    flexDirection: "row",
    gap: "4mm",
  },

  planCard: {
    flex: 1,
    border: "1pt solid #2563EB",
    borderRadius: "2mm",
    padding: "5mm 6mm",
  },

  planName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#1F2937",
    marginBottom: "2mm",
    fontFamily: "Noto Sans KR",
  },

  planPrice: {
    fontSize: 20,
    fontWeight: 700,
    color: "#1F2937",
    fontFamily: "Noto Sans KR",
  },

  planUnit: {
    fontSize: 14,
    fontWeight: 400,
    color: "#4B5563",
    marginLeft: "1mm",
    fontFamily: "Noto Sans KR",
  },

  // 기본 테이블 스타일
  table: {
    width: "100%",
    marginTop: "4mm",
  },

  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#F0F9FF",
    padding: "3mm 4mm",
    borderBottom: "1pt solid #E5E7EB",
  },

  tableHeaderCell: {
    flex: 1,
    fontSize: 12,
    fontWeight: 600,
    color: "#374151",
    fontFamily: "Noto Sans KR",
  },

  tableHeaderCellRight: {
    flex: 1,
    fontSize: 12,
    fontWeight: 600,
    color: "#374151",
    textAlign: "right",
    fontFamily: "Noto Sans KR",
  },

  tableRow: {
    flexDirection: "row",
    padding: "3mm 4mm",
    borderBottom: "1pt solid #E5E7EB",
  },

  tableRowEven: {
    backgroundColor: "#F9FAFB",
  },

  tableRowOdd: {
    backgroundColor: "white",
  },

  tableCell: {
    flex: 1,
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Noto Sans KR",
  },

  tableCellRight: {
    flex: 1,
    fontSize: 12,
    fontWeight: 400,
    textAlign: "right",
    fontFamily: "Noto Sans KR",
  },

  // 보장내역 테이블 스타일
  coverageTable: {
    marginTop: "5mm",
    border: "1pt solid #E5E7EB",
    borderRadius: "2mm",
  },

  coverageTableHeader: {
    flexDirection: "row",
    backgroundColor: "#F0F9FF",
    padding: "3mm 3mm",
    borderBottom: "1pt solid #E5E7EB",
    minHeight: "10mm",
  },

  coverageHeaderCell: {
    width: "60%", // 50%에서 60%로 증가
    fontSize: 11,
    fontWeight: 600,
    color: "#374151",
    fontFamily: "Noto Sans KR",
    paddingRight: "2mm",
  },

  coverageHeaderCellAmount: {
    width: "13.33%", // 나머지 40%를 3등분
    fontSize: 11,
    fontWeight: 600,
    color: "#374151",
    textAlign: "right",
    fontFamily: "Noto Sans KR",
  },

  coverageTableRow: {
    flexDirection: "row",
    padding: "2.5mm 3mm",
    borderBottom: "1pt solid #E5E7EB",
    minHeight: "10mm",
    alignItems: "center",
  },

  coverageTableRowEven: {
    backgroundColor: "#F9FAFB",
  },

  coverageTableRowOdd: {
    backgroundColor: "white",
  },

  coverageNameCell: {
    width: "60%", // 50%에서 60%로 증가
    fontSize: 11,
    fontWeight: 400,
    fontFamily: "Noto Sans KR",
    paddingRight: "2mm",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    lineHeight: 1.4,
  },

  coverageAmountCell: {
    width: "13.33%", // 나머지 40%를 3등분
    fontSize: 11,
    fontWeight: 400,
    textAlign: "right",
    fontFamily: "Noto Sans KR",
  },

  // 하단 텍스트 스타일
  footnote: {
    fontSize: 10,
    color: "#6B7280",
    marginTop: "5mm",
    lineHeight: 1.4,
    fontWeight: 400,
    fontFamily: "Noto Sans KR",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: "15mm",
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#6B7280",
    fontFamily: "Noto Sans KR",
  },
});

const InsurancePDFDocument = ({
  priceData,
  startDate,
  endDate,
  selectedCountry,
  koreanName,
  englishName,
  email,
  phoneNumber,
}) => {
  if (!priceData) return null;

  const formatNumber = (number) =>
    new Intl.NumberFormat("ko-KR").format(number);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);
    return `${year}년 ${month}월 ${day}일`;
  };

  const formatAmount = (amount) => {
    if (!amount) return "0원";
    if (amount >= 100000000) return `${amount / 100000000}억원`;
    if (amount >= 10000) return `${amount / 10000}만원`;
    return `${formatNumber(amount)}원`;
  };

  const displayCountry =
    typeof selectedCountry === "object"
      ? selectedCountry.korNatlNm || "미지정"
      : selectedCountry || "미지정";

  const plans = {
    BASIC: { name: "메리츠 알뜰 플랜" },
    RECOMMEND: { name: "메리츠 추천 플랜" },
    PREMIUM: { name: "메리츠 고급 플랜" },
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>여행자보험 견적서</Text>
          <View style={styles.infoGrid}>
            <View style={styles.infoColumn}>
              <View style={styles.infoRow}>
                <Text style={styles.label}>견적일자</Text>
                <Text style={styles.value}>
                  {new Date().toLocaleDateString("ko-KR")}
                </Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.label}>여행기간</Text>
                <Text style={styles.value}>
                  {formatDate(startDate)} ~ {formatDate(endDate)}
                </Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.label}>여행지역</Text>
                <Text style={styles.value}>{displayCountry}</Text>
              </View>
            </View>
            <View style={styles.infoColumn}>
              <View style={styles.infoRow}>
                <Text style={styles.label}>계약자명</Text>
                <Text style={styles.value}>{koreanName}</Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.label}>영문이름</Text>
                <Text style={styles.value}>{englishName}</Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.label}>연락처</Text>
                <Text style={styles.value}>{phoneNumber}</Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.label}>이메일</Text>
                <Text style={styles.value}>{email}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.premiumSection}>
          <Text style={styles.sectionTitle}>총 보험료</Text>
          <View style={styles.planGrid}>
            {Object.entries(plans).map(([key, plan]) => (
              <View key={key} style={styles.planCard}>
                <Text style={styles.planName}>{plan.name}</Text>
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                  <Text style={styles.planPrice}>
                    {formatNumber(priceData[key].ttPrem)}
                  </Text>
                  <Text style={styles.planUnit}>원</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View>
          <Text style={styles.sectionTitle}>가입자별 보험료</Text>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableHeaderCell}>가입자</Text>
              <Text style={styles.tableHeaderCellRight}>알뜰 플랜</Text>
              <Text style={styles.tableHeaderCellRight}>추천 플랜</Text>
              <Text style={styles.tableHeaderCellRight}>고급 플랜</Text>
            </View>
            {priceData.BASIC.opapiGnrCoprCtrInspeInfCbcVo.map((person, idx) => (
              <View
                key={idx}
                style={[
                  styles.tableRow,
                  idx % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd,
                ]}
              >
                <Text style={styles.tableCell}>{person.cusNm}</Text>
                <Text style={styles.tableCellRight}>
                  {formatNumber(person.ppsPrem)}원
                </Text>
                <Text style={styles.tableCellRight}>
                  {formatNumber(
                    priceData.RECOMMEND.opapiGnrCoprCtrInspeInfCbcVo[idx]
                      .ppsPrem
                  )}
                  원
                </Text>
                <Text style={styles.tableCellRight}>
                  {formatNumber(
                    priceData.PREMIUM.opapiGnrCoprCtrInspeInfCbcVo[idx].ppsPrem
                  )}
                  원
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>

      {priceData.BASIC.opapiGnrCoprCtrInspeInfCbcVo.map((person, personIdx) => (
        <Page key={`coverage-${personIdx}`} size="A4" style={styles.page}>
          <Text style={styles.sectionTitle}>
            보장내역 상세 - {person.cusNm}
            <Text
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#6B7280",
                fontFamily: "Noto Sans KR",
              }}
            ></Text>
          </Text>

          <View style={styles.coverageTable}>
            <View style={styles.coverageTableHeader}>
              <Text style={styles.coverageHeaderCell}>보장명</Text>
              <Text style={styles.coverageHeaderCellAmount}>알뜰플랜</Text>
              <Text style={styles.coverageHeaderCellAmount}>추천플랜</Text>
              <Text style={styles.coverageHeaderCellAmount}>고급플랜</Text>
            </View>

            {person.opapiGnrCoprCtrQuotCovInfCbcVo.map((coverage, covIdx) => {
              const recommendCoverage =
                priceData.RECOMMEND.opapiGnrCoprCtrInspeInfCbcVo[
                  personIdx
                ].opapiGnrCoprCtrQuotCovInfCbcVo.find(
                  (cov) => cov.covCd === coverage.covCd
                );
              const premiumCoverage =
                priceData.PREMIUM.opapiGnrCoprCtrInspeInfCbcVo[
                  personIdx
                ].opapiGnrCoprCtrQuotCovInfCbcVo.find(
                  (cov) => cov.covCd === coverage.covCd
                );

              return (
                <View
                  key={covIdx}
                  style={[
                    styles.coverageTableRow,
                    covIdx % 2 === 0
                      ? styles.coverageTableRowEven
                      : styles.coverageTable
                      ? styles.coverageTableRowEven
                      : styles.coverageTableRowOdd,
                  ]}
                >
                  <Text style={styles.coverageNameCell}>{coverage.covNm}</Text>
                  <Text style={styles.coverageAmountCell}>
                    {formatAmount(coverage.insdAmt)}
                  </Text>
                  <Text style={styles.coverageAmountCell}>
                    {formatAmount(recommendCoverage?.insdAmt)}
                  </Text>
                  <Text style={styles.coverageAmountCell}>
                    {formatAmount(premiumCoverage?.insdAmt)}
                  </Text>
                </View>
              );
            })}
          </View>

          <Text style={styles.footnote}>
            ※ 보상한도가 (본 실제외, 1만원) 등으로 표시된 항목은 자기부담금이
            적용됩니다.{"\n"}※ 보상한도가 20만원으로 표시된 항목은 보험기간 중
            1회에 한하여 보상됩니다.
          </Text>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      ))}
    </Document>
  );
};

// PDF 생성 함수
export const generatePDF = async (props) => {
  try {
    // 폰트 로딩 상태 체크를 위한 프로미스
    const loadFonts = Promise.all([
      Font.load({ fontFamily: "Noto Sans KR", fontWeight: 400 }),
      Font.load({ fontFamily: "Noto Sans KR", fontWeight: 500 }),
      Font.load({ fontFamily: "Noto Sans KR", fontWeight: 600 }),
      Font.load({ fontFamily: "Noto Sans KR", fontWeight: 700 }),
    ]);

    // 폰트가 모두 로드될 때까지 대기
    await loadFonts;

    // PDF 생성
    const blob = await pdf(<InsurancePDFDocument {...props} />).toBlob();
    const url = URL.createObjectURL(blob);

    // 다운로드 링크 생성 및 클릭
    const link = document.createElement("a");
    link.href = url;
    link.download = `인슈어트러스트_여행자보험_견적서_${new Date().getTime()}.pdf`;
    document.body.appendChild(link);
    link.click();

    // 클린업
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);

    return true;
  } catch (error) {
    console.error("PDF 생성 중 오류:", error);
    throw error;
  }
};

export default InsurancePDFDocument;
