import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/modal/Loading";
import { useDispatch } from "react-redux";
import { setCookie } from "../redux/store";

const PrivateRoute = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [existCookie, setExistCookie] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const currentSearch = location.search;
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");
  const [redirectPath, setRedirectPath] = useState(
    `/?redirect=${encodeURIComponent(currentPath + currentSearch)}`
  );

  const checkCookieData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        credentials: "include", // 쿠키를 요청에 포함시키기
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/trip-api/auth/checkToken`,
        requestOptions
      );

      if (!response.ok) {
        navigate(redirectPath);
        return;
      }

      const result = await response.json();

      dispatch(setCookie(result));

      if (result.success) {
        if (currentPath === "/combine" && result?.mrzUser === true) {
          setRedirectPath(redirect);
          setExistCookie(false);
        } else {
          setExistCookie(true); // 토큰이 유효할 때 true로 설정
        }
      } else {
        setExistCookie(false);
      }
    } catch (error) {
      console.error(error.status);
      setExistCookie(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkCookieData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return existCookie ? (
    element
  ) : (
    <Navigate to={redirectPath} state={{ from: location }} />
  );
};

export default PrivateRoute;
